import React, { useEffect, useState } from "react";
import config from "../../../config";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import useSectionHook from "./sectionHook";
import SectionCreate from "./SectionCreate";
import { Badge, Button } from "react-bootstrap";

function SectionDescription() {
  const pageTitle = "Section Details";
  const { id } = useParams();
  const userData = sessionStorage.getItem("user");

  const {
    sectionView,
    setSectionView,
    handleDelete,
    navigate,
    setSectionItem,
    setValue,
    onSubmit,
    onUpdate,
    register,
    sectionItem,
    handleSubmit,
    sectionList,
    errors,
    isOpenCanvas,
    setIsOpenCanvas,
  } = useSectionHook();

  // console.log(sectionView,'hgfgfgfhfghf');

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-6 m-auto p-30">
            <div className="text-left">
              <div className="timeline-footer text-right">
                <button
                  className="btn btn-primary btn-round btn-sm"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                  onClick={() => {
                    setIsOpenCanvas(true);
                    setSectionItem({ status: "Active" });
                    setValue("status", "Active");
                  }}
                >
                  Add
                </button>
                &nbsp; &nbsp;
                <Button
                  onClick={() => {
                    setSectionItem(sectionView);
                    setIsOpenCanvas(true);
                  }}
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Update Section
                </Button>
                &nbsp; &nbsp;
                <Link
                  to="/section"
                  className="btn btn-primary btn-round btn-sm "
                >
                  Manage
                </Link>
                &nbsp; &nbsp;
                <button
                  onClick={() => handleDelete(sectionView._id)}
                  className="btn btn-danger btn-round btn-sm "
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <div className="card-body">
              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right "
                >
                  <strong> Display Name :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.display_name}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right "
                >
                  <strong> Section Name :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.section_name}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right "
                >
                  <strong> Parent Section :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.parent_section}
                </div>
              </div>

              {/* <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-lg-8 form-control text-left no-border">
                 <strong> Parent Name : </strong>
               
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {sectionView.parent_section}
              </div>
        </div> */}

              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right"
                >
                  <strong>Page Title :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.page_title}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right"
                >
                  <strong>Meta Title :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.meta_title}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right"
                >
                  <strong> Description :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.description}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right"
                >
                  <strong> Keyword :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  {sectionView.keywords}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="page_title"
                  className="col-sm-4 col-form-label text-right"
                >
                  <strong> Status :</strong>
                </label>
                <div className="col-lg-8 form-control text-left no-border">
                  <Badge
                    bg={sectionView.status === "Active" ? "success" : "danger"}
                    text="white"
                  >
                    {sectionView.status}
                  </Badge>
                </div>
              </div>

              {/* offcanvas for update */}
              <div
                className={`offcanvas offcanvasbodyupdate offcanvas-end ${
                  isOpenCanvas ? "show" : ""
                }`}
                tabindex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header ">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasExampleLabel"
                  ></h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <div>
                    <SectionCreate
                      onSubmit={onSubmit}
                      onUpdate={onUpdate}
                      register={register}
                      setValue={setValue}
                      sectionItem={sectionItem}
                      handleSubmit={handleSubmit}
                      sectionList={sectionList}
                      errors={errors}
                      isOpenCanvas={isOpenCanvas}
                      setIsOpenCanvas={setIsOpenCanvas}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionDescription;
