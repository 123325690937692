import React from 'react'
import CreateAttribute from './CreateAttribute'
// import { Pencil, Trash } from 'react-bootstrap-icons'
// import { Link } from 'react-router-dom'

function ListAttribute() {
    const pageTitle = "Attribute List"

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              <div className="timeline-footer text-right">
                {/* <Link to="/section/manage" className="btn btn-primary btn-round btn-sm">Manage Section</Link> */}
                <a className="btn btn-primary btn-round btn-sm" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                Add Attribute 
</a>
&nbsp;
              </div>
            </div>
            <div className="card-body">
         
{/* offcanvas start from here manage part strat */}

<div className="offcanvas offcanvasbody offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div className="offcanvas-header ">
    <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5>
    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div className="offcanvas-body">
    <div>
     <CreateAttribute/>
    </div>

  </div>
</div>
            
                
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListAttribute