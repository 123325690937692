import React, { useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../../../../config";
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import useRolesHook from "./rolesHook";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import usePermissionGroupHook from "../permissionGroup/permissionGroupHook";
import usePermissionHook from "../permission/permissionHook";
import Form from "react-bootstrap/Form";

const CreateRole = () => {
  const {
    id,
    onSubmitRoles,
    onUpdateRoles,
    register,
    handleSubmit,
    errors,
    setValue,
  } = useRolesHook();
  const { listPermissionGroup } = usePermissionGroupHook();
  const { listPermission } = usePermissionHook();
  const pageTitle = id ? `Update Role` : `Add New Role`;
  const pageTitleTwo = "Permissions";
  const fetchPermissionGroupListById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.rolesById(id)}`
      );
      if (response?.data) {
        setValue("name", response?.data?.name);
        setValue("status", response?.data?.status);
        setValue("paramName", response?.data?.paramName);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPermissionGroupListById(id);
    }
  }, [id]);
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={
                  id ? handleSubmit(onUpdateRoles) : handleSubmit(onSubmitRoles)
                }
              >
                <div className="row mb-15">
                  <label
                    htmlFor="name"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Role Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="Role Name"
                      className="form-control"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                        Role Name is required.
                      </span>
                    )}
                  </div>
                </div>
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="status"
                      {...register("status")}
                      className="form-control"
                      defaultValue={"Active"}
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>
                {/* permisssion group start from here */}
                <div className="card-header">
                  <h3 className="text-bold">{pageTitleTwo}</h3>
                </div>
                <div className="card-body">
                  {/* <div className="row"> */}
                  <div className="col-lg-12">
                    <div className="card-body">
                      <div className="table">
                        {listPermissionGroup?.length > 0
                          ? listPermissionGroup?.map((prmGrp, index) => {
                              return (
                                listPermission?.length > 0 &&
                                listPermission?.filter(
                                  (prm) =>
                                    prm?.permissionGroup?._id === prmGrp?._id
                                )?.length > 0 && (
                                  <table
                                    className="table table-hover"
                                    key={index}
                                  >
                                    <thead>
                                      <tr>
                                        <th className={`text-capitalize`}>
                                          {prmGrp?.name}
                                        </th>
                                        <th className="text-center"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="w-25"></td>
                                        <td>
                                          <div className="row text-start">
                                            {listPermission?.length > 0
                                              ? listPermission
                                                  ?.filter(
                                                    (prm) =>
                                                      prm?.permissionGroup
                                                        ?._id === prmGrp?._id
                                                  )
                                                  ?.map((permission, key) => {
                                                    return (
                                                      <div
                                                        className="col-3"
                                                        key={key}
                                                      >
                                                        <div className="d-flex align-items-center form-group text-capitalize">
                                                          <Form.Check
                                                            key={key}
                                                            type="switch"
                                                            id={`switch-${index}-${key}`}
                                                            label={
                                                              permission.action_name
                                                            }
                                                            name={`parmName[${prmGrp.name}][]`}
                                                            value={
                                                              permission.action_name
                                                            }
                                                            {...register(
                                                              `paramName[${prmGrp.name}][]`
                                                            )}
                                                            className="form-control bg-transparent border-0 role-switch"
                                                          />
                                                        </div>
                                                      </div>
                                                    );
                                                  })
                                              : ""}
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                )
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-15  text-center">
                  <div className="col-sm-12">
                    <button
                      className="btn btn-primary btn-round mr-5"
                      style={{ width: "2rem!important" }}
                      type="submit"
                    >
                      {id ? `Update` : `Submit`}
                    </button>
                    <Link
                      to="/user-management/roles"
                      className="btn btn-primary btn-round"
                      type="reset"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default CreateRole;
