import React from "react";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import PageTable from "./ListPages";
import usePageHook from "./pageHook";
import QuillEditor from "./contentWrite";

const CreatePage = () => {
  const {
    id,
    onPageUpdate,
    onSubmit,
    errors,
    formData,
    handleChange,
    handleDelete,
    handleSubmit,
    loading,
    pagesList,
    register,
    setFormData,
    setSubmittedPage,
    setValue,
    getValues,
    submittedPage,
  } = usePageHook();
  console.log(formData, "formData");
  const PageTitle = id ? "Update Page" : "Create Page";
  const BtnName = id ? "Update" : "Submit";

  const handleEditorChange = (value) => {
    setValue("content", value);
  };
  const Check_Mime_Type = formData?.mime_type || getValues()?.mime_type;
  return (
    <div className="content">
      <div className="card col-md-12 m-auto p-30">
        <div className="card-header">
          <h3 className="text-bold">{PageTitle}</h3>
        </div>
        <div className="card-body">
          <form
            onSubmit={id ? handleSubmit(onPageUpdate) : handleSubmit(onSubmit)}
          >
            {/* Title */}
            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-4 col-form-label text-right"
              >
                Title
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="title"
                  className="form-control"
                  {...register("title", { required: "Title is required" })}
                  onChange={handleChange}
                />
                {errors.title && <span>{errors.title.message}</span>}
              </div>
            </div>
            {/* Meta Title */}
            <div className="row mb-15">
              <label
                htmlFor="meta_title"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Title
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="meta_title"
                  className="form-control"
                  {...register("meta_title", {
                    required: "Meta Title is required",
                  })}
                  onChange={handleChange}
                />
                {errors.metaTitle && <span>{errors.meta_title.message}</span>}
              </div>
            </div>
            {/* Meta Display */}
            <div className="row mb-15">
              <label
                htmlFor="meta_description"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Description
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="meta_description"
                  className="form-control"
                  {...register("meta_description", {
                    required: "Meta Description is required",
                  })}
                  onChange={handleChange}
                />
                {errors.metaDisplay && (
                  <span>{errors.meta_description.message}</span>
                )}
              </div>
            </div>
            {/* Meta Keyword */}
            <div className="row mb-15">
              <label
                htmlFor="meta_keywords"
                className="col-sm-4 col-form-label text-right"
              >
                Meta Keyword
              </label>
              <div className="col-sm-4">
                <input
                  type="text"
                  name="meta_keyword"
                  className="form-control"
                  {...register("meta_keywords", {
                    required: "Meta Keyword is required",
                  })}
                  onChange={handleChange}
                />
                {errors.metaKeyword && (
                  <span>{errors.metaKeyword.message}</span>
                )}
              </div>
            </div>
            {/* Status Dropdown */}

            <div className="row mb-15">
              <label
                htmlFor="status"
                className="col-sm-4 col-form-label text-right"
              >
                Status
              </label>
              <div className="col-sm-4">
                <select
                  name="status"
                  className="form-control"
                  onChange={handleChange}
                  {...register("status")}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            {/* Mime Type Dropdown */}
            <div className="row mb-15">
              <label
                htmlFor="mime_type"
                className="col-sm-4 col-form-label text-right"
              >
                Mime Type
              </label>
              <div className="col-sm-4">
                <select
                  name="mime_type"
                  className="form-control"
                  {...register("mime_type")}
                  onChange={handleChange}
                >
                  <option value="HTML">HTML</option>
                  <option value="JSON">JSON</option>
                  <option value="TEXT">TEXT</option>
                </select>
              </div>
            </div>
            {/* Show Header and Show Footer Checkboxes */}

            <div className="row mb-15">
              <div className="col-sm-6 ">
                <Form.Check
                  type="switch"
                  id="show_header"
                  {...register("new_tab")}
                  className="form-control  no-border d-flex justify-content-end align-items-end switchInput"
                  label="Show Header"
                  name="show_header"
                  checked={formData.show_header}
                  onChange={handleChange}
                />
              </div>

              <div className="col-sm-6">
                <Form.Check
                  type="switch"
                  id="show_footer"
                  {...register("new_tab")}
                  className="form-control  no-border switchInput"
                  label="Show Footer"
                  name="show_footer"
                  checked={formData.show_footer}
                  onChange={handleChange}
                />
              </div>
            </div>
            {/* react quill editor */}
            <div className="row mb-15">
              <label
                htmlFor="status"
                className="col-sm-4 col-form-label text-right"
              >
                Page Content
              </label>
              <div className="col-sm-6">
                {Check_Mime_Type === "HTML" && (
                  <div className="mb-50">
                    <QuillEditor
                      {...register("content", {
                        required: "Content is required",
                      })}
                      value={getValues("content")}
                      onChange={handleEditorChange}
                    />
                  </div>
                )}
                {Check_Mime_Type === "TEXT" && (
                  <textarea
                    rows={7}
                    cols={40}
                    className="p-20"
                    {...register("content", {
                      required: "Content is required",
                    })}
                    onChange={handleChange}
                  />
                )}
                {Check_Mime_Type === "JSON" && (
                  <textarea
                    rows={7}
                    cols={40}
                    className="p-20"
                    {...register("content", {
                      required: "Content is required",
                    })}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>

            {/* Submit and Cancel Buttons */}
            <div className="row mb-15 text-center ">
              <div className="col-sm-12 d-flex gap-3 justify-content-center align-items-center">
                <button
                  className="btn btn-primary btn-round mr-5 px-20"
                  style={{ width: "2rem!important" }}
                  type="submit"
                >
                  {BtnName}
                </button>
                <Link
                  to="/pages"
                  className="btn btn-primary btn-round px-20"
                  type="reset"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>

      {submittedPage && (
        <PageTable
          pageList={[submittedPage]}
          handleDelete={(pageId) => console.log("Delete page with ID", pageId)}
        />
      )}
    </div>
  );
};

export default CreatePage;
