import { Link } from "react-router-dom";

const UpdateSeo = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-8 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">SEO Content</h3>
            </div>
            <div className="card-body">
              <form method="post" className="horizontal-form">
                <div className="row mb-20">
                  <label htmlFor="metaTitle" className="col-sm-3 col-form-label">Meta Title</label>
                  <div className="col-sm-9">
                    <input type="text" placeholder="Meta Title" name="metaTitle" className="form-control" />
                  </div>
                </div>

                <div className="row mb-20">
                  <label htmlFor="metaDescription" className="col-sm-3 col-form-label">Meta Descriptiom</label>
                  <div className="col-sm-9">
                    <textarea placeholder="Meta Descriptiom" name="metaDescription" className="form-control"></textarea>
                  </div>
                </div>

                <div className="row mb-20 text-center">
                  <div className="col-sm-12">
                    <button className="btn btn-primary btn-round">Update Record</button>
                    <Link to="/seo-content" className="btn btn-danger btn-round ml-10">Cancel</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateSeo;