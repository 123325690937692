import { Link } from "react-router-dom";

const Openings = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Openings</h3>
              <div class="timeline-footer text-right">
                <Link to="/career/post-job" class="btn btn-primary btn-round btn-sm">Post Job</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Job Title</th>
                      <th>Designation</th>
                      <th>Exp</th>
                      <th>Package</th>
                      <th>Is WalkIn</th>
                      <th>Posting Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        Urgent opening for Search Engine Optimisation Specialist
                      </td>
                      <td>
                        SEO Specialist
                      </td>
                      <td>
                        2-3 Years
                      </td>
                      <td>6,00,000</td>
                      <td><span className="badge badge-success">Yes</span></td>
                      <td>01-08-2023</td>
                      <th>
                        <a href="#">
                          <i className="fa fa-edit"></i>
                        </a>
                        <a href="#" className="ml-2">
                          <i className="fa fa-trash"></i>
                        </a>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Openings;