import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import Form from "react-bootstrap/Form";
import "react-toastify/dist/ReactToastify.css";
import StoryDetailForm from "./canvasStoryForm";
import usePageshook from "./hook";
import ImageUpload from "../../common/editor/imageCrop";
import Timeline from "./timeline";
import { convertToSlug } from "../../common/count-words-and-characters";
import PublishForm from "./publish";
import AppContext from "../../appContext";
import EditorNotes from "./editorNotes";
import { CheckAccess } from "../../common/utils/roleAccess";
import LinkPreview from "./linkPreview";
import { GetNewCustomSlug } from "../../common/api/api";
import TextEditorComponent from "./TextEditors";
import CommonDropBox from "../../common/drop-zone/dropbox";

const CreateVideoStory = () => {
  const {
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    includeSectionList,
    setIncludeSectionList,
    authorList,
    setAuthorList,
    selectedAuthorList,
    setSelectedAuthorList,
    HandleSelectChange,
    selectedIncludeSection,
    register,
    handleSubmit,
    errors,
    setValue,
    onSubmit,
    formData,
    setFormData,
    socialCard,
    handleAddElement,
    handleDeleteElement,
    handleSocialCardChange,
    show,
    setShow,
    handleClose,
    handleShow,
    HandleModalDataSubmit,
    id,
    cards,
    setCards,
    HandleGetSection,
    slug,
    setSlug,
    currentImgId,
    setCurrentImgId,
    setImgUrl,
    imgUrl,
    showTimeline,
    setShowTimeline,
    timelines,
    loading,
    RemoveEmptyFields,
    getValues,
    isImgEditorShown,
    setIsImgEditorShown,
    handleSubCardChange,
    onUpdateStatus,
    setStoryStatus,
    navigate,
    fetchTimeline,
    onAutoSaveStory,
    setLoading,
    statusRef,
    isSaving,
    videoUrl,
    setVideoUrl,
  } = usePageshook();
  const [files, setFiles] = useState([]);
  const { showEditorNotes, setShowEditorNotes, appData } =
    useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [openCanvas, setOpenCanvas] = useState(false);
  const handleCloseCanvas = () => setOpenCanvas(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [isImgEditorShownForEditor, setIsImgEditorShownForEditor] =
    useState(false);
  const [cardIndexObj, setCardIndexObj] = useState({
    cardIndex: 0,
    subCardIndex: 0,
  });

  const fetchNewCustomSlugData = async (item) => {
    try {
      const response = await GetNewCustomSlug({
        slug: item,
        id: appData?.story_Id,
      });
      setValue("custom_slug", response?.data?.new_custom_slug);
      let convertedSlug = convertToSlug(response?.data?.new_custom_slug);
      const updatedCustomSlug = slug
        ? `${slug?.split("/")?.reverse()?.join("/")}/${convertedSlug}`
        : `${convertedSlug}`;
      setValue("slug_preview", updatedCustomSlug?.toLowerCase());
      setValue("slug", `${convertedSlug?.toLowerCase()}`);
    } catch (error) {
      console.error("Error fetching new custom slug:", error.message);
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValue("status", "open");
    setStoryStatus("open");
    if (name === "title") {
      setValue("title", value);
    }

    if (name === "sub_title") {
      setValue("sub_title", value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "custom_slug") {
      let convertedSlug = convertToSlug(value);
      const updatedCustomSlug = slug
        ? `${slug?.split("/")?.reverse()?.join("/")}/${convertedSlug}`
        : `${convertedSlug}`;
      setValue("slug_preview", updatedCustomSlug);
      setValue("slug", `${convertedSlug}`);
    }
  };

  const autoResizeTextarea = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const textareaRef = useRef(null);
  useEffect(() => {
    if (formData?.title || formData?.sub_title) {
      adjustTextareaHeight();
      textareaRef?.current?.blur();
    }
  }, [formData?.title, formData?.sub_title]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `auto`;
      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflow = `hidden`;
    }
  };
  const showError = (data) => {};
  useEffect(() => {
    if (!id) {
      window.onload();
    }
  }, [id]);

  const calculateTextareaRows = () => {
    const numberOfNewlines = (getValues()?.title?.match(/\n/g) || []).length;
    const calculatedRows = Math.max(2, numberOfNewlines + 1);
    return getValues()?.title?.length > 63
      ? calculatedRows + 2
      : calculatedRows;
  };
  const calculateSubTextareaRows = () => {
    const numberOfNewlines = (getValues()?.sub_title?.match(/\n/g) || [])
      .length;
    const calculatedRows = Math.max(2, numberOfNewlines + 1);
    return getValues()?.sub_title?.length > 98
      ? calculatedRows + 2
      : calculatedRows;
  };
  return (
    <div className="content position-relative">
      {/* {!isImgEditorShown && !isImgEditorShownForEditor && ( */}
      {loading ? <div className="spinner story-form-spinner"></div> : ""}
      <div className="card col-md-12 m-auto p-30">
        <form
          onSubmit={(e) => {
            e?.preventDefault();

            if (
              !formData?.meta_title ||
              !formData?.meta_description ||
              !selectedTagsList?.length > 0 ||
              !selectedIncludeSection?.length > 0 ||
              getValues()?.meta_keywords === "" ||
              getValues()?.caption === "" ||
              getValues()?.attribution === "" ||
              getValues()?.credit === ""
            ) {
              if (
                getValues()?.caption === "" ||
                getValues()?.attribution === "" ||
                getValues()?.credit === ""
              ) {
                handleSubmit(showError)(e);
                return;
              }

              setShow(true);
              handleSubmit(showError)(e);
            } else {
              handleSubmit(onSubmit)(e);
            }
          }}
          name="frmStory"
        >
          <div className="card-header">
            <div className="col-sm-2 ">
              <h3 className="text-bold">
                {id ? `Update` : `Create`} Video Story
              </h3>
            </div>
            <div className="col-sm-7 d-flex justify-content-end ">
              <div className="add-btns d-flex">
                {CheckAccess("story", "create") && (
                  <button
                    className="btn btn-primary mr-5 border-0"
                    style={{ width: "2rem!important" }}
                    onClick={(e) => {
                      e?.preventDefault();
                      onAutoSaveStory("open");
                    }}
                    disabled={isSaving}
                  >
                    {isSaving ? `Saving...` : `Save`}
                  </button>
                )}

                {statusRef?.current === "open" &&
                  CheckAccess("story", "create") && (
                    <button
                      className="btn btn-primary mr-5"
                      style={{ width: "2rem!important" }}
                      type="submit"
                      disabled={loading}
                    >
                      {`Submit`}
                    </button>
                  )}
                {statusRef?.current === "submitted" &&
                  CheckAccess("story", "approved") && (
                    <button
                      className="btn btn-primary mr-5 border-0"
                      style={{ width: "2rem!important" }}
                      onClick={(e) => {
                        e?.preventDefault();
                        if (
                          getValues()?.caption === "" ||
                          getValues()?.attribution === "" ||
                          getValues()?.credit === ""
                        ) {
                          handleSubmit(showError)(e);
                          return;
                        }
                        if (
                          !formData?.meta_title ||
                          !formData?.meta_description ||
                          !selectedTagsList?.length > 0 ||
                          !selectedIncludeSection?.length > 0
                        ) {
                          setShow(true);
                          handleSubmit(showError)(e);
                        } else {
                          let cardsAfterRemovedEmptyElement =
                            RemoveEmptyFields(cards);
                          setCards(cardsAfterRemovedEmptyElement);
                          setValue("cards", cardsAfterRemovedEmptyElement);
                          onUpdateStatus("approved");
                        }
                      }}
                      disabled={loading}
                    >
                      Approved
                    </button>
                  )}
                {statusRef?.current === "approved" &&
                  CheckAccess("story", "published") && (
                    <button
                      className="btn btn-primary mr-5 border-0"
                      style={{ width: "2rem!important" }}
                      onClick={(e) => {
                        e?.preventDefault();

                        if (
                          !formData?.meta_title ||
                          !formData?.meta_description ||
                          !selectedTagsList?.length > 0 ||
                          !selectedIncludeSection?.length > 0 ||
                          getValues()?.meta_keywords === "" ||
                          getValues()?.caption === "" ||
                          getValues()?.attribution === "" ||
                          getValues()?.credit === ""
                        ) {
                          if (
                            getValues()?.caption === "" ||
                            getValues()?.attribution === "" ||
                            getValues()?.credit === ""
                          ) {
                            handleSubmit(showError)(e);
                            return;
                          }

                          setShow(true);
                          handleSubmit(showError)(e);
                        } else {
                          setOpenCanvas(true);
                        }
                      }}
                      disabled={loading}
                    >
                      Publish
                    </button>
                  )}

                {CheckAccess("story", "create") && (
                  <button
                    className="btn btn-primary mr-5"
                    style={{ width: "2rem!important" }}
                    onClick={(e) => {
                      handleShow(e);
                    }}
                    disabled={loading}
                  >
                    Manage
                  </button>
                )}
                {statusRef?.current && timelines?.length > 0 && (
                  <button
                    className="btn btn-primary mr-5"
                    style={{ width: "2rem!important" }}
                    onClick={(e) => {
                      e?.preventDefault();
                      setShowTimeline(true);
                    }}
                    disabled={loading}
                  >
                    Timeline
                  </button>
                )}
                <button
                  className="btn btn-primary mr-5"
                  style={{ width: "2rem!important" }}
                  onClick={(e) => {
                    e?.preventDefault();
                    navigate("/video-story");
                  }}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-sm-12 d-flex justify-content-between align-items-center"></div>
          </div>
          <div className="card-body">
            {/* Title */}
            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Title<span className="text-danger">*</span>
                <p>{`${formData?.title?.length || 0}/110`}</p>
              </label>
              <div className="col-sm-7">
                <Form.Control
                  ref={textareaRef}
                  as="textarea"
                  name="title"
                  placeholder="Title"
                  maxLength={110}
                  {...register("title", {
                    required: true,
                    maxLength: 110,
                  })}
                  onChange={handleChange}
                  onBlur={(e) => {
                    e?.preventDefault();
                    onAutoSaveStory();
                  }}
                  rows={calculateTextareaRows()}
                  // rows={2}
                  className={
                    id
                      ? "border-0 hero-input textarea-height text-area-style auto-resize-textarea"
                      : "border-0 hero-input textarea-height auto-resize-textarea"
                  }
                  isInvalid={formData?.title?.length > 110}
                  value={formData?.title}
                />
                {errors.title && (
                  <span className="text-danger">Title is required</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Sub Title<span className="text-danger">*</span>
                <p>{`${formData?.sub_title?.length || 0}/200`}</p>
              </label>
              <div className="col-sm-7">
                <Form.Control
                  ref={textareaRef}
                  as="textarea"
                  name="sub_title"
                  placeholder="Sub Title"
                  className={
                    id
                      ? "form-control border-0 hero-sub-input textarea-height text-area-style1"
                      : "form-control border-0 hero-sub-input textarea-height"
                  }
                  maxLength={200}
                  {...register("sub_title", {
                    required: true,
                    maxLength: 200,
                  })}
                  value={formData?.sub_title}
                  onChange={handleChange}
                  // onInput={(e) => autoResizeTextarea(e.target)}
                  onBlur={(e) => {
                    e?.preventDefault();
                    onAutoSaveStory();
                    autoResizeTextarea(e.target);
                  }}
                  rows={calculateSubTextareaRows()}
                />
                {errors.sub_title && (
                  <span className="text-danger">Sub title is required</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Image<span className="text-danger">*</span>
              </label>
              <div className="col-sm-7">
                <CommonDropBox
                  register={register}
                  setValue={setValue}
                  imgUrl={imgUrl}
                  setImgUrl={setImgUrl}
                  files={files}
                  setFiles={setFiles}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  getValues={getValues}
                  errors={errors}
                />
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="title"
                className="col-sm-2 col-form-label text-start"
              >
                Video Url<span className="text-danger">*</span>
              </label>
              <div className="col-sm-7">
                <Form.Control
                  as="textarea"
                  name="video_url"
                  placeholder="Embed url or content"
                  rows={2}
                  {...register(`video_url`, {
                    required: true,
                  })}
                  onChange={(e) => {
                    setVideoUrl(e?.target?.value);
                    setValue("video_url", e?.target?.value);
                  }}
                />
                {errors.video_url && (
                  <span className="text-danger">Video is required</span>
                )}
                {videoUrl && (
                  <LinkPreview
                    link={videoUrl}
                    register={register}
                    errors={errors}
                    setVideoUrl={setVideoUrl}
                    setValue={setValue}
                  />
                )}
              </div>
            </div>
            <div className="width-100"></div>
            <>
              <StoryDetailForm
                placement={"end"}
                timelines={timelines}
                fetchNewCustomSlugData={fetchNewCustomSlugData}
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                setValue={setValue}
                handleChange={handleChange}
                handleClose={handleClose}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                formData={formData}
                setFormData={setFormData}
                tagsList={tagsList}
                setTagsList={setTagsList}
                selectedTagsList={selectedTagsList}
                setSelectedTagsList={setSelectedTagsList}
                includeSectionList={includeSectionList}
                setIncludeSectionList={setIncludeSectionList}
                selectedIncludeSection={selectedIncludeSection}
                authorList={authorList}
                setAuthorList={setAuthorList}
                selectedAuthorList={selectedAuthorList}
                setSelectedAuthorList={setSelectedAuthorList}
                HandleSelectChange={HandleSelectChange}
                socialCard={socialCard}
                handleAddElement={handleAddElement}
                handleDeleteElement={handleDeleteElement}
                handleSocialCardChange={handleSocialCardChange}
                HandleModalDataSubmit={HandleModalDataSubmit}
                HandleGetSection={HandleGetSection}
                slug={slug}
                onAutoSaveStory={onAutoSaveStory}
                setSlug={setSlug}
              />
            </>
          </div>
        </form>
        <div className="row mb-15">
          <TextEditorComponent
            setValue={setValue}
            cards={cards}
            setCards={setCards}
            isImgEditorShown={isImgEditorShown}
            setIsImgEditorShown={setIsImgEditorShown}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
            handleSubCardChange={handleSubCardChange}
            setCardIndexObj={setCardIndexObj}
            currentImgId={currentImgId}
            setCurrentImgId={setCurrentImgId}
            errors={errors}
            register={register}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            getValues={getValues}
            onAutoSaveStory={onAutoSaveStory}
          />
        </div>
      </div>
      <>
        <Modal
          // {...props}
          show={isImgEditorShown}
          // fullscreen={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          onHide={() => setIsImgEditorShown(false)}
          dialogClassName="story-popup"
          contentClassName="story-content-popup"
        >
          <Modal.Body>
            <ImageUpload
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              isImgEditorShown={isImgEditorShown}
              setIsImgEditorShown={setIsImgEditorShown}
              isImgEditorShownForEditor={isImgEditorShownForEditor}
              setIsImgEditorShownForEditor={setIsImgEditorShownForEditor}
              setValue={setValue}
              setImgUrl={setImgUrl}
              handleSubCardChange={handleSubCardChange}
              cardIndexObj={cardIndexObj}
              currentImgId={currentImgId}
              setCurrentImgId={setCurrentImgId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Modal.Body>
        </Modal>
      </>
      {/* )} */}
      {timelines?.length > 0 && (
        <Timeline
          showTimeline={showTimeline}
          setShowTimeline={setShowTimeline}
          timelines={timelines}
        />
      )}{" "}
      {/* offcanvas start from here manage part start */}
      <PublishForm
        storyId={getValues()?.story_id ? getValues()?.story_id : ""}
        handleCloseCanvas={handleCloseCanvas}
        openCanvas={openCanvas}
        setOpenCanvas={setOpenCanvas}
        setStoryStatus={setStoryStatus}
        fetchTimeline={fetchTimeline}
        getValues={getValues}
        setLoading={setLoading}
      />
      <EditorNotes
        storyId={getValues()?.story_id ? getValues()?.story_id : ""}
        showEditorNotes={showEditorNotes}
        setShowEditorNotes={setShowEditorNotes}
        setStoryStatus={setStoryStatus}
      />
    </div>
  );
};

export default CreateVideoStory;
