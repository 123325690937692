// ImageUpload.js

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import config from "../../../config";
import { apiEndpoints } from "../api/apiEndpoints";

const ImageUpload = (props) => {
  const {
    selectedFile,
    setSelectedFile,
    // isImgEditorShown,
    setIsImgEditorShown,
    isImgEditorShownForEditor,
    setIsImgEditorShownForEditor,
    setValue,
    setImgUrl,
    handleSubCardChange,
    cardIndexObj,
    isLoading,
    setIsLoading,
  } = props;


  // const [isLoading, setIsLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState("");
  const [cropper, setCropper] = useState();
  const [aspectRatio, setAspectRatio] = useState(16 / 9);

  // const onDrop = (acceptedFiles) => {
  //   setSelectedFile(acceptedFiles[0]);
  // };

  const onCrop = () => {
    setIsLoading(true);
    if (cropper) {
      setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const onUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("files", dataURLtoFile(croppedImage, "croppedImage.png"));
      const res = await axios.post(
        `${config?.fileUrl}/${apiEndpoints.upload}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res?.data?.length > 0) {
        setIsLoading(false);
        if (isImgEditorShownForEditor) {
          handleSubCardChange(
            cardIndexObj?.cardIndex,
            cardIndexObj?.subCardIndex,
            "images",
            res?.data[0]?.file_path
          );
          setIsImgEditorShownForEditor(false);
        } else {
          setValue("file_name", res?.data[0]?.file_name);
          setValue("file_path", res?.data[0]?.file_path);
          setValue("file_Id", res?.data[0]?._id);

          setImgUrl(`${config?.fileUrl}/${res?.data[0]?.file_path}`);
        }
        setIsImgEditorShown(false);
      }

      console.log("Image uploaded successfully!");
    } catch (error) {
      console.error("Error uploading image:", error.message);
    }
  };
  useEffect(() => {
    if (croppedImage) {
      onUpload();
    }
  }, [croppedImage]);
  // Helper function to convert data URL to a file
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const setAspectRatioAndCrop = (ratio) => {
    setAspectRatio(ratio);
    if (cropper) {
      cropper.setAspectRatio(ratio);
    }
  };
  useEffect(() => {
    const getDimensions = () => {
      const image = new Image(selectedFile);
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        setAspectRatioAndCrop(width / height);
        console.log(width, "width", height, "height");
      };
    };
    getDimensions();
  }, [selectedFile]);
  return (
    <>
      <div className="content">
        <div>
          <div className={`row`}>
            <div className=" col-md-12 m-auto p-30">
              {!selectedFile ? <div className="spinner"></div> : ""}
              <div>
                {selectedFile && (
                  <>
                    <div className={`row d-flex justify-content-center my-2`}>
                      <div className="col-lg-12 text-center">
                        <div className={`margin-bottom-50`}>
                          <button
                            className={
                              aspectRatio === 16 / 9
                                ? `btn btn-round btn-primary active`
                                : `btn btn-round btn-primary`
                            }
                            onClick={() => setAspectRatioAndCrop(16 / 9)}
                          >
                            16:9
                          </button>
                          <button
                            className={
                              aspectRatio === 9 / 16
                                ? `btn btn-round btn-primary mx-2 active`
                                : `btn mx-2 btn-round btn-primary`
                            }
                            onClick={() => setAspectRatioAndCrop(9 / 16)}
                          >
                            9:16
                          </button>
                          <button
                            className={
                              aspectRatio === 4 / 3
                                ? `btn btn-round btn-primary mx-2 active`
                                : `btn mx-2 btn-round btn-primary`
                            }
                            onClick={() => setAspectRatioAndCrop(4 / 3)}
                          >
                            4:3
                          </button>
                          <button
                            className={
                              aspectRatio === 3 / 4
                                ? `btn btn-round btn-primary active`
                                : `btn btn-round btn-primary`
                            }
                            onClick={() => setAspectRatioAndCrop(3 / 4)}
                          >
                            3:4
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-8 text-center position-relative">
                        {isLoading ? <div className="spinner"></div> : ""}
                        <Cropper
                          src={URL.createObjectURL(selectedFile)}
                          // aspectRatio={aspectRatio}
                          onInitialized={(instance) => {
                            setCropper(instance);
                            const image = instance.getImageData();
                            console.log(image, "image instance");
                            
                            const maxHeight = Math.min(
                              window.innerHeight,
                              image?.naturalHeight
                            );
                            const maxWidth = Math.min(
                              window.innerWidth,
                              image?.naturalWidth
                            );
                            const cropSize = Math.min(maxHeight, maxWidth);
                            instance.setCropBoxData({
                              width: cropSize,
                              height: cropSize,
                            });
                          }}
                          preview=".img-preview"
                          style={{
                            width: "100%",
                            maxHeight: "75vh",
                            height:"75vh",
                            backgroundColor: "transparent",
                          }}
                          background={false}
                          responsive={true}
                          zoomable={false}
                          viewMode={2}
                        />

                        <div
                          className={`d-flex justify-content-center margin-top-50`}
                        >
                          <button
                            className={`btn btn-primary btn-round my-2`}
                            onClick={onCrop}
                            disabled={isLoading}
                          >
                            Upload
                          </button>
                          <button
                            className={`btn btn-primary btn-round my-2`}
                            onClick={(e) => {
                              e?.preventDefault();
                              setIsImgEditorShown(false);
                              setIsImgEditorShownForEditor(false);
                              setSelectedFile(null);
                              setIsLoading(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const dropzoneStyle = {
  border: "2px dashed #0087F7",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default ImageUpload;
