import React from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import useMenuHook from "./menuHook";
const animatedComponents = makeAnimated();
function CreateMenu() {
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    postionOption,
    handlePosition,
    valueTypes,
    onMenuSubmit,
    parentMenu,
    typeMenu,
    id,
    onMenuUpdate,
    selectedType,
    selectedPosition,
    selectedParentMenu,
    selectedTypeValue,
  } = useMenuHook();
  const pageTitle = id ? "Update Menu" : "Create Menu";

  return (
    <>
      <div className="content text-center">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={
                  id ? handleSubmit(onMenuUpdate) : handleSubmit(onMenuSubmit)
                }
              >
                {}
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Position
                  </label>
                  <div className="col-sm-3">
                    <Select
                      className={` outline-none text-start`}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      name={`position`}
                      options={postionOption}
                      onChange={(e) => handlePosition("position", e)}
                      value={selectedPosition}
                      isSearchable={true}
                      isClearable={true}
                    >
                      {postionOption?.length > 0 &&
                        postionOption?.map((item, key) => {
                          return (
                            <option value={item?.value} key={key}>
                              {item?.label}
                            </option>
                          );
                        })}
                    </Select>
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Name
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                        Menu Name Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Type
                  </label>
                  <div className="col-sm-3">
                    <Select
                      className={` outline-none text-start`}
                      closeMenuOnSelect={true}
                      name="type"
                      components={animatedComponents}
                      options={typeMenu}
                      value={selectedType}
                      onChange={(e) => handlePosition("type", e)}
                      isSearchable={true}
                      isClearable={true}
                    ></Select>
                  </div>
                </div>

                <div className="row mb-15">
                  {selectedType?.value !== "Placeholder" ? (
                    <label
                      htmlFor="type_value"
                      className="col-sm-4 col-form-label text-right"
                    >
                      {" "}
                      Type Value
                    </label>
                  ) : (
                    ""
                  )}
                  {selectedType?.value !== "External" &&
                  selectedType?.value !== "Placeholder" ? (
                    <div className="col-sm-3">
                      <Select
                        className={` outline-none text-start`}
                        name="type_value"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={valueTypes}
                        onChange={(e) => handlePosition("type_value", e)}
                        value={selectedTypeValue}
                        isSearchable={true}
                        isClearable={true}
                      ></Select>
                    </div>
                  ) : selectedType?.value == "External" ? (
                    <div className="col-sm-3">
                      <input
                        type="text"
                        name="type_value"
                        className="form-control"
                        {...register("type_value")}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Parent Menu
                  </label>
                  <div className="col-sm-3">
                    <Select
                      className={` outline-none text-start`}
                      closeMenuOnSelect={true}
                      name="parent"
                      components={animatedComponents}
                      options={parentMenu}
                      onChange={(e) => handlePosition("parent", e)}
                      value={selectedParentMenu}
                      isSearchable={true}
                      isClearable={true}
                    ></Select>
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    New Tab
                  </label>
                  <div className="col-sm-3">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="switch"
                      label=" "
                      name="new_tab"
                      {...register("new_tab")}
                      className="form-control  no-border text-start switchInput"
                    />
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="status"
                      {...register("status")}
                      className="form-control"
                    >
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>
                {}
                <button className="btn btn-primary btn-round" type="submit">
                  {id ? `Update` : `Submit`}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateMenu;
