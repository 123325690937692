import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Profile</h3>
              <div className="timeline-footer text-right">
                <Link to="/users/profile" className="btn btn-primary btn-round btn-sm">Update Profile</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="box-profile">
                <div className="row">
                  <div className="col-md-6">
                    <img className="profile-user-img rounded-circle img-fluid mx-auto d-block w-100" src="../../../images/5.jpg" alt="User profile picture" />
                    <h3 className="profile-username text-center mb-0">Jhone Mical</h3>
                    <p className="text-center">Accoubts Manager Jindal Cop.</p>
                  </div>
                  <div className="col-md-6">
                    <p className="text-white">Email address </p>
                    <h6 className="margin-bottom">jhone.mical@yahoo.com</h6>
                    <p className="text-white">Phone</p>
                    <h6 className="margin-bottom">+11 123 456 7890</h6>
                    <p className="text-white">Address</p>
                    <h6 className="margin-bottom">123, Lorem Ipsum, Florida, USA</h6>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;