import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import config from '../../../config';
import Swal from "sweetalert2";
import { toast } from "react-toastify"; // Import react-toastify

const ViewWorks = () => {
  const [ourWork, setOurWork] = useState([]);
  const { slug } = useParams();

  useEffect(() => {
    fetchOurWork(); // Fetch contact requests on component mount
  }, []);

  const fetchOurWork = () => {
    axios.get(`${config.apiUrl}/our-work/${slug}`)
      .then(response => {
        setOurWork(response.data);
      })
      .catch(error => {
        console.error('Error fetching contact requests:', error);
      });
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios.delete(`${config.apiUrl}/our-work/${id}`)
          .then(response => {
            console.log('Item deleted successfully:', response.data);
            fetchOurWork(); // Refresh the table data
            // Show success message
            toast.success("Item deleted successfully");
            // Swal.fire("Deleted!", "Your item has been deleted.", "success");
          })
          .catch(error => {
            console.error('Error deleting item:', error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
            // Swal.fire("Error", "An error occurred while deleting.", "error");
          });
      }
    });
  };

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-8 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Our Works</h3>
              <div class="timeline-footer text-right">
                <Link to="/our-works/add" class="btn btn-primary btn-round btn-sm">Add Work</Link>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <tbody>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                    <tr>
                      <th>Client Name</th>
                      <td>{ourWork.client_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewWorks;