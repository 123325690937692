import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { apiEndpoints } from "../../../common/api/apiEndpoints";

const usePermissionHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [editPermission, setEditPermission] = useState({
    permissionGroup: "",
    name: "",
    controllerName: "",
    actionName: "",
    status: "active",
  });
  const [formData, setFormData] = useState({
    permissionGroup: "",
    name: "",
    controllerName: "",
    action_name: "",
    status: "active",
  });

  const {
    register,
    handleSubmit,
    formState: { errors = formData },
    setValue,
  } = useForm();

  const [listPermission, setListPermission] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/${apiEndpoints?.permission}/create`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        toast.success("Permission created successfully.");
        navigate("/user-management/permissions");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onUpdate = async (data) => {
    try {
      const response = await axios.put(
        `${config.apiUrl}/${apiEndpoints?.permissionById(id)}`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        toast.success("Permission updated successfully.");
        navigate("/user-management/permissions");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/permission/${id}`)
          .then((response) => {
            fetchPermissionList();
            toast.success("Item deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };
  const fetchPermissionList = async (
    permissionGroup,
    name,
    controllerName,
    actionName,
    status
  ) => {
    try {
      const response = await axios.get(`${config.apiUrl}/permission`, {
        params: {
          permissionGroup: permissionGroup,
          name: name,
          controllerName: controllerName,
          actionName: actionName,
          status: status,
          page: currentPage,
          pageSize: 20,
          pagination: false,
        },
      });
      setTotalPages(response?.data?.pages)
      setListPermission(response.data?.permission);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchPermissionList();
  }, [currentPage]);

  const HandleEdit = (data) => {
    navigate(`/user-management/permission/update/${data?._id}`);
  };
  useEffect(() => {
    setValue("status", "Active");
  }, []);

  return {
    navigate,
    register,
    handleSubmit,
    errors,
    formData,
    setFormData,
    onSubmit,
    listPermission,
    handleDelete,
    editPermission,
    setEditPermission,
    HandleEdit,
    onUpdate,
    id,
    handleChange,
    setValue,
    fetchPermissionList,
    totalPages,
    currentPage,
    handlePageChange,
  };
};

export default usePermissionHook;
