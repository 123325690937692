import React, { useContext, useEffect, useState } from "react";
import usePageshook from "./hook";
import { Link } from "react-router-dom";
import Pagination from "../../common/pagination";
import moment from "moment";
import { UpdateDataApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Loader from "../../common/utils/loader";
import _ from "lodash";
import { CheckAccess } from "../../common/utils/roleAccess";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";

const ListStory = () => {
  const [template, setTemplate] = useState('');
  const [publishDate, setPublishDate] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');
  const [section, setSection] = useState('');
  const [author, setAuthor] = useState('');

  const {
    totalPages,
    storiesList,
    handleDelete,
    currentPage,
    handlePageChange,
    navigate,
    fetchOurStory,
    loading,
    setStoriesList,
    authorList,
    sections
  } = usePageshook();
  const { setAppData } = useContext(AppContext);
  const [isTab, setIsTab] = useState("All");
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  useEffect(() => {
    setAppData("");
  }, []);

  const filterButtonClick = async () => {
    let url = `${config.apiUrl}/stories?page=1&pageSize=20&pagination=true`

    if (template) url += `&story_type=${template}`
    if (section) url += `&sections.label=${section}`
    if (author) url += `&authors.label=${author}`

    const response = await fetch(url)
    const data = await response.json()

    setStoriesList(data?.stories)
  }

  return (
    <div>
      <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          <h5 id="offcanvasRightLabel">Filters</h5>
          <button onClick={filterButtonClick} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Apply Filters</button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" style={{ width: "49%" }} role="presentation">
              <button className="nav-link active" style={{ width: "100%" }} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Options</button>
            </li>
            <li className="nav-item" style={{ width: "50%" }} role="presentation">
              <button className="nav-link" style={{ width: "100%" }} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Saved</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className="row mb-30 mt-30">
                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                  Story Template
                </label>
                <div className="col-sm-8">
                  <select
                    name="custom_slug"
                    className="form-control select2"
                    style={{ width: '100%' }}
                    data-placeholder="Select a section"
                    value={template}
                    onChange={(e) => setTemplate(e.target.value)}
                  >
                    <option > select story type</option>
                    <option value={'Text Story'}> Text Story</option>
                    <option value={'Web Story'}> Web Story</option>
                  </select>
                </div>
              </div>

              <div className="row mb-30 mt-30">
                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                  Updated Date
                </label>
                <div className="col-sm-8">
                  <input
                    type="date"
                    name="custom_slug"
                    className="form-control"
                    value={updatedDate}
                    onChange={(e) => setUpdatedDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-30 mt-30">
                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                  Published Date
                </label>
                <div className="col-sm-8">
                  <input
                    type="date"
                    name="custom_slug"
                    className="form-control"
                    value={publishDate}
                    onChange={(e) => setPublishDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-30 mt-30">
                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                  Section
                </label>
                <div className="col-sm-8">
                  <select
                    name="custom_slug"
                    className="form-control select2"
                    style={{ width: '100%' }}
                    data-placeholder="Select a section"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                  >
                    <option > select section</option>

                    {sections.map((section, index) => (
                      <option key={index} value={section.section_name}>
                        {section.section_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-30 mt-30">
                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                  Author
                </label>
                <div className="col-sm-8">
                  <select
                    name="custom_slug"
                    className="form-control select2"
                    style={{ width: '100%' }}
                    data-placeholder="Select a section"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                  >
                    <option > select author</option>

                    {authorList.map((author, index) => (
                      <option key={index} value={author.label}>
                        {author.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Saved</div>
          </div>
        </div>
      </div>


      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Story</h3>
              <input
                type="search"
                placeholder="Enter title or author"
                className="form-control story-search-input"
                onChange={(e) => {
                  fetchOurStory("", e?.target?.value);
                }}
              />

              <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filter</button>
              {/* <button className="btn btn-primary btn-round btn-sm">Filter</button> */}

              {CheckAccess("story", "create") && (
                <div className="timeline-footer text-right">
                  <Link
                    to="/story/create"
                    className="btn btn-primary btn-round btn-sm"
                  >
                    Add Story
                  </Link>
                </div>
              )}
            </div>
            <div className="card-body">
              <div className={`row mb-15 d-flex justify-content-around`}>
                <div
                  className={
                    isTab === "All"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("All");
                    fetchOurStory("");
                  }}
                >
                  All
                </div>
                <div
                  className={
                    isTab === "Open"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Open");
                    fetchOurStory("open");
                  }}
                >
                  Open
                </div>
                <div
                  className={
                    isTab === "Submitted"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Submitted");
                    fetchOurStory("submitted");
                  }}
                >
                  Submitted
                </div>
                <div
                  className={
                    isTab === "Approved"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Approved");
                    fetchOurStory("approved");
                  }}
                >
                  Approved
                </div>
                <div
                  className={
                    isTab === "Published"
                      ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-md-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Published");
                    fetchOurStory("published");
                  }}
                >
                  Published
                </div>
                <div
                  className={
                    isTab === "Rejected"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Rejected");
                    fetchOurStory("rejected");
                  }}
                >
                  Rejected
                </div>
                <div
                  className={
                    isTab === "Retract"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Retract");
                    fetchOurStory("Retract");
                  }}
                >
                  Retract
                </div>
                <div
                  className={
                    isTab === "Scheduled"
                      ? "px-0 col-sm-1 text-center active-stepper-form-head text-bold mb-0"
                      : "px-0 col-sm-1 text-center text-bold mb-0"
                  }
                  role="button"
                  onClick={(e) => {
                    setIsTab("Scheduled");
                    fetchOurStory("scheduled");
                  }}
                >
                  Scheduled
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Author</th>
                      <th>Story Type</th>
                      <th>Story Status</th>
                      <th>Created On</th>
                      <th>Breaking News</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!storiesList?.length > 0 || loading ? (
                      <Loader />
                    ) : (
                      storiesList?.map((story, index) => {
                        return (
                          <tr key={story?._id}>
                            <td>{index + 1}</td>
                            <td>{story?.title}</td>
                            {/* <td>{story.sub_title}</td> */}
                            <td className="text-capitalize">
                              {story?.authors?.length > 0
                                ? ConvertArrayTOString(
                                  story?.authors,
                                  "collection"
                                )
                                : "-"}
                            </td>

                            <td>{story?.story_type}</td>
                            <td className="text-capitalize">{story?.status}</td>

                            {/* <td>{story.created_by || `-`}</td> */}
                            <td>
                              {moment(story?.timestamp).format(
                                "MMMM DD, YYYY | hh:mm:ss a"
                              )}
                            </td>
                            <td>
                              <div className="icheckbox_flat-green">
                                {CheckAccess("story", "update") ? (
                                  <input
                                    type="checkbox"
                                    id="is_breaking_news"
                                    name="is_breaking_news"
                                    className="chk-col-grey"
                                    onChange={async (e) => {
                                      if (CheckAccess("story", "update")) {
                                        await UpdateDataApi(
                                          `${apiEndpoints.storiesById(
                                            story?._id
                                          )}`,
                                          { is_breaking_news: e.target.checked }
                                        );
                                        fetchOurStory();
                                      } else {
                                        Swal.fire({
                                          title: "You don't have permission.",
                                          text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                          icon: "warning",
                                        });
                                      }
                                    }}
                                    checked={story?.is_breaking_news}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    id="is_breaking_news"
                                    name="is_breaking_news"
                                    className="chk-col-grey"
                                    onChange={(e) => {
                                      e?.preventDefault();
                                      Swal.fire({
                                        title: "You don't have permission.",
                                        text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                        icon: "warning",
                                      });
                                    }}
                                    checked={story?.is_breaking_news}
                                  />
                                )}
                              </div>
                            </td>
                            <td className="d-flex align-items-center">
                              {CheckAccess("story", "view") && (
                                <div
                                  onClick={() =>
                                    navigate(`/story/view/${story?._id}`)
                                  }
                                  className="mr-2"
                                  role="button"
                                >
                                  <i className="fa fa-eye"></i>
                                </div>
                              )}
                              {story?.status === "approved" ? (
                                <>
                                  {CheckAccess("story", "update") &&
                                    CheckAccess(
                                      "story",
                                      "update approved story"
                                    ) && (
                                      <div
                                        onClick={() => {
                                          if (
                                            _.lowerCase(story?.story_type) ===
                                            _.lowerCase("Video Story")
                                          ) {
                                            navigate(
                                              `/video-story/update/${story?._id}`
                                            );
                                            return;
                                          }

                                          if (
                                            _.lowerCase(story?.story_type) ===
                                            _.lowerCase("Text Story")
                                          ) {
                                            navigate(
                                              `/story/update/${story?._id}`
                                            );
                                            return;
                                          }

                                          if (
                                            _.lowerCase(story?.story_type) ===
                                            _.lowerCase("Web Story")
                                          ) {
                                            navigate(
                                              `/web-story/update/${story?._id}`
                                            );
                                            return;
                                          }
                                          if (
                                            _.lowerCase(story?.story_type) ===
                                            _.lowerCase("Photo Story")
                                          ) {
                                            navigate(
                                              `/photo-story/update/${story?._id}`
                                            );
                                            return;
                                          }
                                          setAppData({ story_Id: story?._id });
                                        }}
                                        className="mr-2"
                                        role="button"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </div>
                                    )}
                                </>
                              ) : (
                                CheckAccess("story", "update") && (
                                  <div
                                    onClick={() => {
                                      if (
                                        _.lowerCase(story?.story_type) ===
                                        _.lowerCase("Video Story")
                                      ) {
                                        navigate(
                                          `/video-story/update/${story?._id}`
                                        );
                                        return;
                                      }

                                      if (
                                        _.lowerCase(story?.story_type) ===
                                        _.lowerCase("Text Story")
                                      ) {
                                        navigate(`/story/update/${story?._id}`);
                                        return;
                                      }

                                      if (
                                        _.lowerCase(story?.story_type) ===
                                        _.lowerCase("Web Story")
                                      ) {
                                        navigate(
                                          `/web-story/update/${story?._id}`
                                        );
                                        return;
                                      }
                                      if (
                                        _.lowerCase(story?.story_type) ===
                                        _.lowerCase("Photo Story")
                                      ) {
                                        navigate(
                                          `/photo-story/update/${story?._id}`
                                        );
                                        return;
                                      }
                                      setAppData({ story_Id: story?._id });
                                    }}
                                    className="mr-2"
                                    role="button"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </div>
                                )
                              )}
                              {CheckAccess("story", "delete") && (
                                <div
                                  onClick={(e) => {
                                    e?.preventDefault();
                                    handleDelete(story?._id);
                                  }}
                                  role="button"
                                >
                                  <i className="fa fa-trash"></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                {!loading && totalPages && storiesList?.length > 0 ? (
                  <div className={`container`}>
                    <div className={`row`}>
                      <div
                        className={`col-sm-12 d-flex justify-content-center px-0`}
                      >
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListStory;
