import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(`${config.apiUrl}/auth/login`, data);
      const responseData = response.data;
      // console.log(responseData.status + "Sachin J");
      if (responseData.status == "success") {
        // Store response data in session or local storage
        sessionStorage.setItem("user", JSON.stringify(responseData.user));
        sessionStorage.setItem("token", responseData.token);
        localStorage.setItem("user", JSON.stringify(responseData.user));
        localStorage.setItem("token", JSON.stringify(responseData.token));

        // Navigate to the dashboard route
        navigate("/dashboard");
        window.location.reload();
      } else {
        // console.log(data)
        toast.error(response.data.error);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Enter correct email or password.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <div className="container h-p100 baackground_image">
      {/* // <div className="row align-items-center height-100-vh justify-content-md-center h-p100 p-lg-5 p-5" style={{minHeight: "400px !important"}}  > */}
      <div
        className=" row align-items-center justify-content-md-center height-100-vh h-p100 "
        style={{ minHeight: "400px !important" }}
      >
        <div className="col-lg-7 col-md-11 col-12">
          <div
            className="bg-white p-40 row align-items-center justify-content-md-center h-p100"
            data-overlay-light="5"
          >
            <div className="col-lg-6 col-md-6 col-12">
              <div className=" text-center content-bottom">
                <img
                  src="../../../images/raftaarlogo.png"
                  className="no-border"
                  alt=""
                />
                {/* <img src="https://images.assettype.com/raftaar/2022-12/d7ee5677-e831-4b00-832b-8da33a5e06f1/Raftaar_Desktop.png" className="b-2  no-border" alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className=" content-bottom p-lg-5 ">
                <div className="content-top-agile p-lg-5 ">
                  <h2 className="text-bold">Login to your Dashboard</h2>
                  {/* <div></div> */}
                  <p></p>
                  <p></p>
                </div>
                <form
                  method="post"
                  onSubmit={handleSubmit(onSubmit)}
                  action="/dashboard"
                >
                  <div className="form-group">
                    <div className="input-group mb-3 mt-4 ">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        id="email"
                        placeholder="Username"
                        // value={email} // Bind to state variable
                        onChange={(e) => setEmail(e.target.value)}
                        {...register("email", {
                          required: "Please enter your email.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          {errors.email.message}
                        </div>
                      )}
                      {/* ... */}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        id="password"
                        placeholder="Password"
                        // value={password} // Bind to state variable
                        onChange={(e) => setPassword(e.target.value)}
                        {...register("password", {
                          required: "Please enter your password.",
                        })}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password.message}
                        </div>
                      )}
                      {/* ... */}
                    </div>
                  </div>
                  {/* ... */}
                  {/* <div className="col-12 text-center">
                    <button type="submit" className="btn btn-danger btn-block margin-top-10">SIGN IN</button>
                  </div> */}
                  <div className="row">
                    <div className="col-6">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id="basic_checkbox_1"
                          className="invisible"
                        />
                        <label htmlFor="basic_checkbox_1">Remember Me</label>
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                    <div className="col-6">
                      <div
                        className="fog-pwd text-right"
                        onClick={(e) => {
                          navigate("/forget-password");
                        }}
                      >
                        <a href="javascript:void(0)">
                          <i className="ion ion-locked"></i> Forgot pwd?
                        </a>
                        <br />
                      </div>
                    </div>
                    {/* <!-- /.col --> */}
                    {/* <div className="col-12 text-center"> */}
                    <div className="col-12 text-center mb-5">
                      <button
                        type="submit"
                        className="btn btn-danger btn-block margin-top-10"
                      >
                        SIGN IN
                      </button>
                    </div>
                    {/* <!-- /.col --> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
