import React from 'react'
import {useEffect} from 'react';
import { useForm } from "react-hook-form";
import { redirect, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css"; 
import { config } from '@fortawesome/fontawesome-svg-core';
import axios from 'axios';

function SectionUpdate(props) {
  const{sectionItem,onSubmit,onUpdate,handleSubmit,register,setValue,errors}=props;
  
    const pageTitle = "Update Section"
    const navigate = useNavigate();
    const userData = sessionStorage.getItem("user");
    // const user = userData ? JSON.parse(userData) : null;
    // console.log(sectionItem,'sectionItemsssssssss')
    useEffect(() => {
      console.log('useEffect triggered:', sectionItem);
      setValue("section_name", sectionItem?.section_name || '');
      setValue("display_name", sectionItem?.display_name || '');
      setValue("parent_section", sectionItem?.parent_section || '');
      setValue("page_title", sectionItem?.page_title || '');
      setValue("meta_title", sectionItem?.meta_title || '');
      setValue("description", sectionItem?.description || '');
      setValue("keyword", sectionItem?.keyword || '');
      setValue("status", sectionItem?.status || '');
    }, [sectionItem, setValue]);
    return (
      <>
      {/* <div className="content">
        <div className="content"> */}
         <div className=" col-md-12 m-auto">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <p></p>
            <div className="">
            <form onSubmit={handleSubmit(onUpdate)}>
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Section Name
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="section_name" className="form-control"{...register("section_name", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Name Is Required.</span>)}
                  </div>
                </div>
                
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Display Name
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="display_name" className="form-control"{...register("display_name", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Display Name Is Required.</span>)}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Parent Section
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="parent_section" className="form-control"{...register("parent_section", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Parent Section Is Required.</span>)}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Page Title
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="page_title" className="form-control"{...register("page_title", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Parent Section Is Required.</span>)}
                  </div>
                </div>
                 
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Meta Title
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="meta_title" className="form-control"{...register("meta_title", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Meta Title Is Required.</span>)}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Description
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="description" className="form-control"{...register("description", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Description Is Required.</span>)}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">
                    {" "}
                    Keywords
                  </label>
                  <div className="col-lg-8">
                    <input type="text" name="keywords" className="form-control"{...register("keywords", { required: true })}/>
                    {errors.name && (<span className="text-danger"> Keyword Is Required.</span>)}
                  </div>
                </div>

                
                 
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">Status</label>
                  <div className="col-lg-8">
                    <select name="status" {...register('type')} className="form-control">
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                  </div>
                </div>

                

                {}

                {/* <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Status
                  </label>
                  <div className="col-lg-8">
                    <select name="status" {...register("type")} className="form-control">
                      <option value={"Active"}>Active</option>
                      <option value={"Deactive"}>Deactive</option>
                    </select>
                  </div>
                </div> */}

               

                <div className="row mb-15">
                  <div className="col-sm-12 text-right">
                    <button className="btn btn-primary btn-round mr-5"
                     data-bs-dismiss="offcanvas" type="submit" >
                      {sectionItem?._id?`Update`:`Submit`}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        {/* </div>
      </div> */}
    </>
    )
  }

export default SectionUpdate