import React, { useEffect, useState } from "react";
import usePageshook from "./hook";
import { GetDataByIdApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import config from "../../../config";
import { Badge } from "react-bootstrap";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";

const SubCardComponent = (props) => {
  const { subcard } = props;
  return (
    <>
      {subcard?.sub_card?.length > 0 &&
        subcard?.sub_card?.map((item, key) => {
          return (
            <>
              {item?.type === "Image" && (
                <div
                  className="row display-flex justify-content-center mb-2"
                  key={key}
                >
                  <div className="col-sm-12 col-md-6 px-0 hero-image dropzone border position-relative">
                    <img
                      className="view-page-img"
                      src={`${config?.fileUrl}/${item?.images}`}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
                    <label
                      htmlFor="title"
                      className="col-form-label text-bold mb-0"
                    >
                      Caption
                    </label>
                    <div>caption</div>

                    <label
                      htmlFor="title"
                      className="col-form-label text-bold mb-0"
                    >
                      Attribution
                    </label>
                  </div>
                </div>
              )}
              {item?.type === "Text" && (
                <div
                  className="row display-flex justify-content-center mb-2"
                  key={key}
                >
                  <div className="card-header">
                    <div
                      className="text-bold text-uppercase"
                      dangerouslySetInnerHTML={{ __html: item?.title }}
                    />
                    {/* <h3 className="text-bold text-uppercase">
                      {item?.title || `--`}
                    </h3> */}
                  </div>
                </div>
              )}
              {item?.type === "Blockquote" && (
                <div
                  className="row display-flex justify-content-center mb-2"
                  key={key}
                >
                  <p>{item?.title}:</p>
                  <blockquote cite="">{item?.attributes}</blockquote>
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

const ViewStory = () => {
  const { id } = usePageshook();
  const [story, setStory] = useState();
  const FetchSingleStory = async () => {
    const response = await GetDataByIdApi(
      `${apiEndpoints.storiesById(id)}`,
      ""
    );
    setStory(response);
  };

  useEffect(() => {
    FetchSingleStory();
  }, [id]);
  return (
    <div className="content">
      <div className="card col-md-9 m-auto p-30">
        <div className="text-left">
          <div className="timeline-footer ">
            {/* <button
                  className="btn btn-primary btn-round btn-sm"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                  onClick={() => {
                    setIsOpenCanvas(true);
                    setSectionItem({ status: "Active" });
                    setValue("status", "Active");
                  }}
                >
                  Add
                </button>
                &nbsp; &nbsp; */}
            {/* <Button
                  onClick={() => {
                    setSectionItem(story);
                    setIsOpenCanvas(true);
                  }}
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Update Section
                </Button>
                &nbsp; &nbsp;
                <Link
                  to="/section"
                  className="btn btn-primary btn-round btn-sm "
                >
                  Manage
                </Link>
                &nbsp; &nbsp;
                <button
                  onClick={() => handleDelete(story?._id)}
                  className="btn btn-danger btn-round btn-sm "
                >
                  Delete
                </button> */}
          </div>
        </div>
        <div className="card-header">
          <h3 className="text-bold">View Story</h3>
        </div>
        <div className="card-body">
          <div className="row mb-15">
            <div className="row mb-15">
              <div className="col-lg-6 text-left border hero-image">
                <img
                  className="view-page-img"
                  src={`${config?.fileUrl}/${story?.hero_image?.file_path}`}
                />
              </div>
              <div className="col-lg-6 text-left no-border d-flex flex-column justify-content-center">
                <div className="row">
                  <div htmlFor="page_title" className="col-sm-5 pe-0">
                    <strong>Caption</strong>
                  </div>
                  <div className="col-lg-7  text-left no-border">
                    : {story?.hero_image?.caption}
                  </div>
                </div>
                <div className="row">
                  <div htmlFor="page_title" className="col-sm-5 pe-0">
                    <strong>Attribution</strong>
                  </div>
                  <div className="col-lg-7  text-left no-border">
                    : {story?.hero_image?.attribution}
                  </div>
                </div>
              </div>
            </div>
            <div htmlFor="page_title" className="col-sm-4  ">
              <strong>Title :</strong>
            </div>
            <div className="col-lg-8  text-left no-border">{story?.title}</div>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label  ">
              <strong> Sub Title :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.sub_title}
            </div>
          </div>
          <div className="row mb-15 d-flex bg-dark">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong className="text-bold">META DATA</strong>
            </label>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label">
              <strong>Section :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {/* {story?.sections?.length > 0
                ? ConvertArrayTOString(story?.sections, "opinionPoll")
                : `-`} */}

              {story?.sections?.length > 0
                ? ConvertArrayTOString(story?.sections, "")
                : `-`}
            </div>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong>Tags :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.tags?.length > 0
                ? ConvertArrayTOString(story?.tags, "")
                : `-`}
            </div>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Authors :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.authors?.length > 0
                ? ConvertArrayTOString(story?.authors, "collection")
                : "-"}
            </div>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Custom Slug :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.custom_slug}
            </div>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Slug Preview :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.slug_preview}
            </div>
            {/* <div className="col-lg-8  text-left no-border">
                  <Badge
                    bg={story?.status === "Active" ? "success" : "danger"}
                    text="white"
                  >
                    {story?.slu}
                  </Badge>
                </div> */}
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Canonical Url :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.canonical_url}
            </div>
          </div>
          {story?.story_features?.isSponsered_story ? (
            <div className="row mb-15 d-flex">
              <label htmlFor="page_title" className="col-sm-4 col-form-label ">
                <strong className="text-bold"> Story Features</strong>
              </label>
            </div>
          ) : (
            ""
          )}
          {story?.story_features?.isSponsered_story ? (
            <div className="row mb-15">
              <label htmlFor="page_title" className="col-sm-4 col-form-label ">
                <strong> Sponsored By :</strong>
              </label>
              <div className="col-lg-8  text-left no-border">
                {story?.story_features?.sponsored_by}
              </div>
              <label htmlFor="page_title" className="col-sm-4 col-form-label ">
                <strong> Enable Card Share for all card :</strong>
              </label>
              <div className="col-lg-8  text-left no-border">
                {story?.story_features?.isCard_shareable}
              </div>
              <label htmlFor="page_title" className="col-sm-4 col-form-label ">
                <strong> Enable Story Promotional Message :</strong>
              </label>
              <div className="col-lg-8  text-left no-border">
                {story?.story_features?.isStory_promotiona_message}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row mb-15 d-flex bg-dark">
            <label htmlFor="page_title" className="col-sm-4 col-form-label">
              <strong className="text-bold"> Seo</strong>
            </label>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Meta Title :</strong>
            </label>
            <div className="col-lg-8  text-left no-border h-auto">
              {story?.seo?.meta_title}
            </div>

            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Meta Description :</strong>
            </label>
            <div className="col-lg-8  text-left no-border h-auto">
              {story?.seo?.meta_description}
            </div>
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong> Meta Keyword :</strong>
            </label>
            <div className="col-lg-8  text-left no-border h-auto">
              {story?.seo?.meta_keywords}
            </div>
          </div>
          <div className="row mb-15 d-flex bg-dark">
            <label htmlFor="page_title" className="col-sm-4 col-form-label">
              <strong className="text-bold">SOCIAL</strong>
            </label>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong>Title :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.social?.social_title}
            </div>

            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong>Message :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.social?.message}
            </div>
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong>Platform:</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.social?.platform}
            </div>
          </div>
          {/* social share */}
          <div className="row mb-15 d-flex bg-dark">
            <label htmlFor="page_title" className="col-sm-4 col-form-label">
              <strong className="text-bold"> Social Share</strong>
            </label>
          </div>
          <div className="row mb-15">
            <label htmlFor="page_title" className="col-sm-4 col-form-label ">
              <strong>Social Description :</strong>
            </label>
            <div className="col-lg-8  text-left no-border">
              {story?.social_description}
            </div>
          </div>

          {story?.social_card?.length > 0 &&
            story?.social_card?.map((card, index) => {
              return (
                <div key={index}>
                  <div className="row mb-15">
                    <div className="col-sm-4 d-flex align-itmes-center">
                      <label
                        htmlFor="page_title"
                        className="col-form-label px-1  "
                      >
                        {`${index + 1}.`}
                      </label>

                      <label htmlFor="page_title" className=" col-form-label ">
                        <strong>Social Share Message :</strong>
                      </label>
                    </div>

                    <div className="col-lg-7  text-left no-border">
                      {card?.social_share_message}
                    </div>

                    <label
                      htmlFor="page_title"
                      className="col-sm-5 col-form-label "
                    >
                      <strong>
                        Raftaar Netlabs
                        <i className="fa fa-facebook-official ms-2"></i> :
                      </strong>
                    </label>
                    <div className="col-lg-7  text-left no-border">
                      {card?.is_raftaar_netlabs ? "Active" : "In Active"}
                    </div>
                    <label
                      htmlFor="page_title"
                      className="col-sm-5 col-form-label "
                    >
                      <strong>
                        Raftaar <i className="fa fa-facebook-official ms-2"></i>{" "}
                        :
                      </strong>
                    </label>
                    <div className="col-lg-7  text-left no-border">
                      {card?.is_raftaar ? "Active" : "In Active"}
                    </div>
                  </div>
                </div>
              );
            })}

          {story?.cards?.length > 0 ? (
            <div className="row mb-15 d-flex bg-dark">
              <label htmlFor="page_title" className="col-sm-4 col-form-label">
                <strong className="text-bold">CARDS</strong>
              </label>
            </div>
          ) : (
            ""
          )}
          {story?.cards?.length > 0 &&
            story?.cards?.map((card, index) => {
              return (
                <div key={index}>
                  <SubCardComponent subcard={card} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ViewStory;
