import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "./../../assets/images/logo-light-text.png";
import { CheckAccess } from "../common/utils/roleAccess";
import AppContext from "../appContext";
import { CreateDataApi, UpdateDataApi } from "../common/api/api";
import { apiEndpoints } from "../common/api/apiEndpoints";
import moment from "moment";
const Header = () => {
  const navigate = useNavigate();
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const { appData, setShowEditorNotes } = useContext(AppContext);

  const handleRejectStory = async (status) => {
    const response = await UpdateDataApi(
      `${apiEndpoints.storiesById(appData?.story_Id)}`,
      { status: status }
    );

    if (response) {
      const timeline = await CreateDataApi(apiEndpoints?.timeline, {
        story_id: response?._id,
        action_by: user?._id,
        action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
        action_type: status,
      });
      navigate("/story");
    }
  };
  return (
    <>
      <header className="main-header">
        {/* <!-- Logo --> */}
        <a href="index.html" className="logo">
          {/* <!-- mini logo for sidebar mini 50x50 pixels --> */}
          <b className="logo-mini">
            <span className="light-logo">
              <img src={Logo} alt="logo" />
            </span>
            <span className="dark-logo">
              <img src={Logo} alt="logo" />
            </span>
          </b>
          {/* <!-- logo for regular state and mobile devices --> */}
          <span className="logo-lg">
            <img
              src="../images/logo-light-text.png"
              alt="logo"
              className="light-logo"
            />
            <img
              src="../images/logo-dark-text.png"
              alt="logo"
              className="dark-logo"
            />
          </span>
        </a>
        {/* <!-- Header Navbar --> */}
        <nav className="navbar navbar-static-top">
          {/* <!-- Sidebar toggle button--> */}
          <div className="navbar-custom-menu">
            {" "}
            <a
              href="#"
              className="sidebar-toggle"
              data-toggle="push-menu"
              role="button"
            >
              <span className="sr-only">Toggle navigation</span>
            </a>
            <ul className="nav navbar-nav">
              <li className="dropdown notifications-menu ">
                <a
                  href="#"
                  className="dropdown-toggle "
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-plus remove-after"></i> Add New
                </a>
                <ul className="dropdown-menu scale-up add-new">
                  <li>
                    <ul className="menu inner-content-div overflow-hidden add-new-dropdown">
                      {CheckAccess("user", "create") && (
                        <li>
                          {/* <Link to="/admin">User</Link> */}
                          <div
                            className={`px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/admin");
                            }}
                          >
                            User
                          </div>
                        </li>
                      )}

                      {CheckAccess("story", "create") && (
                        <li>
                          <div
                            className={` px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/story/create");
                              window.location.reload();
                            }}
                          >
                            Story
                          </div>
                        </li>
                      )}
                      {CheckAccess("story", "index") && (
                        <li>
                          {/* <Link to="/web-story/create">Web Story</Link> */}
                          <div
                            className={`px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/web-story/create");
                              window.location.reload();
                            }}
                          >
                            Web Story
                          </div>
                        </li>
                      )}
                       {CheckAccess("story", "index") && (
                        <li>
                          {/* <Link to="/web-story/create">Web Story</Link> */}
                          <div
                            className={`px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/video-story/create");
                              window.location.reload();
                            }}
                          >
                            Video Story
                          </div>
                        </li>
                      )}
                      {CheckAccess("story", "index") && (
                        <li>
                          <div
                            className={`px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/photo-story/create");
                              window.location.reload();
                            }}
                          >
                            Photo Story
                          </div>
                        </li>
                      )}
                      {CheckAccess("collections", "create") && (
                        <li>
                          {/* <Link to="/collection/create">Collection</Link> */}
                          <div
                            className={`px-4 py-3 my-2 border-bottom`}
                            role="button"
                            onClick={(e) => {
                              navigate("/collection/create");
                            }}
                          >
                            Collection
                          </div>
                        </li>
                      )}
                      {CheckAccess("section", "create") && (
                        <li>
                          {/* <Link to="/section">Section</Link> */}
                          <div
                            className={`px-4 py-3 my-2`}
                            role="button"
                            onClick={(e) => {
                              navigate("/section");
                            }}
                          >
                            Section
                          </div>
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* <i className="glyphicon glyphicon-option-vertical"></i> */}

          <div className="navbar-custom-menu">
            <ul className="nav navbar-nav list-style-none">
              {appData?.story_Id && (
                <li className="dropdown ">
                  <a
                    href="#"
                    className="dropdown-toggle "
                    data-toggle="dropdown"
                  >
                    <i className="glyphicon glyphicon-option-vertical"></i>
                  </a>
                  <ul className="dropdown-menu scale-up add-new">
                    <li>
                      <ul className="menu inner-content-div">
                        {CheckAccess("story", "editor notes") && (
                          <li
                            onClick={(e) => {
                              e?.preventDefault();
                              setShowEditorNotes(true);
                            }}
                          >
                            <a href="#">Editor Notes</a>
                          </li>
                        )}
                        {appData?.status !== "published" &&
                          CheckAccess("story", "rejected") && (
                            <li
                              onClick={(e) => {
                                e?.preventDefault();
                                handleRejectStory("rejected");
                              }}
                            >
                              <a href="#">Reject</a>
                            </li>
                          )}
                        {CheckAccess("story", "retract") && (
                          <li
                            onClick={(e) => {
                              e?.preventDefault();
                              handleRejectStory("retract");
                            }}
                          >
                            <a href="#">Retract</a>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </li>
              )}
              {/* <!-- Notifications --> */}
              <li className="dropdown notifications-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <i className="fa fa-bell"></i>
                </a>
                <ul className="dropdown-menu scale-up">
                  <li className="header">You have 7 notifications</li>
                  <li>
                    {/* <!-- inner menu: contains the actual data --> */}
                    <ul className="menu inner-content-div">
                      <li>
                        <a href="#">
                          <i className="fa fa-users text-aqua"></i> Curabitur id
                          eros quis nunc suscipit blandit.
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fa fa-warning text-yellow"></i> Duis
                          malesuada justo eu sapien elementum, in semper diam
                          posuere.
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="footer">
                    <a href="#">View all</a>
                  </li>
                </ul>
              </li>
              {/* <!-- Tasks --> */}
              {/* <!-- User Account --> */}
              <li className="dropdown user user-menu">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <img
                    src="../images/user5-128x128.jpg"
                    className="user-image rounded-circle"
                    alt="User Image"
                  />
                </a>
                <ul className="dropdown-menu scale-up">
                  {/* <!-- User image --> */}
                  <li className="user-header">
                    <img
                      src="../images/user5-128x128.jpg"
                      className="float-left rounded-circle"
                      alt="User Image"
                    />
                    <p>
                      {user ? user.name : ""}
                      <small className="mb-5">{user ? user.email : ""}</small>
                      {/* <a href="#" className="btn btn-danger btn-sm btn-rounded">View Profile</a> */}
                    </p>
                  </li>
                  {/* <!-- Menu Body --> */}
                  <li className="user-body">
                    <div className="row no-gutters">
                      <div className="col-12 text-left">
                        <a href="#">
                          <i className="fa fa-user"></i> My Profile
                        </a>
                      </div>
                      <div className="col-12 text-left">
                        <Link to="/users/change-password">
                          {" "}
                          <i className="fa fa-key"></i> Change Password
                        </Link>
                      </div>
                      <div role="separator" className="divider col-12"></div>
                      <div
                        className="col-12 text-left"
                        onClick={() => {
                          navigate("/login");
                          sessionStorage.clear();
                          localStorage.clear();
                        }}
                      >
                        <Link to="/login">
                          <i className="fa fa-power-off"></i> Logout
                        </Link>
                      </div>

                      {/* <div role="separator" className="divider col-12"></div> */}
                    </div>
                    {/* <!-- /.row --> */}
                  </li>
                </ul>
              </li>
              {/* <!-- Control Sidebar Toggle Button --> */}
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
