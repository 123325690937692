import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import config from "../../../config";
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import { ToastContainer, toast } from "react-toastify";

import AllStepperPushNotification from "./stepper-push-notifications-form/AllStepperPushNotification";
import ScheduledStepperPushNotifiaction from "./stepper-push-notifications-form/ScheduledStepperPushNotifiaction";
import PublishedStepperPushNotifiaction from "./stepper-push-notifications-form/PublishedStepperPushNotifiaction";
import CreatePushNotification from "./CreatePushNotification";
import NotificationForm from "./CreatePushNotification";
import { GetListApi } from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import moment from "moment";
import Pagination from "../../../common/pagination";
import Swal from "sweetalert2";
import config from "../../../../config";
import { CheckAccess } from "../../../common/utils/roleAccess";
import { Trash } from "react-bootstrap-icons";

function ManagePushNotifications() {
  const pageTitle = "Push Notifications";
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [StepperPushNotififcation, setStepperPushNotififcation] = useState("");
  const [openCanvas, setOpenCanvas] = useState(false);
  const handleCloseCanvas = () => {
    setOpenCanvas(false);
  };
  const handleOpenCanvas = () => setOpenCanvas(true);

  const [notification, setNotification] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const FetchNotificationList = async () => {
    let params = {
      page: currentPage,
      status: StepperPushNotififcation,
      pageSize: 20,
    };
    const response = await GetListApi(apiEndpoints?.notification, params);
    setNotification(response?.notification);
    setTotalPages(response?.pages);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    FetchNotificationList();
  }, [currentPage]);
  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios
          .delete(`${config.apiUrl}/notification/${id}`)
          .then((response) => {
            FetchNotificationList();
            Swal.fire({
              title: "Item deleted successfully!",
              icon: "success",
            });
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };
  return (
    <>
      {/* <div className="content"> */}
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">{pageTitle}</h3>
            <div className="timeline-footer text-right">
              <button
                className="btn btn-primary btn-round btn-sm"
                onClick={handleOpenCanvas}
              >
                New Notification
              </button>
            </div>
          </div>

          <div className=" col-md-12 m-auto ">
            <div className="card-header">
              <div className={`col-md-4`}>
                <h3
                  className={
                    StepperPushNotififcation === ""
                      ? ` stepper-form-head active-stepper-form-head`
                      : ` stepper-form-head`
                  }
                  onClick={() => {
                    setStepperPushNotififcation("");
                    FetchNotificationList();
                  }}
                >
                  All
                </h3>
              </div>
              <div className={`col-md-4`}>
                <h3
                  className={
                    StepperPushNotififcation === "scheduled"
                      ? ` stepper-form-head active-stepper-form-head`
                      : ` stepper-form-head`
                  }
                  onClick={() => {
                    setStepperPushNotififcation("scheduled");
                    FetchNotificationList();
                  }}
                >
                  Send
                </h3>
              </div>
              <div className={`col-md-4`}>
                {/* <h3> */}
                <h3
                  className={
                    StepperPushNotififcation === "send"
                      ? ` stepper-form-head active-stepper-form-head`
                      : ` stepper-form-head`
                  }
                  onClick={() => {
                    setStepperPushNotififcation("send");
                    FetchNotificationList();
                  }}
                >
                  Scheduled
                </h3>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-bordered text-center text-capitalize">
                <thead className="">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Notification Type</th>
                    <th scope="col">Message</th>
                    <th scope="col">Message Link</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {notification?.length > 0 &&
                  notification?.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item?.notification_type}</td>
                        <td>
                          {item?.notification_type === "story"
                            ? item?.link_story?.label
                            : item?.message_body}
                        </td>
                        <td className="text-lowercase">{item?.message_link}</td>
                        <td>
                          {moment(item?.created_on).format(
                            "MMMM, DD YYYY | hh:mm:ss a"
                          )}
                        </td>
                        <td>{item?.status}</td>
                        <td>
                          {!CheckAccess("notification", "delete") && (
                            <button
                              type="button"
                              size={16}
                              className="btn btn"
                              onClick={() => handleDelete(item._id)}
                            >
                              <Trash
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </table>
              {totalPages ? (
                <div className={`container`}>
                  <div className={`row`}>
                    <div
                      className={`col-sm-12 d-flex justify-content-center px-0`}
                    >
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* offcanvas start  */}
              <NotificationForm
                openCanvas={openCanvas}
                setOpenCanvas={setOpenCanvas}
                handleCloseCanvas={handleCloseCanvas}
                FetchNotificationList={FetchNotificationList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagePushNotifications;
