import { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import { CreateDataApi, UpdateDataApi } from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../appContext";
import moment from "moment";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import DateTimeInput from "../../../common/date-time";

const PublishForm = (props) => {
  const {
    handleCloseCanvas,
    openCanvas,
    storyId,
    setStoryStatus,
    fetchTimeline,
    getValues,
    setLoading,
  } = props;
  const {
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAppData } = useContext(AppContext);
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();

  const [startDate, setStartDate] = useState();
  const [publishNow, setIsPublishNow] = useState(null);
  const [publisLater, setIsPublishLater] = useState(null);
  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const onPublish = async () => {
    let payload = {
      status: "published",
      publish_now: publishNow,
      publish_later: publisLater,
      status: publisLater ? "scheduled" : "published",
      publish_date: publisLater
        ? `${startDate}`
        : moment().format("MMMM, DD YYYY | hh:mm:ss a"),
    };
    if (publishNow || publisLater) {
      if (publisLater && !startDate) {
        Swal.fire({
          title: "Please select date or time!",
          icon: "warning",
        });
        return;
      }
      setLoading(true);

      const response = await UpdateDataApi(
        `${apiEndpoints.storiesById(id || storyId)}`,
        payload
      );

      if (response) {
        const timeline = await CreateDataApi(apiEndpoints?.timeline, {
          story_id: response?._id,
          action_by: user?._id,
          action_on: moment().format("MMMM DD YYYY, h:mm:ss a"),
          action_type: "published",
        });
        await fetchTimeline(`${response?._id}`);
        setLoading(false);
        setValue("story_id", response?._id);
        setStoryStatus(response?.status);
        setAppData("");
        navigate("/story");
        handleCloseCanvas();
      }
    } else {
      Swal.fire({
        title: "Please select publish now or publish later!",
        icon: "warning",
      });
    }
  };

  return (
    <div>
      <Offcanvas show={openCanvas} onHide={handleCloseCanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <button
              type="submit"
              className={`btn btn-primary`}
              onClick={(e) => {
                e?.preventDefault();
                onPublish();
              }}
            >
              Publish
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <form>
          <div className={`publish`}>
            <h4 className="fw-bold">Are you ready to publish?</h4>
            <p>
              Publish the story now or schedule it to be published later. Once
              the story is published, it will be available in all public places.
            </p>
            <div className="mb-5">
              <Form.Check
                inline
                className={`switchInput`}
                id={`inline-1`}
                label="Publish Now"
                name="publish_now"
                type={`radio`}
                onChange={(e) => {
                  setIsPublishNow(e?.target?.checked);
                  setIsPublishLater(false);
                }}
                checked={publishNow}
              />

              <Form.Check
                inline
                className={`switchInput`}
                label="Publish Later"
                name="publish_now"
                type={`radio`}
                id={`inline-2`}
                onChange={(e) => {
                  setIsPublishNow(false);
                  setIsPublishLater(e?.target?.checked);
                  setValue("publish_date", "");
                }}
                checked={publisLater}
              />
            </div>

            {publisLater && (
              <div className="mt-5">
                <h4 className="fw-bold">Schedule a time to publish:</h4>
                <p>
                  This story will be published automatically within five minutes
                  of specified time.
                </p>
                <div className="col-sm-12 px-0">
                  <div className="input-group">
                    <DateTimeInput
                      setStartDate={setStartDate}
                      startDate={startDate}
                    />
                    {/* <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      // excludeTimes={[
                      //   setHours(setMinutes(new Date(), 0), 17),
                      //   setHours(setMinutes(new Date(), 30), 18),
                      //   setHours(setMinutes(new Date(), 30), 19),
                      //   setHours(setMinutes(new Date(), 30), 17),
                      // ]}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    /> */}
                    {/* <input
                      type="datetime-local"
                      className="form-control pull-right"
                      // id={`datepicker`}
                      name="publish_date"
                      {...register("publish_date")}
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </Offcanvas>
    </div>
  );
};

export default PublishForm;
