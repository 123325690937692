import React from 'react'
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; 
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form';


function Static() {
    const pageTitle = " Page"
    const navigate = useNavigate();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    // const [selectedDate, setSelectedDate] = useState(null); 
    const userData = sessionStorage.getItem("user");
    // const user = userData ? JSON.parse(userData) : null;
  
    const onSubmit = (data) => {
      toast.success("Submit success");
    };
  return (
   <>
    <div className="content">
        <div className="content">
          <div className="card col-lg-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
            </div>
            <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>

               <div className="row mb-15 p-lg-3">
               {/* <h4> */}
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label "
                  >
                    {" "}
                  
                  </label>
                  <div className="col-lg-12 col-6">
                   <input
                    placeholder='Enter Title Here'

                      type="text"
                      // name="source_path"
                      className="form-control no-border font_size"
                      {...register("title", { required: true })}
                    />           
                    {errors.title && (
                      <span className="text-danger">
                       Title Cannot Be Empty
                      </span>
                    )}
                  </div>
               {/* </h4> */}
               </div>
                  

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Source Path
                  </label>
                  <div className="col-sm-3">
                    <input
                    placeholder='/'
                      type="text"
                      name="source_path"
                      className="form-control"
                      {...register("source_path", { required: true })}
                    />
                    {errors.source_path && (
                      <span className="text-danger">
                        Source Path Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>


                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    MIME Type
                  </label>
                  <div className="col-sm-3">
                    <select
                      name="menu_type"
                      {...register("mime_type", { required: true })}
                      className="form-control"
                    >
                      <option value={""}> Select</option>
                      <option value={"Empty_1"}>Empty 1</option>
                      <option value={"Empty_2"}>Empty 2</option>
                      <option value={"Empty_3"}>
                      Empty 3{" "}
                      </option>
                    </select>
                    {errors.mime_type && (
                      <span className="text-danger">
                         MIME Type is required.
                      </span>
                    )}
                  </div>
                </div>

{/* check buttons start from here  */}
<div className="row mb-15">
                                  <label
                                    htmlFor="type"
                                    className="col-sm-4 col-form-label text-right"
                                  >
                                    {" "}
                                    Show
                                  </label>
                                  <div className="col-sm-3 ">
                                    <div className='row'>
                                      <div className='col-lg-6'> <Form.Check // prettier-ignore
                                      type="switch"
                                      id="header-switch"
                                      label="Header "
                                      name="header-switch"
                                      className="form-control  no-border"
                                      // {...register("header-switch", { required: true })}
                                    /></div>
                                      <div className='col-lg-6'>
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="footer-switch"
                                      label="Footer "
                                      name="footer-switch"
                                      className="form-control  no-border"
                                      // {...register("footer-switch", { required: true })}
                                    />
                                      </div>
                                    </div>
                                   
                                  

                                  </div>
                                </div>



                {/* <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Meta Description
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="meta_description"
                      className="form-control"
                      {...register("meta_description", { required: true })}
                    />
                    {errors.meta_description && (
                      <span className="text-danger">
                              Meta Description Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div> */}


                 {/* <Form>
      <Form.Check // prettier-ignore
        type="switch"
        id="header-switch"
        label="Header "
      />
      <Form.Check // prettier-ignore
        disabled
        type="switch"
        label="Footer "
        id="footer-switch"
      />
    </Form> */}
{/* check buttons ends from here  */}

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Content
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="content"
                      className="form-control"
                      {...register("content", { required: true })}
                    />
                    {errors.content && (
                      <span className="text-danger">
                        Content Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>

                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Meta Title
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="meta_title"
                      className="form-control"
                      {...register("meta_title", { required: true })}
                    />
                    {errors.meta_title && (
                      <span className="text-danger">
                     Meta Ttile Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>


                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Meta Description
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="meta_description"
                      className="form-control"
                      {...register("meta_description", { required: true })}
                    />
                    {errors.meta_description && (
                      <span className="text-danger">
                              Meta Description Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>



                <div className="row mb-15">
                  <label
                    htmlFor="type"
                    className="col-sm-4 col-form-label text-right"
                  >
                    {" "}
                    Meta Keywords
                  </label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      name="meta_keywords"
                      className="form-control"
                      {...register("meta_keywords", { required: true })}
                    />
                    {errors.meta_keywords && (
                      <span className="text-danger">
                      Meta Keywords Cannot Be Empty
                      </span>
                    )}
                  </div>
                </div>


                <div className="text-center">
                <button
                    className="btn btn-primary btn-round "
                    type="submit"
                  >
                    Submit
                  </button>
                  </div>
                 

                  
                  {/* <button
                    className="btn btn-primary btn-round"
                    type="submit"
                  >
                    CAncel
                  </button> */}
                {/* </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
   </>
  )
}

export default Static