import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import { ToastContainer, toast } from "react-toastify";   

const AddWorks = () => {
  const pageTitle = "Add New Work";
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const [selectedDate, setSelectedDate] = useState(null); // State for the selected date
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;

  const onSubmit = (data) => {
    data.project_date = selectedDate;
    data.created_by = user.name;
    data.status = "active";
    axios.post(`${config.apiUrl}/our-work`, data)
      .then(response => {
        // console.log('Work added successfully:', response.data);
        toast.success("Work added successfully");
        navigate('/our-works');
        // Clear the form or show a success message as needed
      })
      .catch(error => {
        console.error('Error adding work:', error);
        // Handle error, show error message, etc.
      });
  };


  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              <div className="timeline-footer text-right">
                <Link to="/our-works/add" className="btn btn-primary btn-round btn-sm">Add Work</Link>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right">Work Type</label>
                  <div className="col-sm-3">
                    <select name="type" {...register('type', { required: true })} className="form-control">
                      <option value={""}>Please Select</option>
                      <option value={"Website Development"}>Website Development</option>
                      <option value={"Software Development"}>Software Development</option>
                      <option value={"Mobile App Development"}>Mobile App Development</option>
                      <option value={"Search Engine Optimisation"}>Search Engine Optimisation</option>
                      <option value={"Social Media Management"}>Social Media Management</option>
                    </select>
                    {errors.type && <span className="text-danger">Work Type is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="client_name" className="col-sm-4 col-form-label text-right">Client Name</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="client_name" placeholder="Client Name" {...register('client_name', { required: true })} />
                    {errors.client_name && <span className="text-danger">Client Name is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="url" className="col-sm-4 col-form-label text-right">Work URL</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="url" placeholder="Work URL" {...register('url', { required: true })} />
                    {errors.url && <span className="text-danger">Work URL is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="images" className="col-sm-4 col-form-label text-right">Work Images</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="images" placeholder="Work Images" {...register('images', { required: true })} />
                    {errors.images && <span className="text-danger">Image is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="videos" className="col-sm-4 col-form-label text-right">Work Videos</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="videos" placeholder="Work Videos" {...register('videos', { required: true })} />
                    {errors.videos && <span className="text-danger">Video is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="title" className="col-sm-4 col-form-label text-right">Title</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="title" placeholder="Title" {...register('title', { required: true })} />
                    {errors.title && <span className="text-danger">Title is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="short_description" className="col-sm-4 col-form-label text-right">Short Description</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="short_description" placeholder="Short Description" {...register('short_description', { required: true })} />
                    {errors.short_description && <span className="text-danger">Short Description is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="description" className="col-sm-4 col-form-label text-right">Description</label>
                  <div className="col-sm-5">
                    <textarea type="text" className="form-control" name="description" placeholder="Description" {...register('description', { required: true })}></textarea>
                    {errors.description && <span className="text-danger">Short Description is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="meta_title" className="col-sm-4 col-form-label text-right">Meta Title</label>
                  <div className="col-sm-4">
                    <input type="text" className="form-control" name="meta_title" placeholder="Meta Title" {...register('meta_title')} />
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">Meta Description</label>
                  <div className="col-sm-5">
                    <input type="text" className="form-control" name="meta_description" placeholder="Meta Description" {...register('meta_description')} />
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="project_date" className="col-sm-4 col-form-label text-right">Project Date</label>
                  <div className="col-sm-3">
                    <DatePicker
                      selected={selectedDate}
                      onChange={date => setSelectedDate(date)}
                      name="project_date"
                      placeholderText="Project Date"
                      className="form-control"
                      dateFormat="dd-MM-yyyy" // Customize the date format if needed
                    // {...register('project_date', { required: true })}
                    />
                    {errors.project_date && <> <br /><span className="text-danger">Project Date is required.</span></>}
                  </div>
                </div>
                {/* <div className="row mb-15"><hr /></div> */}

                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <button className="btn btn-primary btn-round mr-5" type="submit">Submit</button>
                    <Link to="/our-works" className="btn btn-primary btn-round" type="reset">Cancel</Link>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddWorks;