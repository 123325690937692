import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const RulesForm = (props) => {
  const {
    errors,
    register,
    handleChange,
    rulesType,
    HandleSelectChange,
    tagsList,
    selectedTagsList,
    storyTemplate,
    selectedStoryTemplate,
    includeSectionList,
    selectedIncludeSection,
    excludeSectionList,
    selectedExcludeSection,
    id,
    authorList,
    selectedAuthorList,
    sortedByList,
    selectedSortedBy,
    isAnyFieldFilled, setIsAnyFieldFilled
  } = props;
  // const [attributes, setAttributes] = useState([
  //   { selAttribute: "", selAttributeValue: "" },
  // ]);

  // const handleAttributeValueChange = (index, field, newValue) => {
  //   const updatedAttributes = [...attributes];
  //   updatedAttributes[index][field] = newValue;
  //   setAttributes(updatedAttributes);
  // };

  // const handleAddField = () => {
  //   setAttributes([...attributes, { selAttribute: "", selAttributeValue: "" }]);
  // };
  // const handleRemoveField = (index) => {
  //   const updatedAttributes = [...attributes];
  //   updatedAttributes.splice(index, 1);
  //   setAttributes(updatedAttributes);
  // };

  return (
    <>
      <div className="card-body">
        <div className="row mb-15 justify-content-center">
          <div className="col-sm-3">
            <div className="d-flex align-items-center justify-content-center">
              <label htmlFor="selTags" className="col-form-label pr-2">
                Automated
              </label>
              <div>
                <input
                  type="radio"
                  name="automated"
                  value="automated"
                  checked={rulesType === "automated" ? true : false}
                  {...register("automated")}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="d-flex align-items-center">
              <label htmlFor="selTags" className="col-form-label pr-2">
                Manual
              </label>
              <input
                type="radio"
                name="automated"
                value="manual"
                {...register("automated")}
                onChange={(e) => handleChange(e)}
                checked={rulesType === "manual" ? true : false}
              />
            </div>
          </div>
        </div>
        {rulesType === "automated" ? (
          <>
            <div className="row mb-15">
              <label
                htmlFor="tags"
                className="col-sm-5 col-form-label text-right"
              >
                Tags
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="tags"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={tagsList}
                  isMulti
                  {...register("tags", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("tags", e)}
                  value={selectedTagsList}
                ></Select>

                {errors.tags && (
                  <span className="text-danger">Tags is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="selIncludeSections"
                className="col-sm-5 col-form-label text-right"
              >
                Include Sections
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="include_section"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                  options={includeSectionList}
                  value={selectedIncludeSection}
                  {...register("include_section", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("include_section", e)}
                ></Select>
                {errors.include_section && (
                  <span className="text-danger">
                    Include section is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="selExcludeSections"
                className="col-sm-5 col-form-label text-right"
              >
                Exclude Sections
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="exclude_section"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  isMulti
                  options={excludeSectionList}
                  {...register("exclude_section", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("exclude_section", e)}
                  value={selectedExcludeSection}
                ></Select>
                {errors.exclude_section && (
                  <span className="text-danger">
                    Exclude section is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="storyTemplates"
                className="col-sm-5 col-form-label text-right"
              >
                Story Templates
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="story_template"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={storyTemplate}
                  {...register("story_template", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("story_template", e)}
                  value={selectedStoryTemplate}
                ></Select>

                {errors.story_template && (
                  <span className="text-danger">
                    Story template is required.
                  </span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="selAuthors"
                className="col-sm-5 col-form-label text-right"
              >
                Authors
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="author"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={authorList}
                  {...register("author", { required: isAnyFieldFilled })}
                  onChange={(e) => HandleSelectChange("author", e)}
                  value={selectedAuthorList}
                ></Select>
                {errors.author && (
                  <span className="text-danger">Author is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="selSortedBy"
                className="col-sm-5 col-form-label text-right"
              >
                Sorted By
              </label>
              <div className="col-sm-3">
                <Select
                  className={`outline-none`}
                  name="sort_by"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={sortedByList}
                  {...register("sort_by")}
                  onChange={(e) => HandleSelectChange("sort_by", e)}
                  value={selectedSortedBy}
                ></Select>
                {errors.sort_by && (
                  <span className="text-danger">Sort by is required.</span>
                )}
              </div>
            </div>
            {/* {attributes?.map((item, key) => {
                return (
                  <div className="row " key={key}>
                    <div className="col-md-5">
                      <div className="row mb-15 justify-content-center">
                        <label
                          htmlFor="selAttribute"
                          className="col-sm-5 col-form-label text-right"
                        >
                          Attribute
                        </label>
                        <div className="col-sm-5">
                          <select
                            // name="selAttributes"
                            // {...register('selAttributes')} className={`form-control-${key}`}
                            onChange={(e) =>handleAttributeValueChange(key, "selAttribute", e.target.value)}
                            value={item.key}
                          >
                            <option value={""}>Please Select</option>
                            <option value={"Website Development"}>
                              Website Development
                            </option>
                            <option value={"Software Development"}>
                              Software Development
                            </option>
                            <option value={"Software"}>
                              Software
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row mb-15 justify-content-center">
                        <label
                          htmlFor="txtValue"
                          className="col-sm-3 col-form-label text-right"
                        >
                          Value
                        </label>
                        <div className="col-sm-7">
                          <Select
                            className={`form-control-${key} outline-none-${key}`}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={alphabetsOptions}
                            onChange={(e) => handleAttributeValueChange(key, "selAttributeValue", e)}
                          >
                            {alphabetsOptions?.map((item) => {
                              return <option>{item?.value}</option>;
                            })}
                          </Select>
                          <div></div>
                        </div>
                        <div
                          className="col-sm-1 d-flex align-items-center cursor-pointer"
                          onClick={() => handleRemoveField(key)}
                        >
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })} */}
            {/* <div className={`row mb-15`}>
                <div className="col-md-5">
                  <div
                    className="row mb-15 justify-content-end"
                    onClick={handleAddField}
                  >
                    <div className="col-sm-5 col-form-label cursor-pointer add-icon">
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div> */}
            {/* <div className="row mb-15">
              <div className="col-sm-12 text-center">
                <button
                  className="btn btn-primary btn-round mr-5"
                  type="submit"
                >
                  {id ? `Update` : `Submit`}
                </button>
                <Link
                  to="/collection"
                  className="btn btn-primary btn-round"
                  type="reset"
                >
                  Cancel
                </Link>
              </div>
            </div> */}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default RulesForm;
