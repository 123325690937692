import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";

import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { CheckAccess } from "../../common/utils/roleAccess";
const animatedComponents = makeAnimated();
const StoryDetailForm = ({
  name,
  register,
  handleSubmit,
  errors,
  setValue,
  handleChange,
  handleShow,
  handleClose,
  show,
  setShow,
  formData,
  setFormData,
  tagsList,
  setTagsList,
  selectedTagsList,
  setSelectedTagsList,
  selectedIncludeSection,
  includeSectionList,
  setIncludeSectionList,
  authorList,
  setAuthorList,
  selectedAuthorList,
  setSelectedAuthorList,
  HandleSelectChange,

  socialCard,
  handleAddElement,
  handleDeleteElement,
  handleSocialCardChange,
  HandleModalDataSubmit,
  HandleGetSection,
  slug,
  setSlug,
  onAutoSaveStory,
  timelines,
  fetchNewCustomSlugData,
  ...props
}) => {
  const [isTab, setIsTab] = useState("metadata");

  useEffect(() => {
    setValue("authors", selectedAuthorList);
  }, []);

  return (
    <>
      <Offcanvas
        className="story-canvas"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h4 className="text-bold mb-0">Story Details</h4>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={`row mb-15 d-flex justify-content-center`}>
            <div
              className={
                isTab === "metadata"
                  ? "col-sm-4 text-center active-stepper-form-head text-bold mb-0"
                  : "col-sm-4 text-center text-bold mb-0"
              }
              role="button"
              onClick={(e) => {
                setIsTab("metadata");
              }}
            >
              METADATA
            </div>
            <div
              className={
                isTab === "social"
                  ? "col-sm-4 text-center active-stepper-form-head text-bold mb-0"
                  : "col-sm-4 text-center text-bold mb-0"
              }
              role="button"
              onClick={(e) => {
                setIsTab("social");
              }}
            >
              SOCIAL
            </div>
          </div>
          {isTab === "metadata" && (
            <>
              <div className="row mb-15">
                <label
                  htmlFor="Sections"
                  className="col-sm-4 col-form-label text-right"
                >
                  Sections
                </label>
                <div className="col-sm-8">
                  <Select
                    className={`outline-none`}
                    name="sections"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isMulti
                    options={includeSectionList}
                    value={selectedIncludeSection}
                    {...register("sections", {
                      required: true,
                    })}
                    onChange={(e) => {
                      HandleSelectChange("sections", e);
                      HandleGetSection(e);
                    }}
                    onBlur={(e) => {
                      e?.preventDefault();
                      onAutoSaveStory();
                    }}
                  ></Select>
                  {errors.sections && (
                    <span className="text-danger">
                      Include section is required.
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="Sections"
                  className="col-sm-4 col-form-label text-right"
                >
                  Tags
                </label>
                <div className="col-sm-8">
                  <Select
                    className={`outline-none`}
                    name="tags"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isMulti
                    options={tagsList}
                    {...register("tags", { required: true })}
                    onChange={(e) => HandleSelectChange("tags", e)}
                    onBlur={(e) => {
                      e?.preventDefault();
                      onAutoSaveStory();
                    }}
                    value={selectedTagsList}
                  ></Select>
                  {errors.tags && (
                    <span className="text-danger">Tags is required.</span>
                  )}
                </div>
              </div>
              {CheckAccess("story", "update author") && (
                <div className="row mb-15">
                  <label
                    htmlFor="Sections"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Authors
                  </label>
                  <div className="col-sm-8">
                    <Select
                      className={`outline-none`}
                      name="authors"
                      isMulti={true}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={authorList}
                      {...register("authors", { required: true })}
                      onChange={(e) => HandleSelectChange("authors", e)}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                      value={selectedAuthorList}
                    ></Select>
                    {errors.authors && (
                      <span className="text-danger">Author is required.</span>
                    )}
                  </div>
                </div>
              )}
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Custom Slug
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="custom_slug"
                    className="form-control"
                    {...register("custom_slug", {
                      required: "Custom slug is required",
                    })}
                    onChange={handleChange}
                    onBlur={(e) => {
                      e?.preventDefault();
                      fetchNewCustomSlugData(e?.target?.value);
                    }}
                    readOnly={timelines?.some(
                      (timeline) => timeline?.action_type === "published"
                    )}
                  />
                  {errors.custom_slug && (
                    <span className="text-danger">
                      {errors.custom_slug.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Slug Preview
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="slug_preview"
                    className="form-control"
                    {...register("slug_preview", {
                      // required: "Custom Display is required",
                    })}
                    onChange={handleChange}
                   readOnly={true}
                  />
                  {errors.slug_preview && (
                    <span className="text-danger">
                      {errors.slug_preview.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Canonical Url
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    name="canonical_url"
                    className="form-control"
                    {...register("canonical_url", {
                      // required: "Canonical url is required",
                    })}
                    onChange={handleChange}
                    onBlur={(e) => {
                      e?.preventDefault();
                      onAutoSaveStory();
                    }}
                  />
                  {/* {errors.canonical_url && (
                    <span className="text-danger">
                      {errors.canonical_url.message}
                    </span>
                  )} */}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right text-bold"
                >
                  Story Features
                </label>
                <div className="col-sm-8 d-flex align-items-center">
                  <div className="hLine"></div>
                </div>
              </div>
              <>
                <div className={`row d-flex justify-content-end`}>
                  <div className="col-sm-10 ps-0">
                    <Form.Check
                      className={`switchInput`}
                      type="checkbox"
                      id="isSponsered_story"
                      label="This Is A Sponsored Story"
                      name="isSponsered_story"
                      checked={formData.isSponsered_story}
                      onChange={handleChange}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                    />
                  </div>
                </div>
                {formData?.isSponsered_story ? (
                  <div className="row my-15">
                    <label
                      htmlFor="meta_description"
                      className="col-sm-4 col-form-label text-right"
                    >
                      Sponsored By
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        id="sponsored_by"
                        name="sponsored_by"
                        className="form-control"
                        {...register("sponsored_by", {
                          // required: "Canonical url is required",
                        })}
                        onChange={handleChange}
                        onBlur={(e) => {
                          e?.preventDefault();
                          onAutoSaveStory();
                        }}
                      />
                      {errors.sponsered_by && (
                        <span className="text-danger">
                          {errors.sponsered_by.message}
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className={`row d-flex justify-content-end`}>
                  <div className="col-sm-10 ps-0">
                    <Form.Check
                      className={`switchInput`}
                      type="checkbox"
                      id="isCard_shareable"
                      label="Enable Card Share for all cards"
                      name="isCard_shareable"
                      checked={formData.isCard_shareable}
                      onChange={handleChange}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                    />
                  </div>
                </div>
                <div className={`row d-flex justify-content-end`}>
                  <div className="col-sm-10 ps-0">
                    <Form.Check
                      className={`switchInput`}
                      type="checkbox"
                      id="isStory_promotional_message"
                      label="Disable Story Promotional Message"
                      name="isStory_promotional_message"
                      checked={formData.isStory_promotional_message}
                      onChange={handleChange}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                    />
                  </div>
                </div>
                <div className={`row d-flex justify-content-end`}>
                  <div className="col-sm-10 ps-0">
                    <Form.Check
                      className={`switchInput`}
                      type="checkbox"
                      id="is_breaking_news"
                      label="Breaking News"
                      name="is_breaking_news"
                      checked={formData.is_breaking_news}
                      onChange={handleChange}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                    />
                  </div>
                </div>
                <div className={`row d-flex justify-content-end`}>
                  <div className="col-sm-10 ps-0">
                    <Form.Check
                      className={`switchInput`}
                      type="checkbox"
                      id="disclaimer"
                      label="Disclaimer"
                      name="disclaimer"
                      checked={formData?.disclaimer}
                      onChange={handleChange}
                      onBlur={(e) => {
                        e?.preventDefault();
                        onAutoSaveStory();
                      }}
                    />
                  </div>
                </div>
              </>
              <div className="row my-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right text-bold"
                >
                  SEO
                </label>
                <div className="col-sm-8 d-flex align-items-center">
                  <div className="hLine"></div>
                </div>
              </div>
              <>
                {/* Meta Title */}
                <div className="row mb-15">
                  <label
                    htmlFor="meta_title"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Meta Title
                    <p>{`${formData?.meta_title?.length || 0}/110`}</p>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      as="textarea"
                      name="meta_title"
                      maxLength={110}
                      {...register("meta_title", {
                        required: true,
                        maxLength: 110,
                      })}
                      onChange={handleChange}
                      // onBlur={(e) => {
                      //   // e?.preventDefault();
                      //   onAutoSaveStory();
                      // }}
                      
                      className="border"
                      isInvalid={formData?.meta_title?.length > 110}
                    />

                    {errors.meta_title && (
                      <span className="text-danger">
                        Meta Title is required.
                      </span>
                    )}
                  </div>
                </div>

                {/* Meta Display */}
                <div className="row mb-15">
                  <label
                    htmlFor="meta_description"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Meta Description
                    <p>{`${formData?.meta_description?.length || 0}/200`}</p>
                  </label>
                  <div className="col-sm-8">
                    <Form.Control
                      as="textarea"
                      name="meta_description"
                      maxLength={200}
                      {...register("meta_description", {
                        required: true,
                        maxLength: 200,
                      })}
                      onChange={handleChange}
                      // onBlur={(e) => {
                      //   e?.preventDefault();
                      //   onAutoSaveStory();
                      // }}
                      className="border"
                      isInvalid={formData?.meta_description?.length > 200}
                    />

                    {errors.meta_description && (
                      <span className="text-danger">
                        Meta Description is required.
                      </span>
                    )}
                  </div>
                </div>

                {/* Meta Keyword */}
                <div className="row mb-15">
                  <label
                    htmlFor="meta_keywords"
                    className="col-sm-4 col-form-label text-right"
                  >
                    Meta Keyword
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="meta_keywords"
                      className="form-control"
                      {...register("meta_keywords", {
                        required: "Meta Keyword is required",
                      })}
                      onChange={handleChange}
                      // onBlur={(e) => {
                      //   e?.preventDefault();
                      //   onAutoSaveStory();
                      // }}
                    />
                    {errors.meta_keywords && (
                      <span className="text-danger">
                        {errors.meta_keywords.message}
                      </span>
                    )}
                  </div>
                </div>
              </>
            </>
          )}

          {isTab === "social" && (
            <>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right text-bold"
                >
                  Push Notification
                </label>
                <div className="col-sm-8 d-flex align-items-center">
                  <div className="hLine"></div>
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Set Title
                  <p>{`${formData?.social_title?.length || 0}/140`}</p>
                </label>
                <div className="col-sm-8">
                  <Form.Control
                    as="textarea"
                    name="social_title"
                    maxLength={140}
                    {...register("social_title", {
                      // required: "Title is required",
                      maxLength: 140,
                    })}
                    onChange={handleChange}
                    // onBlur={(e) => {
                    //   e?.preventDefault();
                    //   onAutoSaveStory();
                    // }}
                    className="border textarea-height"
                    isInvalid={formData?.social_title?.length > 140}
                  />
                  {errors.social_title && (
                    <span className="text-danger">
                      {errors.social_title.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Set Message
                  <p>{`${formData?.message?.length || 0}/140`}</p>
                </label>
                <div className="col-sm-8">
                  <Form.Control
                    as="textarea"
                    name="message"
                    maxLength={140}
                    {...register("message", {
                      // required: "Title is required",
                      maxLength: 140,
                    })}
                    onChange={handleChange}
                    // onBlur={(e) => {
                    //   e?.preventDefault();
                    //   onAutoSaveStory();
                    // }}
                    className="border textarea-height"
                    isInvalid={formData?.message?.length > 140}
                  />
                  {errors.message && (
                    <span className="text-danger">
                      {errors.message.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Select Platform
                </label>
                <div className="col-sm-8">
                  <select
                    name="platform"
                    className="form-control"
                    {...register("platform", {
                      // required: "Custom Display is required",
                    })}
                    // onBlur={(e) => {
                    //   e?.preventDefault();
                    //   onAutoSaveStory();
                    // }}
                  >
                    <option>All</option>
                    <option>Web</option>
                    <option>Mobile</option>
                  </select>

                  {errors.platform && (
                    <span className="text-danger">
                      {errors.platform.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right text-bold"
                >
                  Social Share
                </label>
                <div className="col-sm-8 d-flex align-items-center">
                  <div className="hLine"></div>
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="meta_description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Social Description
                  <p>{`${formData?.social_description?.length || 0}/1k`}</p>
                </label>
                <div className="col-sm-8">
                  <Form.Control
                    as="textarea"
                    name="social_description"
                    maxLength={1000}
                    {...register("social_description", {
                      // required: "Title is required",
                      maxLength: 1000,
                    })}
                    onChange={handleChange}
                    // onBlur={(e) => {
                    //   e?.preventDefault();
                    //   onAutoSaveStory();
                    // }}
                    className="border textarea-height"
                    isInvalid={formData?.social_description?.length > 1000}
                  />

                  {errors.social_description && (
                    <span className="text-danger">
                      {errors.social_description.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-15 text-center">
                <div className="col-sm-6">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    onClick={(e) => {
                      handleAddElement();
                    }}
                  >
                    Add Social Card
                  </button>
                </div>
              </div>
              {socialCard?.length > 0 &&
                socialCard?.map((socialcard, key) => {
                  return (
                    <div key={key}>
                      <div className="row justify-content-end">
                        <div
                          className="col-sm-1 my-3"
                          role="button"
                          onClick={(e) => handleDeleteElement(key)}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                      </div>
                      <div className="row mb-15">
                        <label
                          htmlFor="meta_description"
                          className="col-sm-4 col-form-label text-right"
                        >
                          Social Share Message
                          <p>{`${
                            socialcard?.social_share_message?.length || 0
                          }/256`}</p>
                        </label>
                        <div className="col-sm-8">
                          <Form.Control
                            as="textarea"
                            name="social_share_message"
                            maxLength={256}
                            // {...register("social_description", {
                            //   // required: "Title is required",
                            //   maxLength: 1000,
                            // })}
                            className="border textarea-height"
                            onChange={(e) =>
                              handleSocialCardChange(
                                key,
                                "social_share_message",
                                e.target.value
                              )
                            }
                            // onBlur={(e) => {
                            //   e?.preventDefault();
                            //   onAutoSaveStory();
                            // }}
                            value={socialcard?.social_share_message}
                            isInvalid={
                              socialcard?.social_share_message?.length > 256
                            }
                          />

                          {errors.social_share_message && (
                            <span className="text-danger">
                              {errors.social_share_message.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={`row d-flex justify-content-end`}>
                        <div className="col-sm-10 ps-0">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-facebook-official"></i>
                            <Form.Check
                              className={`switchInput ps-2`}
                              type="checkbox"
                              label="Raftaar Netlabs"
                              name="is_raftaar_netlabs"
                              id={`is_raftaar_netlabs-${key}`}
                              checked={socialcard?.is_raftaar_netlabs}
                              onChange={(e) =>
                                handleSocialCardChange(
                                  key,
                                  "is_raftaar_netlabs",
                                  e.target.checked
                                )
                              }
                              // onBlur={(e) => {
                              //   e?.preventDefault();
                              //   onAutoSaveStory();
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`row d-flex justify-content-end mb-15`}>
                        <div className="col-sm-10 ps-0">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-facebook-official"></i>
                            <Form.Check
                              className={`switchInput ps-2`}
                              type="checkbox"
                              label="Raftaar"
                              name="is_raftaar"
                              id={`is_raftaar-${key}`}
                              checked={socialcard?.is_raftaar}
                              onChange={(e) =>
                                handleSocialCardChange(
                                  key,
                                  "is_raftaar",
                                  e.target.checked
                                )
                              }
                              // onBlur={(e) => {
                              //   e?.preventDefault();
                              //   onAutoSaveStory();
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`row d-flex justify-content-end mb-15`}>
                        <div className="col-sm-10 ps-0">
                          <Form.Check
                            className={`switchInput ps-0`}
                            type="checkbox"
                            id={`is_scheduled-${key}`}
                            label="Scheduled"
                            name="is_scheduled"
                            checked={socialcard?.is_scheduled}
                            onChange={(e) =>
                              handleSocialCardChange(
                                key,
                                "is_scheduled",
                                e.target.checked
                              )
                            }
                            // onBlur={(e) => {
                            //   e?.preventDefault();
                            //   onAutoSaveStory();
                            // }}
                          />
                        </div>
                      </div>
                      {socialcard?.is_scheduled ? (
                        <div className={`row d-flex justify-content-end mb-15`}>
                          <label
                            htmlFor="meta_description"
                            className="col-sm-4 col-form-label text-right"
                          >
                            Date
                          </label>
                          <div className="col-sm-8">
                            <div className="input-group date">
                              <input
                                type="date"
                                className="form-control pull-right"
                                id={`datepicker-${key}`}
                                // name="scheduled_date"
                                // {...register("scheduled_date")}
                                onChange={(e) =>
                                  handleSocialCardChange(
                                    key,
                                    "scheduled_date",
                                    e.target.value
                                  )
                                }
                                // onBlur={(e) => {
                                //   e?.preventDefault();
                                //   onAutoSaveStory();
                                // }}
                                value={socialcard?.scheduled_date}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default StoryDetailForm;
