import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { apiEndpoints } from "../../../common/api/apiEndpoints";

const usePermissionGroupHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [listPermissionGroup, setListPermissionGroup] = useState([]);
  const [prmGrpOption, setPrmGrpOption] = useState([{ value: "", label: "" }]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const onSubmitPermissionGroup = async (data) => {
    try {
      data.created_by = user?.user_role;
      const response = await axios.post(
        `${config.apiUrl}/${apiEndpoints?.permissionGroup}/create`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        toast.success("Permission group created successfully.");
        navigate("/user-management/permissions-group");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onUpdatePermissionGroup = async (data) => {
    try {
      const response = await axios.put(
        `${config.apiUrl}/permission-group/${id}`,
        data
      );
      const responseData = response.data;
      if (responseData) {
        toast.success("Permission group updated successfully.");
        navigate("/user-management/permissions-group");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPermissionGroupList = async (text, status) => {
    try {
      const response = await axios.get(
        // console.log(response, "working")
        `${config.apiUrl}/${apiEndpoints?.permissionGroup}`,
        {
          params: {
            name: text,
            status: status,
            pagination: true,
          },
        }
      );
      setListPermissionGroup(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchPermissionGroupList();
  }, []);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/${apiEndpoints?.permissionGroupById(id)}`)
          .then((response) => {
            console.log(
              "Permission group deleted successfully:",
              response.data
            );
            fetchPermissionGroups();
            toast.success("Permission group deleted successfully");
          })
          .catch((error) => {
            console.error("Error deleting permission group:", error);
            toast.error("An error occurred while deleting permission group");
          });
      }
    });
  };
  const fetchPermissionGroups = () => {
    axios
      .get(`${config.apiUrl}/${apiEndpoints?.permissionGroup}`)
      .then((response) => {
        setListPermissionGroup(response.data);
      })
      .catch((error) => {
        console.error("Error fetching permission groups:", error);
      });
  };
  useEffect(() => {
    fetchPermissionGroups();
  }, []);

  return {
    id,
    navigate,
    onSubmitPermissionGroup,
    onUpdatePermissionGroup,
    fetchPermissionGroupList,
    listPermissionGroup,
    setListPermissionGroup,
    register,
    handleSubmit,
    setValue,
    handleDelete,
    errors,
    prmGrpOption,
    setPrmGrpOption,
  };
};

export default usePermissionGroupHook;
