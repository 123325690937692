import React, { useState } from "react";

const DateTimeInput = ({ setStartDate, startDate, notification = "" }) => {
  const [selectedDateTime, setSelectedDateTime] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState("");

  const formatDateTime = () => {
    const inputDate = new Date(selectedDateTime);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    const formattedDateTime = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );

    setFormattedDateTime(formattedDateTime);
    setStartDate(formattedDateTime);
  };

  return (
    <div>
      {/* <label htmlFor="datetime">Select Date and Time:</label> */}
      <input
        className="form-control"
        type="datetime-local"
        id="datetime"
        value={selectedDateTime}
        onChange={(e) => {
          setSelectedDateTime(e.target.value);
        }}
        onBlur={formatDateTime}
      />

      {formattedDateTime && (
        <p>
          {notification !== "" ? notification : `Published`} Date and Time:
          <br /> {formattedDateTime}
        </p>
      )}
    </div>
  );
};

export default DateTimeInput;
