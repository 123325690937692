import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { GetListApi } from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";

function UserCreate(props) {
  const {
    onSubmit,
    register,
    handleSubmit,
    errors,
    setValue,
    editUser,
    onUserUpdate,
  } = props;
  const [rolesList, setRolesList] = useState([]);

  const pageTitle = editUser?._id ? "Update User" : "Create User";
  const fetchRolesList = async () => {
    const roles = await GetListApi(`${apiEndpoints?.roles}`, {
      status: "Active",
      pagination:false
    });
    if (roles?.roles?.length) {
    
      setRolesList(roles?.roles);
    }
  };
console.log(editUser,'editUser')
  useEffect(() => {
    fetchRolesList();
    setValue("Fname", editUser?.name?.split(" ")[0]);
    setValue("Lname", editUser?.name?.split(" ")[1]);
    setValue("mobile", editUser?.mobile);
    setValue("email", editUser?.email);
    setValue("password", editUser?.password);
    setValue("user_role", editUser?.user_role);
    setValue("status", editUser?.status || "Active");
  }, [editUser]);
  return (
    <>
      <div className=" col-md-12 m-auto ">
        <div className="card-header">
          <h3 className="text-bold">{pageTitle}</h3>
        </div>
        <p></p>
        <div className="">
          <form
            onSubmit={
              editUser?._id
                ? handleSubmit(onUserUpdate)
                : handleSubmit(onSubmit)
            }
          >
            <div className="row mb-15">
              <label
                htmlFor="Fname"
                className="col-sm-4 col-form-label text-right"
              >
                First Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="Fname"
                  placeholder="First  Name"
                  {...register("Fname", { required: true })}
                />
                {errors.Fname && (
                  <span className="text-danger">First Name is required.</span>
                )}
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="Lname"
                className="col-sm-4 col-form-label text-right"
              >
                {" "}
                Last Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  name="Lname"
                  placeholder="Last Name"
                  {...register("Lname", { required: true })}
                />
                {errors.Lname && (
                  <span className="text-danger">Last Name is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="mobile"
                className="col-sm-4 col-form-label text-right"
              >
                {" "}
                Mobile
              </label>
              <div className="col-sm-8">
                <input
                  type="number"
                  className="form-control"
                  name="mobile"
                  placeholder=" Number"
                  {...register("mobile", {
                    required: true,
                  })}
                />
                {errors.mobile && (
                  <span className="text-danger"> Number is required.</span>
                )}
              </div>
            </div>
            <div className="row mb-15">
              <label
                htmlFor="Email"
                className="col-sm-4 col-form-label text-right"
              >
                {" "}
                Email
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder=" Email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="text-danger"> Email is required.</span>
                )}
              </div>
            </div>
            {!editUser?._id ? (
              <div className="row mb-15">
                <label
                  htmlFor="Lname"
                  className="col-sm-4 col-form-label text-right"
                >
                  {" "}
                  Password
                </label>
                <div className="col-sm-8">
                  {!editUser?._id ? (
                    <>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="password"
                        // disabled={editUser?.password ? true : false}
                        {...register("password", { required: true })}
                      />
                      {errors.Lname && (
                        <span className="text-danger">
                          Password is required.
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Role
              </label>
              <div className="col-lg-8">
                <select
                  name="type"
                  {...register("user_role")}
                  className="form-control"
                >
                  <option value={""}>Select</option>
                  {rolesList?.length > 0 &&
                    rolesList?.map((role, key) => {
                      return (
                        <option value={role?._id} key={key}>
                          {role?.name || `-`}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row mb-15">
              <label
                htmlFor="type"
                className="col-sm-4 col-form-label text-right"
              >
                Status
              </label>
              <div className="col-lg-8">
                <select
                  name="status"
                  {...register("status")}
                  className="form-control"
                  // defaultValue={"Active"}
                >
                  <option value={"Active"}>Active</option>
                  <option value={"Inactive"}>InActive</option>
                </select>
              </div>
            </div>
            <div className="row mb-15">
              <div className="col-sm-12 text-end">
                <button
                  // id="#closeCanvas"
                  className="btn btn-primary btn-round mr-5"
                  data-bs-dismiss="offcanvas"
                  type="submit"
                >
                  {editUser?._id ? `Update` : `Submit`}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default UserCreate;
