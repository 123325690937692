import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../../config";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import {
  selectOption,
  templateOption,
} from "../../common/common-data/alphabets";
import { CreateDataApi, GetListApi } from "../../common/api/api";
import { CheckAccess } from "../../common/utils/roleAccess";
import AppContext from "../../appContext";
const useCollectionHook = () => {
  const navigate = useNavigate();
  const { searchItem, setSearchItem } = useContext(AppContext);

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [collectionReads, setCollectionReads] = useState([]);

  const [listData, setListData] = useState([]);
  const [currentPageCollection, setCurrentPageCollection] = useState(1);
  const [currentPage, setCurrentPage] = useState();
  const [inputs, setInputs] = useState({});
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);

  const [rulesType, setRulesType] = useState("manual");

  const [collectionList, setCollectionList] = useState();
  const [imgUrl, setImgUrl] = useState("");
  const [tagsList, setTagsList] = useState(selectOption);
  const [selectedTagsList, setSelectedTagsList] = useState([]);

  const [storyTemplate, setStoryTemplate] = useState(templateOption);

  const [selectedStoryTemplate, setSelectedStoryTemplate] =
    useState(selectOption);

  const [includeSectionList, setIncludeSectionList] = useState(selectOption);
  const [selectedIncludeSection, setSelectedIncludeSection] = useState([]);

  const [excludeSectionList, setExcludeSectionList] = useState(selectOption);
  const [selectedExcludeSection, setSelectedExcludeSection] = useState([]);

  const [authorList, setAuthorList] = useState(selectOption);
  const [selectedAuthorList, setSelectedAuthorList] = useState([]);
  const [sortedByList, setSortedByList] = useState([
    {
      label: "latest published",
      value: "latest published",
    },
    {
      label: "trending 24 hours",
      value: "trending 24 hours",
    },
  ]);
  const [selectedSortedBy, setSelectedSortedBy] = useState(selectOption);

  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [stepperForm, setStepperForm] = useState("contentForm");

  const HandleSelectChange = (type, e) => {
    if (type === "tags") {
      setValue("tags", e);
      setSelectedTagsList(e);
      setSearchItem({ tags: e });
    } else if (type === "story_template") {
      setValue("story_template", e?.value);
      setSelectedStoryTemplate(e);
    } else if (type === "include_section") {
      setValue("include_section", e);
      setSelectedIncludeSection(e);
      setSearchItem({ ...searchItem, sections: e });
    } else if (type === "exclude_section") {
      setValue("exclude_section", e);
      setSelectedExcludeSection(e);
    } else if (type === "author") {
      setValue("author", e?.value);
      setSelectedAuthorList(e);
    } else if (type === "sort_by") {
      setValue("sort_by", e?.value);
      setSelectedSortedBy(e);
    }
    setIsAnyFieldFilled(false);
  };

  const onSubmit = async (data) => {
    const userData = localStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;
    let dt = new Date();
    data.created_by = user?.name;
    data.updated_by = user?.name;
    data.created_on = dt;
    data.updated_on = dt;
    data.created_by = user.name;
    data.rule_type = rulesType;
    data.status = "Active";
    const response = await CreateDataApi(`collection/create`, data);
    if (response) {
      FetchCollectionList();
      navigate(`/collection/update/${response?._id}`);
    }
  };

  const onUpdate = async (data) => {
    const userData = sessionStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;
    let dt = new Date();
    data.updated_by = user?.name;
    data.updated_on = dt;
    data.rule_type = rulesType;
    data.status = "Active";
    data.author = selectedAuthorList?.value;
    await axios
      .put(`${config.apiUrl}/${apiEndpoints?.collectionById(id)}`, data)
      .then((response) => {
        toast.success("Collection updated successfully");
        FetchCollectionList();
        navigate("/collection");
      })
      .catch((error) => {
        console.error("Error updating collection:", error);
      });
  };

  const FetchCollectionList = async () => {
    let params = {
      page: currentPageCollection,
      pageSize: 20,
    };
    const collections = await GetListApi(`collection`, params);
    setCollectionList(collections?.collections);
    setCurrentPage(collections?.pages)
  };
  useEffect(() => {
    FetchCollectionList();
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.apiUrl}/${apiEndpoints.collectionById(id)}`)
          .then((response) => {
            FetchCollectionList();
            toast.success("Collection deleted successfully", {
              position: "bottom-center",
            });
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            toast.error("An error occurred while deleting");
          });
      }
    });
  };

  let AttributeValueArr = [
    {
      id: 1,
      attribute: "Attribute",
      value: [],
    },
  ];
  const [attributeValuePair, setAttributeValuePair] =
    useState(AttributeValueArr);
  const handleAddAttributeValueFields = () => {
    setAttributeValuePair((attributeValuePair) => [
      ...attributeValuePair,
      { id: Number(AttributeValueArr?.length + 1), attribute: "", value: "" },
    ]);
  };
  const handleRemoveAttributeValueFields = (index) => {
    if (index > -1) {
      attributeValuePair.splice(index, 1);
    }
    setAttributeValuePair((attributeValuePair) => [...attributeValuePair]);
  };
  const handleChange = (e) => {
    setValue("rule_type", e.target.value);
    setRulesType(e.target.value);
  };
  const [selectedValues, setSelectedValues] = useState([]);
  const handleAttributeChange = (event, name, index) => {
    setAttributeValuePair((prevAttributeValuePair) => {
      const newArray = [...prevAttributeValuePair];
      newArray.splice(index, 1, {
        attribute: event.target.value,
        // value: selectedValues (add this line if selectedValues is defined)
      });
      return newArray;
    });
  };
  const handlSelectValue = (event, name, index) => {
    setSelectedValues(event);
  };

  //section
  const fetchOurSection = () => {
    axios
      .get(`${config.apiUrl}/section`, {
        pagination: false,
      })
      .then((response) => {
        let arr = [];
        if (response.data.sections.length > 0) {
          let result = response?.data?.sections?.map((sec) => {
            let sectionString = sec?.section_name;
            if (sec?.parent_section && sec?.parent_section?.length > 0) {
              let subSectionString = sec?.parent_section
                ?.map((subSec) => subSec)
                .join(" > ");
              sectionString += " > " + subSectionString;
            }
            let reversedSectionString = sectionString
              .split(" > ")
              .reverse()
              .join(" > ");

            arr?.push({
              label: reversedSectionString,
              value: sec?._id,
              parents: sec?.parent_section,
            });
            return reversedSectionString;
          });
          setIncludeSectionList(arr);
          setExcludeSectionList(arr);
        }
      })
      .catch((error) => {
        console.error("Error fetching contact requests:", error);
      });
  };
  //tags
  const FetchTagsList = async () => {
    try {
      const response = await axios
        .get(`${config.apiUrl}/${apiEndpoints.tags}`, {
          params: {},
        })
        .then((response) => {
          let arr = [];
          if (response.data.length > 0) {
            response.data.forEach((element) => {
              arr?.push({
                label: element?.name,
                value: element?._id,
              });
              setTagsList(arr);
            });
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchAuthorList = async (user_role = "author") => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.user}`,
        {
          params: {
            user_role: user_role,
          },
        }
      );

      let arr = [];

      if (response.data.users.length > 0) {
        response.data.users.forEach((element) => {
          if (element?.user_role === "author") {
            arr?.push({
              label: element?.name,
              value: element?._id,
            });
          }
        });

        setAuthorList(arr);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (stepperForm === "RulesForm") {
      FetchTagsList();
      fetchOurSection();
      fetchAuthorList();
    }
  }, [stepperForm]);

  const fetchCollectionById = async (id) => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints?.collectionById(id)}`
      );
      if (response?.data) {
        if (response?.data?.rule?.include_section?.length > 0) {
          setSelectedIncludeSection(response?.data?.rule?.include_section);
          setValue("include_section", response?.data?.rule?.include_section);
        }
        if (response?.data?.rule?.exclude_section?.length > 0) {
          setSelectedExcludeSection(response?.data?.rule?.exclude_section);
          setValue("exclude_section", response?.data?.rule?.exclude_section);
        }
        setSelectedStoryTemplate([
          {
            label: response?.data?.rule?.story_template,
            value: response?.data?.rule?.story_template,
          },
        ]);

        if (response?.data?.rule?.tags.length > 0) {
          let arr = [];
          response?.data?.rule?.tags.forEach((element) => {
            arr?.push({
              label: element?.label,
              value: element?._id,
            });
          });
          setSelectedTagsList(arr);
        }
        setSelectedAuthorList({
          label: response?.data?.rule?.author?.name,
          value: response?.data?.rule?.author?._id,
        });
        setValue("author", response?.data?.rule?.author?._id);
        setSelectedSortedBy({
          label: response?.data?.rule?.sort_by,
          value: response?.data?.rule?.sort_by,
        });

        setInputs({
          title: response?.data?.title,
          summary: response?.data?.summary,
        });

        setValue("title", response?.data?.title);
        setValue("summary", response?.data?.summary);
        setValue("updated_on", response?.data?.updated_on);
        setValue("template", response?.data?.template);

        setValue("tags", response?.data?.rule?.tags);
        setValue("story_template", response?.data?.rule?.story_template);
        setValue("author", response?.data?.rule?.author?.name);
        setValue("sort_by", response?.data?.rule?.sort_by);
        setImgUrl(`${config?.apiUrl}/${response?.data?.images?.file_path}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (id) {
      fetchCollectionById(id);
      FetchTagsList();
      fetchOurSection();
      fetchAuthorList();
    }
  }, [id]);
  useEffect(() => {
    if (selectedIncludeSection?.length > 0 && includeSectionList?.length > 0) {
      let excludeArr = [];
      includeSectionList.forEach((element) => {
        if (
          !selectedIncludeSection.some(
            (selectedEl) => selectedEl?.value === element?.value
          )
        ) {
          excludeArr.push({
            label: element?.label,
            value: element?.value,
          });
        }
      });
      setExcludeSectionList(excludeArr);
    }
    if (selectedExcludeSection?.length > 0 && excludeSectionList?.length > 0) {
      let includeArr = [];
      excludeSectionList.forEach((element) => {
        if (
          !selectedExcludeSection.some(
            (selectedEl) => selectedEl?.value === element?.value
          )
        ) {
          includeArr.push({
            label: element?.label,
            value: element?.value,
          });
        }
      });
      setIncludeSectionList(includeArr);
    }
  }, [selectedIncludeSection, selectedExcludeSection]);

  const HandleUploadImage = async (files) => {
    let data = {};

    try {
      if (files?.length > 0) {
        const formData = new FormData();
        data.status = "Active";
        const res = await axios.post(
          `${config.apiUrl}/${apiEndpoints.upload}`,
          { files: files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res?.data?.length) {
          setValue("file_name", res?.data[0]?.file_name);
          setValue("file_path", res?.data[0]?.file_path);
          setValue("file_Id", res?.data[0]?._id);
          // setImgUrl(`${config?.apiUrl}/${res?.data[0]?.file_path}`);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const onCollectionListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      const dt = new Date();

      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios.put(
        `${config.apiUrl}/${apiEndpoints?.collection}`,
        {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: data?.originalOrder,
        }
      );

      if (response.data) {
        FetchCollectionList();
        toast.success("Collection updated successfully.");
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Error updating collection list:", error);
    }
  };

  //content form
  const [contentFormErr, setContentFormErr] = useState({
    title: "",
    summary: "",
  });

  const HandleErrorChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
    setContentFormErr({ ...errors, [name]: "" });
  };
  const HandleNext = () => {
    const newErrors = {};
    if (!inputs.title) {
      newErrors.title = "title is required";
    }
    if (!inputs.summary) {
      newErrors.summary = "summary is required";
    }

    if (Object.keys(newErrors).length > 0) {
      setContentFormErr(newErrors);
      return;
    }

    setStepperForm("RulesForm");
  };

  const fetchOurCollection = async (status, query) => {
    const param = {
      page: currentPageCollection,
      pageSize: 20,
      pagination: true,
      q: query,
      title: query,
    };
    const response = await GetListApi(`${apiEndpoints.collection}`, param);
    if (response) {
      setCollectionList(response?.collections);
      setCurrentPage(response?.pages)
      // setCurrentPageCollection(response);
    }
  };
  return {
    navigate,
    register,
    handleSubmit,
    handleChange,
    selectedDate,
    setSelectedDate,
    fetchOurCollection,
    rulesType,
    setRulesType,
    user,
    onSubmit,
    onUpdate,
    AttributeValueArr,
    attributeValuePair,
    setAttributeValuePair,
    handleAddAttributeValueFields,
    handleRemoveAttributeValueFields,
    handleAttributeChange,
    handlSelectValue,
    // FetchCollectionList,
    collectionList,
    setCollectionList,
    handleDelete,
    HandleSelectChange,
    tagsList,
    setTagsList,
    selectedTagsList,
    setSelectedTagsList,
    storyTemplate,
    setStoryTemplate,
    selectedStoryTemplate,
    setSelectedStoryTemplate,
    includeSectionList,
    setIncludeSectionList,
    selectedIncludeSection,
    setSelectedIncludeSection,
    excludeSectionList,
    setExcludeSectionList,
    selectedExcludeSection,
    setSelectedExcludeSection,
    id,
    errors,
    authorList,
    setAuthorList,
    selectedAuthorList,
    setSelectedAuthorList,
    sortedByList,
    selectedSortedBy,
    stepperForm,
    setStepperForm,
    HandleUploadImage,
    setImgUrl,
    imgUrl,
    listData,
    setListData,
    collectionReads,
    setCollectionReads,
    FetchCollectionList,
    onCollectionListUpdate,
    isAnyFieldFilled,
    setIsAnyFieldFilled,
    inputs,
    setInputs,
    HandleErrorChange,
    HandleNext,
    contentFormErr,
    getValues,
    currentPageCollection,
    setCurrentPageCollection,
  };
};

export default useCollectionHook;
