import _ from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";

export const ConvertArrayTOString = (parents, type) => {
  if (type === "section") {
    let arr = parents;
    const resultString = arr.join(" > ");
    return resultString;
  } else if (type === "opinionPoll" && parents?.length > 0) {
    let valuesArray = parents?.map((obj) => obj.value);
    let stringValue = valuesArray.join(", ");
    return stringValue;
  } else if (type === "collection" && parents?.length > 0) {
    let valuesArray = parents?.map((obj) => obj?.label);
    let stringValue = valuesArray.join(", ");
    return stringValue;
  } else if (type === "dash" && parents?.length > 0) {
    let arr = parents;
    const resultString = arr.join("-");
    let slug = _.lowerCase(resultString);
    return slug;
  } else if (type === "slash" && parents?.length > 0) {
    let arr = parents;
    const resultString = arr.join("/");
    let slug = _.lowerCase(resultString);
    return slug;
  } else if (parents?.length > 0) {
    let valuesArray = parents?.map((obj) => obj.label);
    let stringValue = valuesArray.join(", ");
    return stringValue;
  }
};
