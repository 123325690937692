import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import useUserHook from "./hook";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    errors,
    handleChangePassword,
    id
  } = useUserHook();
  const [newPassword, setNewPassword] = useState("");
  const [consfirmPassword, setConfirmPassword] = useState("");
  return (
      <div className="container h-p100 baackground_image">
        <div
          className=" row align-items-center justify-content-md-center height-100-vh h-p100 "
          style={{ minHeight: "400px !important" }}
        >
          <div className="col-lg-7 col-md-11 col-12">
            <div
              className="bg-white p-40 row align-items-center justify-content-md-center h-p100"
              data-overlay-light="5"
            >
              <div className="col-lg-6 col-md-6 col-12">
                <div className=" text-center content-bottom">
                  <img
                    src="../../../images/raftaarlogo.png"
                    className="no-border"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className=" content-bottom p-lg-5 ">
                  <div className="content-top-agile p-lg-5 ">
                    <h2 className="text-bold">RECOVER PASSWORD</h2>
                    <p></p>
                    <p></p>
                  </div>
                  <form
                    method="post"
                    onSubmit={handleSubmit(handleChangePassword)}
                  >
                    <>
                      <div className="input-group mb-3 mt-4 ">
                        <input
                          className={`form-control ${
                            errors.newPassword ? "is-invalid" : ""
                          }`}
                          id="newPassword"
                          type="password"
                          name="newPassword"
                          placeholder="New Password"
                          // value={email} // Bind to state variable
                          onChange={(e) => setNewPassword(e.target.value)}
                          {...register("newPassword", {
                            required: "Please enter your new password.",
                            pattern: {
                              // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Please enter a new password.",
                            },
                          })}
                        />
                        {errors.newPassword && (
                          <div className="invalid-feedback">
                            {errors.newPassword.message}
                          </div>
                        )}
                        {/* ... */}
                      </div>
                      <div className="form-group">
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className={`form-control ${
                              errors.confirmPassword ? "is-invalid" : ""
                            }`}
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            // value={password} // Bind to state variable
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            {...register("confirmPassword", {
                              required: "Please enter your confirm password.",
                            })}
                          />
                          {errors.confirmPassword && (
                            <div className="invalid-feedback">
                              {errors.confirmPassword.message}
                            </div>
                          )}
                          {/* ... */}
                        </div>
                      </div>
                      <div className="col-12 text-center mb-5 px-0">
                        <button
                          type="submit"
                          className="btn btn-danger btn-block margin-top-10"
                        >
                          Submit
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ResetPassword;
