import React, { useContext, useEffect, useState } from "react";
import usePageshook from "./hook";
import { Link } from "react-router-dom";
import Pagination from "../../common/pagination";
import moment from "moment";
import { CreateDataApi, UpdateDataApi } from "../../common/api/api";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import Loader from "../../common/utils/loader";
import _, { filter } from "lodash";
import { CheckAccess } from "../../common/utils/roleAccess";
import Swal from "sweetalert2";
import AppContext from "../../appContext";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import config from "../../../config";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const BreakingNews = () => {
    const [template, setTemplate] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');
    const [section, setSection] = useState('');
    const [author, setAuthor] = useState('');
    const [breakingNews, setBreakingNews] = useState(null)
    const [filteredBreakingNews, setFilteredBreakingNews] = useState([])
    const [activeBreakingNews, setActiveBreakingNews] = useState(null)
    const [breakingTotalPages, setBreakingTotalPages] = useState(0)
    const [addStoryType, setAddStoryType] = useState(null)
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [searchStories, setSearchStories] = useState([]);
    const [linkStory, setLinkStory] = useState(null)
    const [activeTab, setActiceTab] = useState(false)
    const [dbUpdating, setDbUpdating] = useState(false);


    const [droppableId, setDroppableId] = useState('hello-stories');

    useEffect(() => {
        if (activeBreakingNews?.length) {
            setDroppableId('stories');
        }
    }, [activeBreakingNews?.length]);

    const {
        totalPages,
        storiesList,
        handleDelete,
        currentPage,
        handlePageChange,
        navigate,
        fetchOurStory,
        loading,
        setLoading,
        setStoriesList,
        authorList,
        sections,
    } = usePageshook();
    const { setAppData } = useContext(AppContext);
    const [isTab, setIsTab] = useState("All");
    const userData = localStorage.getItem("user");
    const user = userData ? JSON.parse(userData) : null;
    useEffect(() => {
        setAppData("");

        const fetchBreakingNews = async () => {
            const url = `${config.apiUrl}/stories?page=1&pageSize=100&pagination=true&is_breaking_news=true&is_breaking_news_only=true`
            const response = await fetch(url)
            const data = await response.json()

            if (data && data.stories) {
                data.stories.sort((a, b) => {
                    const dateA = new Date(a.updatedAt);
                    const dateB = new Date(b.updatedAt);
                    return dateB - dateA;
                });

                data.stories.sort((a, b) => {
                    return (b.sort_order - a.sort_order)
                })

                setBreakingNews(data?.stories)
                setFilteredBreakingNews(data?.stories)
                setBreakingTotalPages(data?.pages / 20 + (data.pages % 20))
            }
        }

        if (!breakingNews)
            fetchBreakingNews()
    }, []);

    const publishBreakingClick = async () => {
        if (addStoryType === 'Text Story') {
            const breakingNewsTitle = (document.getElementById("breaking-news-title")?.value || "").trim();

            if (breakingNewsTitle === '') return;

            const userData = JSON.parse(localStorage.getItem("user"));

            const authorPayload = {
                id: userData?._id,
                value: userData?._id,
                label: userData?.name
            }

            const response = await CreateDataApi('stories',
                {
                    title: breakingNewsTitle,
                    is_breaking_news: true,
                    slug: new Date().toISOString(),
                    authors: [authorPayload],
                    story_type: 'Text Story',
                    status: 'published',
                    is_breaking_news_only: true,
                    publish_date: new Date().toISOString()
                }
            )
            if (response) {
                window.location.reload()
            }
        }
        else if (addStoryType === 'Link Story') {

            const id = linkStory?.id

            if (!id) {
                alert('Please select a story')
            }

            try {
                const updatedStory = await UpdateDataApi(`stories/${id}`, { is_breaking_news: true });
                if (updatedStory) {
                    window.location.reload();
                }
            } catch (error) {
                alert(error.message);
                window.location.reload();
            }
        }
    }

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value;
        setSearchKeyWord(searchText);
        handleSearch()
    };

    const delayedSearch = debounce(async (searchKeyWord) => {
        if (!searchKeyWord || searchKeyWord === '') return
        try {
            const result = await fetch(`${config.apiUrl}/stories?story_type=Text Story&q=${searchKeyWord}&page=1&pageSize=100&pagination=true`);
            const data = await result.json()
            setSearchStories(data?.stories);
        } catch (error) {
            console.error("Error fetching search results:", error);
        }
    }, 2000);

    const handleSearch = () => {
        if (searchKeyWord.trim() === "") {
            setSearchStories([]);
            return;
        }
        delayedSearch(searchKeyWord);
    };


    const updateInDB = async (sourceId, destinationId, filteredBreakingNews) => {
        setShowSpinner(true)
        const sourceStory = filteredBreakingNews?.[sourceId]
        const destinationStory = filteredBreakingNews?.[destinationId]

        try {
            if (destinationId <= sourceId) {
                for (let i = destinationId; i < sourceId; i++) {
                    await UpdateDataApi(`stories/${filteredBreakingNews?.[i]?.id}`, { sort_order: filteredBreakingNews?.[i + 1]?.sort_order });
                }
            }
            else {
                for (let i = destinationId; i > sourceId; i--) {
                    await UpdateDataApi(`stories/${filteredBreakingNews?.[i]?.id}`, { sort_order: filteredBreakingNews?.[i - 1]?.sort_order });
                }
            }
            await UpdateDataApi(`stories/${sourceStory?.id}`, { sort_order: destinationStory?.sort_order });

            setTimeout(() => {
                setShowSpinner(false);
            }, 1000)
        }
        catch (error) {
            console.error(error)
            setTimeout(() => {
                setShowSpinner(false);
            }, 1000)
        }
    }

    const handleDragEnd = (dragResults) => {
        const sourceId = dragResults?.source?.index
        const destinationId = dragResults?.destination?.index

        const copyOfActiveBreakingNews = _.cloneDeep(activeBreakingNews);

        let tempBreakingNews = [...activeBreakingNews]
        let [sourceRow] = tempBreakingNews.splice(sourceId, 1)
        tempBreakingNews.splice(destinationId, 0, sourceRow)

        tempBreakingNews.forEach((story, index) => {
            story.sort_order = copyOfActiveBreakingNews[index].sort_order;
        });
        updateInDB(sourceId, destinationId, copyOfActiveBreakingNews)

        setActiveBreakingNews(tempBreakingNews)
    }

    const [showSpinner, setShowSpinner] = useState(false);

    return (
        <div >
            <div id="spinner" className={`modal ${showSpinner ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showSpinner ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mt-2">Updating the DB...</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offcanvas offcanvas-end" tabindex="-1" id="addBreaking" aria-labelledby="addBreakingLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    <h5 id="offcanvasRightLabel">Add Breaking Story</h5>
                    <button onClick={publishBreakingClick} className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Publish Breaking</button>
                </div>
                <div className="offcanvas-body">
                    <div className="row mb-30 mt-30">
                        <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                            Story Type
                        </label>
                        <div className="col-sm-8">
                            <select
                                name="custom_slug"
                                className="form-control select2"
                                style={{ width: '100%' }}
                                data-placeholder="Select a section"
                                value={template}
                                onChange={(e) => { setTemplate(e.target.value); setAddStoryType(e.target.value) }}
                            >
                                <option > select story type</option>
                                <option value={'Text Story'}> Text Story</option>
                                <option value={'Link Story'}> Link Story</option>
                            </select>
                        </div>
                    </div>

                    {
                        addStoryType === 'Text Story' &&

                        <div className="row mb-30 mt-30">
                            <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                Breaking News
                            </label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    name="custom_slug"
                                    className="form-control"
                                    id="breaking-news-title"
                                />
                            </div>
                        </div>
                    }

                    {
                        addStoryType === 'Link Story' &&

                        <div>
                            <div className="row mb-30 mt-30">
                                <label htmlFor="meta_description" className="col-sm-4 col-form-label text-right">
                                    Link Story
                                </label>
                                <div className="search-bar col-sm-8" >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={linkStory?.title || `Search Stories By Title ...`}
                                        value={linkStory?.title || searchKeyWord}
                                        onChange={(e) => { handleSearchInputChange(e); setLinkStory(null) }}
                                        style={{ 'height': '3em' }}
                                    />
                                    {
                                        !linkStory &&
                                        <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            <ul className="list-group">
                                                {searchStories &&
                                                    searchStories.map((story) => (
                                                        <li
                                                            key={story.id}
                                                            className="list-group-item"
                                                            style={{ backgroundColor: 'transparent' }}
                                                            onMouseOver={(e) => { e.currentTarget.style.backgroundColor = '#ccc'; }}
                                                            onMouseOut={(e) => { e.currentTarget.style.backgroundColor = 'transparent'; }}
                                                        >
                                                            <a
                                                                // href={`/story/create#/story/update/${story.id}`}
                                                                onClick={() => { setLinkStory(story) }}
                                                                style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
                                                            >
                                                                {story.title}
                                                            </a>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>


            <div className="content">
                <div className="content">
                    <div className="card col-md-12 m-auto p-30">
                        <div className="card-header">
                            <h3 className="text-bold">Breaking News</h3>
                            <input
                                type="search"
                                placeholder="Enter title or author"
                                className="form-control story-search-input"
                                onChange={(e) => {
                                    fetchOurStory("", e?.target?.value);
                                }}
                            />

                            <button className="btn btn-primary btn-round btn-sm" type="button" data-bs-toggle="offcanvas" data-bs-target="#addBreaking" aria-controls="offcanvasRight">Add Breaking</button>
                            {/* <button className="btn btn-primary btn-round btn-sm">Filter</button> */}
                        </div>

                        <div className="card-body">
                            <div className={`row mb-15 d-flex justify-content-around`}>
                                <div
                                    className={
                                        isTab === "All"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setActiceTab(false)
                                        setIsTab("All");
                                        // setFilteredBreakingNews(breakingNews)
                                        setBreakingTotalPages(breakingNews.length / 20)

                                        const copyOfActiveBreakingNews2 = _.cloneDeep(breakingNews)

                                        activeBreakingNews.forEach((story) => {
                                            const foundIndex = copyOfActiveBreakingNews2.findIndex((story2) => story2.id === story.id);
                                            if (foundIndex !== -1) {
                                                copyOfActiveBreakingNews2[foundIndex].sort_order = story.sort_order;
                                            }
                                        });

                                        copyOfActiveBreakingNews2.sort((a, b) => {
                                            return (b.sort_order - a.sort_order)
                                        })

                                        setFilteredBreakingNews(copyOfActiveBreakingNews2)
                                    }}
                                >
                                    All
                                </div>
                                <div
                                    className={
                                        isTab === "Open"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setFilteredBreakingNews(null)
                                        setActiceTab(true)
                                        setIsTab("Open");

                                        const twoHoursInMillis = 2 * 60 * 60 * 1000;
                                        const currentTime = new Date().getTime()

                                        let data = null

                                        data = breakingNews.filter(story => {
                                            const publish_date = new Date(story.publish_date).getTime();
                                            const timeDifference = Math.abs(currentTime - publish_date);
                                            return timeDifference <= twoHoursInMillis;
                                        })

                                        if (data && !activeBreakingNews) {

                                            data.sort((a, b) => {
                                                return (b.sort_order - a.sort_order)
                                            })

                                            setActiveBreakingNews(data)

                                            const so = []
                                            data.forEach(story => so.push(story?.sort_order))
                                            console.log({ sort_order: so })
                                        }
                                    }}
                                >
                                    Active
                                </div>
                                <div
                                    className={
                                        isTab === "Submitted"
                                            ? "px-0 col-md-1 text-center active-stepper-form-head text-bold mb-0"
                                            : "px-0 col-md-1 text-center text-bold mb-0"
                                    }
                                    role="button"
                                    onClick={(e) => {
                                        setActiceTab(false)
                                        setIsTab("Submitted");

                                        const twoHoursInMillis = 2 * 60 * 60 * 1000;
                                        const currentTime = new Date().getTime()

                                        const data =
                                            breakingNews.filter(story => {
                                                const publish_date = new Date(story.publish_date).getTime();
                                                const timeDifference = Math.abs(currentTime - publish_date);
                                                return timeDifference > twoHoursInMillis;
                                            })
                                        
                                        data.sort((a, b) => {
                                            return (b.sort_order - a.sort_order)
                                        })

                                        setFilteredBreakingNews(data)
                                        setBreakingTotalPages(data.length / 20)
                                    }}
                                >
                                    InActive
                                </div>
                            </div>


                            <div className="table-responsive" style={{ display: activeTab ? 'none' : 'block' }}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Author</th>
                                            <th>Story Type</th>
                                            <th>Story Status</th>
                                            <th>Created On</th>
                                            <th>Breaking News</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!filteredBreakingNews || loading ? (
                                            <Loader />
                                        ) : (
                                            filteredBreakingNews?.map((story, index) => {
                                                return (
                                                    <tr key={story?._id}>
                                                        <td>{index + 1}</td>
                                                        <td>{story?.title}</td>
                                                        {/* <td>{story.sub_title}</td> */}
                                                        <td className="text-capitalize">
                                                            {story?.authors?.length > 0
                                                                ? ConvertArrayTOString(
                                                                    story?.authors,
                                                                    "collection"
                                                                )
                                                                : "-"}
                                                        </td>

                                                        <td>{story?.story_type}</td>
                                                        <td className="text-capitalize">{story?.status}</td>

                                                        {/* <td>{story.created_by || `-`}</td> */}
                                                        <td>
                                                            {moment(story?.timestamp).format(
                                                                "MMMM DD, YYYY | hh:mm:ss a"
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="icheckbox_flat-green">
                                                                {CheckAccess("story", "update") ? (
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_breaking_news"
                                                                        name="is_breaking_news"
                                                                        className="chk-col-grey"
                                                                        onChange={async (e) => {
                                                                            if (CheckAccess("story", "update")) {
                                                                                await UpdateDataApi(
                                                                                    `${apiEndpoints.storiesById(
                                                                                        story?._id
                                                                                    )}`,
                                                                                    { is_breaking_news: e.target.checked }
                                                                                );
                                                                                fetchOurStory();
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: "You don't have permission.",
                                                                                    text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                    icon: "warning",
                                                                                });
                                                                            }
                                                                        }}
                                                                        checked={story?.is_breaking_news}
                                                                    />
                                                                ) : (
                                                                    <input
                                                                        type="checkbox"
                                                                        id="is_breaking_news"
                                                                        name="is_breaking_news"
                                                                        className="chk-col-grey"
                                                                        onChange={(e) => {
                                                                            e?.preventDefault();
                                                                            Swal.fire({
                                                                                title: "You don't have permission.",
                                                                                text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                icon: "warning",
                                                                            });
                                                                        }}
                                                                        checked={story?.is_breaking_news}
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="d-flex align-items-center">
                                                            {CheckAccess("story", "view") && (
                                                                <div
                                                                    onClick={() =>
                                                                        navigate(`/story/view/${story?._id}`)
                                                                    }
                                                                    className="mr-2"
                                                                    role="button"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </div>
                                                            )}
                                                            {story?.status === "approved" ? (
                                                                <>
                                                                    {CheckAccess("story", "update") &&
                                                                        CheckAccess(
                                                                            "story",
                                                                            "update approved story"
                                                                        ) && (
                                                                            <div
                                                                                onClick={() => {
                                                                                    if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Video Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/video-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }

                                                                                    if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Text Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }

                                                                                    if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Web Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/web-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    if (
                                                                                        _.lowerCase(story?.story_type) ===
                                                                                        _.lowerCase("Photo Story")
                                                                                    ) {
                                                                                        navigate(
                                                                                            `/photo-story/update/${story?._id}`
                                                                                        );
                                                                                        return;
                                                                                    }
                                                                                    setAppData({ story_Id: story?._id });
                                                                                }}
                                                                                className="mr-2"
                                                                                role="button"
                                                                            >
                                                                                <i className="fa fa-edit"></i>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            ) : (
                                                                CheckAccess("story", "update") && (
                                                                    <div
                                                                        onClick={() => {
                                                                            if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Video Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/video-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }

                                                                            if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Text Story")
                                                                            ) {
                                                                                navigate(`/story/update/${story?._id}`);
                                                                                return;
                                                                            }

                                                                            if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Web Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/web-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }
                                                                            if (
                                                                                _.lowerCase(story?.story_type) ===
                                                                                _.lowerCase("Photo Story")
                                                                            ) {
                                                                                navigate(
                                                                                    `/photo-story/update/${story?._id}`
                                                                                );
                                                                                return;
                                                                            }
                                                                            setAppData({ story_Id: story?._id });
                                                                        }}
                                                                        className="mr-2"
                                                                        role="button"
                                                                    >
                                                                        <i className="fa fa-edit"></i>
                                                                    </div>
                                                                )
                                                            )}
                                                            {CheckAccess("story", "delete") && (
                                                                <div
                                                                    onClick={(e) => {
                                                                        e?.preventDefault();
                                                                        handleDelete(story?._id);
                                                                    }}
                                                                    role="button"
                                                                >
                                                                    <i className="fa fa-trash"></i>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        )}
                                    </tbody>
                                </table>
                                {!loading && totalPages && storiesList?.length > 0 ? (
                                    <div className={`container`}>
                                        <div className={`row`}>
                                            <div
                                                className={`col-sm-12 d-flex justify-content-center px-0`}
                                            >
                                                <Pagination
                                                    currentPage={1}
                                                    totalPages={breakingTotalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>



                            <div className="table-responsive" style={{ display: activeTab ? 'block' : 'none' }}>
                                <DragDropContext onDragEnd={(dragResults) => handleDragEnd(dragResults)} >
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title</th>
                                                <th>Author</th>
                                                <th>Story Type</th>
                                                <th>Story Status</th>
                                                <th>Created On</th>
                                                <th>Breaking News</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId={droppableId}>
                                            {
                                                (provided) => (
                                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                        {
                                                            !activeBreakingNews || loading ? (
                                                                <Loader />
                                                            ) :
                                                                (
                                                                    activeBreakingNews?.map((story, index) => {
                                                                        return (
                                                                            <Draggable draggableId={story?.id} index={index} key={story?.id}>
                                                                                {
                                                                                    (provided) => (
                                                                                        <tr key={story?.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>{story?.title}</td>
                                                                                            <td className="text-capitalize">
                                                                                                {story?.authors?.length > 0
                                                                                                    ? ConvertArrayTOString(
                                                                                                        story?.authors,
                                                                                                        "collection"
                                                                                                    )
                                                                                                    : "-"}
                                                                                            </td>

                                                                                            <td>{story?.story_type}</td>
                                                                                            <td className="text-capitalize">{story?.status}</td>

                                                                                            {/* <td>{story.created_by || `-`}</td> */}
                                                                                            <td>
                                                                                                {moment(story?.timestamp).format(
                                                                                                    "MMMM DD, YYYY | hh:mm:ss a"
                                                                                                )}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="icheckbox_flat-green">
                                                                                                    {CheckAccess("story", "update") ? (
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            id="is_breaking_news"
                                                                                                            name="is_breaking_news"
                                                                                                            className="chk-col-grey"
                                                                                                            onChange={async (e) => {
                                                                                                                if (CheckAccess("story", "update")) {
                                                                                                                    await UpdateDataApi(
                                                                                                                        `${apiEndpoints.storiesById(
                                                                                                                            story?._id
                                                                                                                        )}`,
                                                                                                                        { is_breaking_news: e.target.checked }
                                                                                                                    );
                                                                                                                    fetchOurStory();
                                                                                                                } else {
                                                                                                                    Swal.fire({
                                                                                                                        title: "You don't have permission.",
                                                                                                                        text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                                                        icon: "warning",
                                                                                                                    });
                                                                                                                }
                                                                                                            }}
                                                                                                            checked={story?.is_breaking_news}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            id="is_breaking_news"
                                                                                                            name="is_breaking_news"
                                                                                                            className="chk-col-grey"
                                                                                                            onChange={(e) => {
                                                                                                                e?.preventDefault();
                                                                                                                Swal.fire({
                                                                                                                    title: "You don't have permission.",
                                                                                                                    text: "Sorry, but you do not have the necessary permissions to access this resource. Please contact the administrator for assistance.",
                                                                                                                    icon: "warning",
                                                                                                                });
                                                                                                            }}
                                                                                                            checked={story?.is_breaking_news}
                                                                                                        />
                                                                                                    )}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="d-flex align-items-center">
                                                                                                {CheckAccess("story", "view") && (
                                                                                                    <div
                                                                                                        onClick={() =>
                                                                                                            navigate(`/story/view/${story?._id}`)
                                                                                                        }
                                                                                                        className="mr-2"
                                                                                                        role="button"
                                                                                                    >
                                                                                                        <i className="fa fa-eye"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                                {story?.status === "approved" ? (
                                                                                                    <>
                                                                                                        {CheckAccess("story", "update") &&
                                                                                                            CheckAccess(
                                                                                                                "story",
                                                                                                                "update approved story"
                                                                                                            ) && (
                                                                                                                <div
                                                                                                                    onClick={() => {
                                                                                                                        if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Video Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/video-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }

                                                                                                                        if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Text Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }

                                                                                                                        if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Web Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/web-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            _.lowerCase(story?.story_type) ===
                                                                                                                            _.lowerCase("Photo Story")
                                                                                                                        ) {
                                                                                                                            navigate(
                                                                                                                                `/photo-story/update/${story?._id}`
                                                                                                                            );
                                                                                                                            return;
                                                                                                                        }
                                                                                                                        setAppData({ story_Id: story?._id });
                                                                                                                    }}
                                                                                                                    className="mr-2"
                                                                                                                    role="button"
                                                                                                                >
                                                                                                                    <i className="fa fa-edit"></i>
                                                                                                                </div>
                                                                                                            )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    CheckAccess("story", "update") && (
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Video Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/video-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }

                                                                                                                if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Text Story")
                                                                                                                ) {
                                                                                                                    navigate(`/story/update/${story?._id}`);
                                                                                                                    return;
                                                                                                                }

                                                                                                                if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Web Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/web-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }
                                                                                                                if (
                                                                                                                    _.lowerCase(story?.story_type) ===
                                                                                                                    _.lowerCase("Photo Story")
                                                                                                                ) {
                                                                                                                    navigate(
                                                                                                                        `/photo-story/update/${story?._id}`
                                                                                                                    );
                                                                                                                    return;
                                                                                                                }
                                                                                                                setAppData({ story_Id: story?._id });
                                                                                                            }}
                                                                                                            className="mr-2"
                                                                                                            role="button"
                                                                                                        >
                                                                                                            <i className="fa fa-edit"></i>
                                                                                                        </div>
                                                                                                    )
                                                                                                )}
                                                                                                {CheckAccess("story", "delete") && (
                                                                                                    <div
                                                                                                        onClick={(e) => {
                                                                                                            e?.preventDefault();
                                                                                                            handleDelete(story?._id);
                                                                                                        }}
                                                                                                        role="button"
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </div>
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </Draggable>
                                                                        );
                                                                    })
                                                                )
                                                        }
                                                        {provided.placeholder}
                                                    </tbody>
                                                )
                                            }
                                        </Droppable>
                                    </table>
                                </DragDropContext>


                                {!loading && totalPages && storiesList?.length > 0 ? (
                                    <div className={`container`}>
                                        <div className={`row`}>
                                            <div
                                                className={`col-sm-12 d-flex justify-content-center px-0`}
                                            >
                                                <Pagination
                                                    currentPage={1}
                                                    totalPages={breakingTotalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BreakingNews;
