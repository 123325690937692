
import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const ManagePermission = () => {
  const history = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [filters, setFilters] = useState({
    permissionGroup: '',
    name: '',
    controllerName: '',
    actionName: '',
    status: '',
  });

  useEffect(() => {
    // Uncomment and modify the API call accordingly
    // axios.get('/api/permissions', { params: filters })
    //   .then(response => {
    //     setPermissions(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching permissions:', error);
    //   });
    // For demo purposes, using static data
    const staticData = [
      {
        id: "1",
        permissionGroup: 'Group 1',
        name: 'Permission 1',
        controllerName: 'Controller 1',
        actionName: 'Action 1',
        status: 'Active',
      },
      {
        id: "2",
        permissionGroup: 'Group 2',
        name: 'Permission 2',
        controllerName: 'Controller 2',
        actionName: 'Action 2',
        status: 'Active',
      },
      // Add more static data as needed
    ];
    setPermissions(staticData);
  }, [filters]);

  const handleDelete = (permissionId) => {
    // Uncomment and modify the API call accordingly
    // axios.delete(`/api/permissions/${permissionId}`)
    //   .then(response => {
    //     setPermissions(prevPermissions => prevPermissions.filter(p => p.id !== permissionId));
    //   })
    //   .catch(error => {
    //     console.error('Error deleting permission:', error);
    //   });
    // For demo purposes, filtering permissions locally
    setPermissions((prevPermissions) => prevPermissions.filter((p) => p.id !== permissionId));
  };

  const renderPermissions = () => {
    return permissions.map((permission) => (
      <tr key={permission.id}>
        <td>{permission.permissionGroup}</td>
        <td>{permission.name}</td>
        <td>{permission.controllerName}</td>
        <td>{permission.actionName}</td>
        <td>{permission.status}</td>
        <td className="action-cell">
          <div className="d-flex">
            <Link to={`/user-management/permissions/EditPermissionForm`}>
              <Button className="mr-2" variant="info">
                Edit
              </Button>
            </Link>
            <Button onClick={() => handleDelete(permission.id)} variant="danger">
              Delete
            </Button>
          </div>
        </td>
      </tr>
    ));
  };
  
  

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div style={{ margin: '5rem' }}>
      <div>
       
        {/* Add similar input fields for other filters */}
      </div>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Permission Group</th>
            <th>Name</th>
            <th>Controller</th>
            <th>Action</th>
            <th>Status</th>
            <th className="action-header">Action</th>
          </tr>
        </thead>
        <tbody>
          {renderPermissions()}
        </tbody>
      </Table>
      
      
    </div>
  )
};

export default ManagePermission;