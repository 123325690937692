import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useRolesHook from "./rolesHook";
import { Badge } from "react-bootstrap";
import { GetListApi } from "../../../common/api/api";
import config from "../../../../config";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import Pagination from "../../../common/pagination";
import { CheckAccess } from "../../../common/utils/roleAccess";

const ListRoles = () => {
  const {
    rolesList,
    setRolesList,
    handleDelete,
    id,
    currentPage,
    setCurrentPage,
    totalPages,
    fetchRolesList
  } = useRolesHook();
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Roles</h3>
             {CheckAccess("roles", "create") &&  <div className="timeline-footer text-right">
                <Link
                  to="/user-management/roles/create"
                  className="btn btn-primary btn-round btn-sm"
                >
                  Add Role
                </Link>
              </div>}
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr style={{ height:"50px"}}>       
                   <th scope="col"></th>                   
                       <th scope="col">
                        <input type="text"  className="form-control" 
                        onChange={(e)=>{fetchRolesList(e.target.value,"")}}
                         data-table="your-table" placeholder="Item to search.." />
                         </th>
                       <th scope="col">
                       <select name="status" className="form-control" onChange={(e)=>{fetchRolesList("",e.target.value)}}>
                      <option value={""}>All</option>
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                                          </th>
                       {/* <th scope="col"> 
                        <input type="text" className="form-control"
                          // onChange={(e)=>{fetchRolesList("","",e.target.value,"")}}
                        data-table="your-table" placeholder="Item to search.." />
                                          </th> */}
                     
                     
                </tr>
                    {(rolesList?.length > 0) ? (
                      rolesList.map((request, index) => (
                        <tr key={request._id}>
                          <td>{index + 1}</td>
                          <td>{request.name}</td>
                          {/* <td>{request.status}</td> */}
                          <td>
                          <Badge
                                bg={
                                  request.status === "Active"
                                    ? "success"
                                    : "danger"
                                }
                                text="white"
                              >
                                {request.status}
                              </Badge>
                          </td>
                          <td>
                            {CheckAccess("roles", "update") &&  <Link
                              to={`/user-management/roles/update/${request._id}`}
                              className="mr-2"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>}
                           {CheckAccess("roles", "delete") &&   <Link onClick={() => handleDelete(request._id)}>
                              <i className="fa fa-trash"></i>
                            </Link>}
                          </td>
                        </tr>
                      ))
                    ) : loading && (
                      <tr>
                        <td>
                          <div id="loader">
                            <div className="d-flex flex-column align-items-center">
                              <div className="spinner-grow text-gold"></div>
                              <span className="text-gold mt-1">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                      // : 'Roles not found...'
                    }
                  </tbody>
                </table>
                {totalPages ? (
                  <div className={`container`}>
                    <div className={`row`}>
                      <div
                        className={`col-sm-12 d-flex justify-content-center px-0`}
                      >
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListRoles;
