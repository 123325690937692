// import { Button } from 'bootstrap'
import { Button } from 'react-bootstrap';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useCollectionHook from './collectionHook';

function CollectionRead() {
    const pageTitle= "Collection Details"

    const {
        collectionList, 
        setCollectionList,
        handleDelete ,
        listData,
        setListData,
        setIsOpenCanvas,
        setSectionItem,
        setValue, 
collectionReads,
        setCollectionReads,
        FetchCollectionList   
      } = useCollectionHook();  

  return (
    <>
      <div className="content">
        <div className="content">
        <div className="card col-md-6 m-auto p-30">
        <div className='text-left'>
        <div className="timeline-footer text-right">

<Link
    className="btn btn-primary btn-round btn-sm"
    data-bs-toggle="offcanvas"
    // href="/collection/create"
    role="button"
    aria-controls="offcanvasExample"
    // onClick={() => {
    //   setIsOpenCanvas(true);
    //   setSectionItem({ status: "Active" });
    //   setValue("status", "Active");
    // }}  
  >
    Add Collection

  </Link>

  &nbsp;
  &nbsp;
    
  <Button
    //   onClick={() => {
    //     setSectionItem(sectionView);
    //     setIsOpenCanvas(true);
    //   }}
      data-bs-toggle="offcanvas"
      href="#offcanvasExample"
      role="button"
      aria-controls="offcanvasExample"
      className="btn btn-primary btn-round btn-sm">
   Update Collection
  </Button>

  &nbsp;
  &nbsp;

  <Link to ="/Collection"
    className="btn btn-primary btn-round btn-sm " >
   Manage Collection
  </Link>

  &nbsp;
  &nbsp;

  <button 
    // onClick={() => handleDelete(sectionView._id)}
    className="btn btn-danger btn-round btn-sm " >
   Delete 
  </button>   
</div>
              </div>
            <div className="card-header">
            
              <h3 className="text-bold">{pageTitle}</h3>
              
            </div>
            <div className="card-body" >
           
        
         <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right ">
                  <strong> Title :</strong>
               
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.display_name} */}
              </div>
        </div>
         <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right ">
                   <strong>Tags :</strong>
               
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.section_name} */}
              </div>
        </div>
        <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right">
                   <strong>  Author :</strong>
              
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.description} */}
              </div>
        </div>
         <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right">
                <strong>Collection :</strong>
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.page_title} */}
              </div>
        </div>
        
         <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right">
                   <strong>Created On  :</strong>
                
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.meta_title} */}
              </div>
        </div>
        
         <div className="row mb-15">
              <label
                htmlFor="page_title"
                className="col-sm-4 col-form-label text-right">
                   <strong>  Story Template :</strong>
            
              </label>
              <div className="col-lg-8 form-control text-left no-border">
              {/* {sectionView.keywords} */}
              </div>
        </div>

{/* offcanvas for update */}

          

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollectionRead