import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import DatePicker from "react-datepicker"; // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import { ToastContainer, toast } from "react-toastify";  
import Form from 'react-bootstrap/Form';
import "bootstrap/dist/css/bootstrap.css";
import Select from "react-select";

function CreateAttribute() {
  const pageTitle = "Create Attribute";
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, } = useForm();
  const [selectedDate, setSelectedDate] = useState(null); // State for the selected date
  const [selectedOption, setSelectedOption] = useState(""); // first drop down
  const [selectedOptions, setSelectedOptions] = useState(""); // second drop down
  const userData = sessionStorage.getItem('user');
  const user = userData ? JSON.parse(userData) : null;
// first drop down 
  const options = [
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "orange", label: "Orange" },
    { value: "purple", label: "Purple" },
  ];

  var handleChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedOption(selectedOption.value);
  };

  // second dropdown
  const optionss = [
    { value: "blue", label: "Blue" },
    { value: "green", label: "Green" },
    { value: "orange", label: "Orange" },
    { value: "purple", label: "Purple" },
  ];

  var handleChanges = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedOptions(selectedOptions.value);
  };

  const onSubmit = (data) => {
    data.project_date = selectedDate;
 
    data.created_by = user.name;
    data.status = "active";

    
    axios.post(`${config.apiUrl}/`, data)
      .then(response => {
        // console.log('Work added successfully:', response.data);
        toast.success("Work added successfully");
        navigate('/our-works');
        // Clear the form or show a success message as needed
      })
      .catch(error => {
        console.error('Error adding work:', error);
        // Handle error, show error message, etc.
      });
  };



  return (
<>
    {/* <div className="content"> */}
      {/* <div className="content"> */}
        <div className=" col-md-12 m-auto">
          <div className="card-header">
            <h3 className="text-bold">{pageTitle}</h3>
           
          </div>
          <p></p>
          <div className="p-3">
          <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-15">
                  <label htmlFor="type" className="col-sm-4 col-form-label text-right"> Name</label>
                  <div className="col-lg-8">
                    <input type="text" name="name" className="form-control" {...register('name', { required: true })} />
                    {errors.name && <span className="text-danger">  Name is required.</span>}
                  </div>
                </div>
{/* type of check button first */}
                <div className="row mb-15">
                                  <label
                                    htmlFor="type"
                                    className="col-sm-4 col-form-label text-right"
                                  >
                                    {" "}
                                    Type
                                  </label>
                                  <div className="col-lg-8 ">
                                    <div className='row'>
                                      <div className='col-lg-4 '> 
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="header-switch"
                                      label="Card "
                                      name="header-switch"
                                      className="form-control  no-border"
                                    
                                    />
                                    </div>
                                      <div className='col-lg-4'>
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="footer-switch"
                                      label="Story "
                                      name="footer-switch"
                                      className="form-control  no-border"
                                    />
                                      </div>

                                      <div className='col-lg-4'>
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="footer-switch"
                                      label="Collection "
                                      name="footer-switch"
                                      className="form-control  no-border"
                                    />
                                      </div>
                                    </div>
                                      

                                  </div>
                                </div>

                                {/* dropdown first 1*/}
                                <div className="row mb-15">
                                  <label
                                    htmlFor="type"
                                    className="col-sm-4 col-form-label text-right"
                                  >
                                    {" "}
                                     Data Type
                                  </label>
                                  <div className="col-lg-8 ">
                                  <Select isMulti onChange={handleChange} options={options} />
                                  </div>
                                </div>
                                
                                

                                {/* type of check button 2 */}
                                <div className="row mb-15">
                                  <label
                                    htmlFor="type"
                                    className="col-sm-4 col-form-label text-right"
                                  >
                                    {" "}
                                    Entry Selection
                                  </label>
                                  <div className="col-lg-8 ">
                                    <div className='row'>
                                      <div className='col-lg-4'> 
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="header-switch"
                                      label="Card "
                                      name="header-switch"
                                      className="form-control  no-border"
                                    
                                    />
                                    </div>
                                      <div className='col-lg-4'>
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="footer-switch"
                                      label="Story "
                                      name="footer-switch"
                                      className="form-control  no-border"
                                    />
                                      </div>

                                      <div className='col-lg-4'>
                                      <Form.Check // prettier-ignore
                                      type="switch"
                                      id="footer-switch"
                                      label="Collection "
                                      name="footer-switch"
                                      className="form-control  no-border"
                                    />
                                      </div>
                                    </div>
                                      

                                  </div>
                                </div>

                                {/* dropdown first 2*/}
                                <div className="row mb-15">
                                  <label
                                    htmlFor="type"
                                    className="col-sm-4 col-form-label text-right"
                                  >
                                    {" "}
                                     Entry Type
                                  </label>
                                  <div className="col-lg-8 ">
                                  <Select isMulti onChange={handleChanges} options={optionss} />
                                  </div>
                                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-right">
                  <button type="submit" className="btn btn-primary btn-round mr-5" >Submit</button>
                   
                   <Link to="/section/manage" className="btn btn-primary btn-round" data-bs-dismiss="offcanvas" type="reset">Cancel</Link>
                  </div>
                </div>


              </form>
          </div>
        </div>
      {/* </div> */}
    {/* </div> */}
  </>
  )
}

export default CreateAttribute;