import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import config from "../../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const UpdateSocialLinks = () => {
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
  const [socialSetting, setSocialSetting] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchSocial();
  }, [])

  const fetchSocial = () => {
    axios.get(`${config.apiUrl}/settings/${id}`)
      .then(response => {
        setSocialSetting(response.data);
        setValue("icon", response.data.icon);
        setValue("name", response.data.name);
        setValue("value", response.data.value);
      })
      .catch(error => {
        console.log("Error occured in feteching social settings: ", error);
      });
  }

  const onSubmit = (data) => {
    axios.put(`${config.apiUrl}/settings/${socialSetting._id}`, data)
      .then(response => {
        // console.log('Work added successfully:', response.data);
        toast.success("Social Setting udpated successfully");
        navigate('/settings/social-links');
        // Clear the form or show a success message as needed
      })
      .catch(error => {
        console.error('Error adding setting:', error);
        // Handle error, show error message, etc.
      });
  };

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-5 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Social Links</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <form method="post" onSubmit={handleSubmit(onSubmit)}>

                  <div className="row mb-15">
                    <label htmlFor="name" className="col-sm-3 col-form-label text-right">Name</label>
                    <div className="col-sm-7">
                      <input type="text" className="form-control" name="name" placeholder="Name" {...register('name', { required: true })} />
                      {errors.name && <span className="text-danger">Name is required.</span>}
                    </div>
                  </div>

                  <div className="row mb-15">
                    <label htmlFor="value" className="col-sm-3 col-form-label text-right">Value</label>
                    <div className="col-sm-7">
                      <input type="text" className="form-control" name="value" placeholder="Value" {...register('value', { required: true })} />
                      {errors.value && <span className="text-danger">Value is required.</span>}
                    </div>
                  </div>

                  <div className="row mb-15">
                    <label htmlFor="icon" className="col-sm-3 col-form-label text-right">Icon</label>
                    <div className="col-sm-7">
                      <input type="text" className="form-control" name="icon" placeholder="Icon" {...register('icon', { required: true })} />
                      {errors.icon && <span className="text-danger"> Icon is required.</span>}
                    </div>
                  </div>

                  <div className="row mb-15">
                    <div className="col-sm-12 text-center">
                      <button className="btn btn-primary btn-round mr-5" type="submit">Update</button>
                      <Link to="/settings/social-links" className="btn btn-danger btn-round" type="submit">Cancel</Link>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateSocialLinks;