import React, { useState } from "react";
import { Link } from "react-router-dom";
import useCollectionHook from "./collectionHook";
import Loader from "../../common/utils/loader";
import config from "../../../config";
import moment from "moment/moment";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import { CheckAccess } from "../../common/utils/roleAccess";
const ListCollections = () => {
  const {
    collectionList,
    handleDelete,
  } = useCollectionHook();
 
  const [collType, setCollType] = useState("all");
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Collections</h3>
              {CheckAccess("collections", "create") && <div className="timeline-footer text-right">
                <Link
                  to="/collection/create"
                  className="btn btn-primary btn-round btn-sm"
                >
                  New Collections
                </Link>
              </div>}
            </div>
            <div className="Row border-bottom">
              <div className={`col-sm-4`}>
                <div className={`d-flex justify-content-between`}>
                  <h3
                    className={
                      collType === "pinned"
                        ? `text-bold stepper-form-head active-stepper-form-head`
                        : `text-bold stepper-form-head`
                    }
                    role="button"
                    onClick={() => setCollType("pinned")}
                  >
                    Pinned
                  </h3>
                  <h3
                    className={
                      collType === "all"
                        ? `text-bold stepper-form-head active-stepper-form-head`
                        : `text-bold stepper-form-head `
                    }
                    role="button"
                    onClick={() => setCollType("all")}
                  >
                    All
                  </h3>
                  <h3
                    className={
                      collType === "sheduled"
                        ? `text-bold stepper-form-head active-stepper-form-head`
                        : `text-bold stepper-form-head `
                    }
                    role="button"
                    // onClick={()=>setCollType("scheduled")}
                  >
                    Scheduled
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Collection</th>
                      <th>Tags</th>
                      <th>Title</th>
                      {/* <th>Template</th> */}
                      <th>Story Template</th>
                      <th>Author</th>
                      <th>Created On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!collectionList?.length > 0 ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      collectionList?.map((request, index) => (
                        <tr
                          key={index}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <div className={`d-flex align-items-center`}>
                              <div>
                                <img
                                  src={`${config?.apiUrl}/${request?.images?.file_path}`}
                                  alt=""
                                  width={40}
                                  height={40}
                                  className="rounded-circle m-2"
                                />
                              </div>
                              <div>
                                <div className={`fw-normal`}>
                                  {request?.title}
                                </div>
                                <div className={`fs-6`}>{request?.summary}</div>
                              </div>
                            </div>
                          </td>
                          <td>
                            {request?.rule?.tags?.length > 0
                              ? ConvertArrayTOString(
                                  request?.rule?.tags,
                                  "collection"
                                )
                              : `-`}
                          </td>
                          <td>{request?.title || `-`}</td>
                          {/* <td>{request?.template || `-`}</td> */}
                          <td>{request?.rule?.story_template || `-`}</td>
                          <td>{request?.rule?.author?.name || `-`}</td>
                          <td>
                            {moment(request?.created_on).format(
                              "MM/DD/YYYY | hh:mm:s"
                            )}
                          </td>
                          <td>
                            {/*{CheckAccess("collections", "view") &&  <Link to={`/our-works/${request.slug}`} className="mr-2"><i className="fa fa-star"></i></Link> }*/}
                            {CheckAccess("collections", "update") && <Link
                              to={`/collection/update/${request._id}`}
                              className="mr-2"
                            >
                              <i className="fa fa-edit"></i>
                            </Link>}
                           {CheckAccess("collections", "delete") &&  <Link
                              onClick={() => handleDelete(request._id)}
                              className="mr-2"
                            >
                              <i className="fa fa-trash"></i>
                            </Link>}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListCollections;
