import React from "react";
import { Link } from "react-router-dom";
import useOpinionPollHook from "./hook";
import config from "../../../config";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { useEffect } from "react";

const CreateOpinionPoll = () => {
  const {
    register,
    handleSubmit,
    errors,
    optionArr,
    setOptionArr,
    handleAddOptionFields,
    handleRemoveOptionFields,
    onSubmit,
    onUpdate,
    HandleUploadImage,
    handleOptionChange,
    id,
    imgUrl,
    setImgUrl,
  } = useOpinionPollHook();
  const [files, setFiles] = useState([]);
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    // width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setImgUrl("");
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      HandleUploadImage(acceptedFiles);
    },
  });

  const thumbs = imgUrl ? (
    <div style={thumb}>
      <div style={thumbInner}>
        <img src={imgUrl} style={img} />
      </div>
    </div>
  ) : (
    files?.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file?.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file?.preview);
            }}
          />
        </div>
      </div>
    ))
  );

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, []);

  return (
    <>
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">
              {id ? `Update` : `Create`} Opinion Polls
            </h3>
          </div>
          <div className="card-body">
            <form
              onSubmit={id ? handleSubmit(onUpdate) : handleSubmit(onSubmit)}
            >
              <div className="row mb-15">
                <label
                  htmlFor="voting"
                  className="col-sm-4 col-form-label text-right"
                >
                  Topic
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Topic"
                    name="topic"
                    {...register("topic", { required: true })}
                  />
                  {errors.topic && (
                    <span className="text-danger">Topic is required.</span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="description"
                  className="col-sm-4 col-form-label text-right"
                >
                  Description
                </label>
                <div className="col-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    name="description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span className="text-danger">
                      Description is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="heroImage"
                  className="col-sm-4 col-form-label text-right"
                >
                  Hero Image
                </label>
                <div className="col-sm-3">
                  <div
                    {...getRootProps({
                      className: "dropzone border p-3 text-center",
                    })}
                  >
                    <input {...getInputProps()} />
                    <i className="fa fa-cloud-upload"></i>
                    <p>Upload Image</p>
                    {/* <p>Drag 'n' drop some files here, or click to select files</p> */}
                  </div>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                </div>
                {/* <div className="col-sm-3">
                  <input
                    type="file"
                    className="form-control"
                    placeholder="Hero Image"
                    name="heroImage"
                    {...register("heroImage", { required: true })}
                    // onChange={(e) => HandleUploadImage(e.target.files)}
                  />
                  {errors.heroImage && (
                    <span className="text-danger">Hero Image is required.</span>
                  )}
                </div> */}
              </div>

              {optionArr?.length > 0 &&
                optionArr?.map((option, key) => {
                  return (
                    <div className="row mb-15" key={key}>
                      <label
                        htmlFor="option"
                        className="col-sm-4 col-form-label text-right"
                      >
                        {`option ${key + 1}`}
                      </label>
                      <div className="col-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`option ${key + 1}`}
                          name={`option ${key + 1}`}
                          value={option?.value}
                          {...register(`option ${key + 1}`, { required: true })}
                          onChange={(e) => handleOptionChange(e, key)}
                        />
                      </div>
                      <div
                        className="col-md-4 ps-0"
                        onClick={(e) => {
                          handleRemoveOptionFields(key);
                        }}
                      >
                        <a href="#">
                          <i className="fa fa-trash-o"></i>
                        </a>
                      </div>
                    </div>
                  );
                })}

              {optionArr?.length <= 3 && (
                <div className={`row justify-content-center`}>
                  <div
                    className="col-md-4"
                    onClick={(e) => {
                      handleAddOptionFields();
                    }}
                  >
                    <a href="#">
                      <i className="fa fa-plus"></i> Add Option
                    </a>
                  </div>
                </div>
              )}
              <div className="row mb-15">
                <div className="col-md-3">
                  <h4 className="text-right text-bold">Settings</h4>
                </div>
              </div>
              <div className="row mb-15">
                <label
                  htmlFor="voting"
                  className="col-sm-4 col-form-label text-right"
                >
                  Voting
                </label>
                <div className="col-sm-3">
                  <select
                    name="voting"
                    {...register("voting", { required: true })}
                    className="form-control"
                  >
                    <option value={"Login"}>Login</option>
                    <option value={"Anonymous"}>Anonymous</option>
                  </select>
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="viewResults"
                  className="col-sm-4 col-form-label text-right"
                >
                  View Results
                </label>
                <div className="col-sm-3">
                  <select
                    name="view_result"
                    {...register("view_result", { required: true })}
                    className="form-control"
                  >
                    {/* <option value={"Login & Vote"}>Login & Vote</option>
                    <option value={"Always"}>Always</option> */}
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                  {errors.view_result && (
                    <span className="text-danger">
                      view result is required.
                    </span>
                  )}
                </div>
              </div>

              <div className="row mb-15">
                <label
                  htmlFor="allow_change_vote"
                  className="col-sm-4 col-form-label text-right"
                ></label>
                <div className="col-sm-3">
                  <label htmlFor="changeVote">
                    <input
                      type="checkbox"
                      name="allow_change_vote"
                      id="switch-0-0"
                      className="filled-in chk-col-yellow"
                      {...register("allow_change_vote", { required: true })}
                    />
                    <span className="ms-2">Allow to Change Vote</span>
                  </label>
                  <div>
                    {errors.allow_change_vote && (
                      <span className="text-danger">Voting is required.</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-15">
                <div className="col-sm-12 text-center">
                  <button
                    className="btn btn-primary btn-round mr-5"
                    type="submit"
                  >
                    {id ? `Update` : `Submit`}
                  </button>
                  <Link
                    to="/opinion-polls"
                    className="btn btn-primary btn-round"
                    type="reset"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOpinionPoll;
