import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Menu from './Menu';
const Main = () => {
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  const getBreadcrumb = () => {
    const paths = location.pathname.split('/').filter((path) => path !== '');
    let breadcrumbItems = [];

    breadcrumbItems.push(
      <li key="home" className="breadcrumb-item">
        <Link to="/dashboard">Home</Link>
      </li>
    );

    for (let i = 0; i < paths.length; i++) {
      const path = `/${paths.slice(0, i + 1).join('/')}`;
      const name = paths[i].charAt(0).toUpperCase() + paths[i].slice(1);
      breadcrumbItems.push(
        <li key={path} className="breadcrumb-item">
          <Link to={path}>{name}</Link>
        </li>
      );
    }

    return breadcrumbItems;
  };

  return (
    <>
      <div className="wrapper main-height">

        {/* Including Header */}
        <Header />
        {/* <!-- Left side menu --> */}
        <Menu />
        {/* <!-- Content Wrapper. Contains page content --> */}
        <div className="content-wrapper">
          {/* <!-- Content Header (Page header) --> */}
          <section className="content-header">
            <h1>
              &nbsp;
              {/* Dashboard
              <small>Control panel</small> */}
            </h1>
            <ol className="breadcrumb">
              {getBreadcrumb()}
            </ol>
          </section>

          {/* <!-- Main content --> */}
          <Outlet />
          {/* <!-- /.content --> */}
        </div>
        {/* <!-- /.content-wrapper --> */}

        <footer className="main-footer">
          <div className="pull-right d-none d-sm-inline-block">
            <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
              <li className="nav-item">
                <a className="nav-link" href="#">Help</a>
              </li>
              <li className="nav-item">
                Powered By: <a className="nav-link" href="https://jithvar.com" target='_blank'>Jithvar Consultancy Services</a>
              </li>
            </ul>
          </div>
          &copy; {currentYear} <a href="https://www.viralon.on/" target='_blank'>Raftaar</a>. All Rights Reserved.
        </footer>
      </div>
    </>
  );
}

export default Main;