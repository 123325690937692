import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Offcanvas } from "react-bootstrap";
import {
  CreateDataApi,
  GetListApi,
  UpdateDataApi,
} from "../../../common/api/api";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import moment from "moment";
import Swal from "sweetalert2";
import DateTimeInput from "../../../common/date-time";
import { MyDropzone } from "../../../common/dropBox";
import { selectOption } from "../../../common/common-data/alphabets";

const animatedComponents = makeAnimated();
const NotificationForm = (props) => {
  const { handleCloseCanvas, openCanvas, FetchNotificationList } = props;
  const {
    formState: { errors },
    setValue,
    handleSubmit,
    register,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [sendNow, setIsSendNow] = useState(null);
  const [sendLater, setSendLater] = useState(null);
  const [storyType, setStoryType] = useState("message");

  const userData = localStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const onSend = async () => {
    setValue("send_now", sendNow);
    setValue(
      "date_sent",
      sendLater ? `${startDate}` : moment().format("MMMM, DD YYYY | hh:mm:ss a")
    );
    setValue("status", sendLater ? "scheduled" : "send");
    setValue("created_by", user?.name);

    // return;
    if (sendNow || sendLater) {
      if (sendLater && !startDate) {
        Swal.fire({
          title: "Please select date or time!",
          icon: "warning",
        });
        return;
      }

      const response = await CreateDataApi(
        `${apiEndpoints.notification}`,
        getValues()
      );

      if (response) {
        FetchNotificationList();
        setValue("message_body", "");
        setValue("image", "");
        setValue("message_link", "");
        setValue("link_story", "");
        setSelectedStoryList([]);
        navigate("/settings/push-notification/manage");
        handleCloseCanvas();
      }
    } else {
      Swal.fire({
        title: "Please select send now or send later!",
        icon: "warning",
      });
    }
  };
  const handlChange = (e) => {
    setStoryType(e?.target?.value);
  };
  const [storyList, setStoryList] = useState(selectOption);
  const [selectedStoryList, setSelectedStoryList] = useState([]);
  const fetchOurStory = async () => {
    const response = await GetListApi(`${apiEndpoints.stories}`, {
      pagination: false,
    });
    if (response?.stories) {
      let arr = [];
      if (response.stories.length > 0) {
        response.stories.forEach((element) => {
          arr?.push({
            label: element?.title,
            value: element?._id,
            slug: element?.slug,
            slug_preview: element?.slug_preview,
            hero_image: element?.hero_image?.file_path,
          });
          setStoryList(arr);
        });
      }
    }
  };
  useEffect(() => {
    fetchOurStory();
  }, []);
  const HandleSelectChange = (type, e) => {
    if (type === "link_story") {
      setSelectedStoryList(e);
      setValue("link_story", e);
    }
  };
  return (
    <div>
      <Offcanvas show={openCanvas} onHide={handleCloseCanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <button
              type="submit"
              className={`btn btn-primary`}
              onClick={(e) => {
                e?.preventDefault();
                onSend();
              }}
            >
              Send
            </button>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <form>
          <div className={`publish`}>
            <h4 className="fw-bold">Send Notification</h4>
            <form>
              <div className="row mb-15">
                <label htmlFor="type" className="col-sm-4 col-form-label">
                  Notification Type
                </label>
                <div className="col-sm-8">
                  <select
                    name="notification_type"
                    {...register("notification_type")}
                    className="form-control"
                    onChange={handlChange}
                    // defaultValue={"Active"}
                  >
                    <option value={"message"}>Message</option>
                    <option value={"story"}>Story</option>
                  </select>
                </div>
              </div>

              {storyType === "message" && (
                <>
                  <div className="row mb-15">
                    <label
                      htmlFor="message_body"
                      className="col-sm-4 col-form-label"
                    >
                      Message Body
                    </label>
                    <div className="col-sm-8">
                      <Form.Control
                        as="textarea"
                        name="message_body"
                        maxLength={200}
                        {...register("message_body", {
                          required: false,
                          maxLength: 200,
                        })}
                        className="border"
                      />
                    </div>
                  </div>
                  <div className="row mb-15">
                    <label htmlFor="type" className="col-sm-4 col-form-label">
                      Image
                    </label>
                    <div className="col-sm-8 col-form-label">
                      <MyDropzone
                        setValue={setValue}
                        register={register}
                        name={`image`}
                      />
                    </div>
                  </div>
                  <div className="row mb-15">
                    <label
                      htmlFor="message_link"
                      className="col-sm-4 col-form-label"
                    >
                      Message Link
                    </label>
                    <div className="col-sm-8">
                      <Form.Control
                        name="message_link"
                        maxLength={200}
                        {...register("message_link", {
                          required: false,
                          maxLength: 200,
                        })}
                        className="border"
                      />
                    </div>
                  </div>
                </>
              )}
              {storyType === "story" && (
                <>
                  <div className="row">
                    <div className="width-100">
                      <Form.Label>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="Layer_1"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="currentColor"
                        >
                          <defs></defs>
                          <path
                            d="M612.41 358.73h-13a2.37 2.37 0 0 1-2.37-2.37v-13.05a2.37 2.37 0 0 1 2.37-2.36h13a2.37 2.37 0 0 1 2.37 2.36v13.05a2.37 2.37 0 0 1-2.37 2.37zm-13-16.78a1.36 1.36 0 0 0-1.36 1.36v13.05a1.36 1.36 0 0 0 1.36 1.36h13a1.36 1.36 0 0 0 1.36-1.36v-13.05a1.36 1.36 0 0 0-1.36-1.36z"
                            transform="translate(-597 -338)"
                          ></path>
                          <path d="M3.34 6.83h11.1v1.21H3.34zM3.34 10.89h11.1v1.21H3.34zM3.34 14.77h6.64v1.21H3.34z"></path>
                          <path
                            d="M615.35 338H602.3a2.37 2.37 0 0 0-2.23 1.6h1.14a1.33 1.33 0 0 1 1.09-.59h13.05a1.36 1.36 0 0 1 1.36 1.36v13a1.35 1.35 0 0 1-.61 1.11v1.13a2.37 2.37 0 0 0 1.61-2.24v-13a2.37 2.37 0 0 0-2.36-2.37"
                            transform="translate(-597 -338)"
                          ></path>
                        </svg>
                        Link A Story
                      </Form.Label>
                      <Select
                        className={`outline-none my-5`}
                        name="link_story"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={storyList}
                        // {...register(`link_story`)}
                        onChange={(e) => {
                          HandleSelectChange("link_story", e);
                        }}
                        // value={selectedStoryList}
                        // value={{
                        //   label:
                        //     cards[cardIndex]?.sub_card[subCardIndex]?.title,
                        //   value:
                        //     cards[cardIndex]?.sub_card[subCardIndex]
                        //       ?.link_story,
                        // }}
                      ></Select>
                    </div>
                  </div>
                </>
              )}

              <div className="row mb-5">
                <Form.Check
                  inline
                  className={`switchInput col-sm-4`}
                  id={`inline-1`}
                  label="Send Now"
                  name="send_now"
                  type={`radio`}
                  onChange={(e) => {
                    setIsSendNow(e?.target?.checked);
                    setSendLater(false);
                  }}
                  checked={sendNow}
                />

                <Form.Check
                  inline
                  className={`switchInput col-sm-4`}
                  label="Send Later"
                  name="send_now"
                  type={`radio`}
                  id={`inline-2`}
                  onChange={(e) => {
                    setIsSendNow(false);
                    setSendLater(e?.target?.checked);
                    setValue("publish_date", "");
                  }}
                  checked={sendLater}
                />
              </div>
            </form>

            {sendLater && (
              <div className="mt-5">
                <h4 className="fw-bold">Schedule a time to Send:</h4>

                <div className="col-sm-12 px-0">
                  <div className="input-group">
                    <DateTimeInput
                      setStartDate={setStartDate}
                      startDate={startDate}
                      notification={"Notificaton"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </Offcanvas>
    </div>
  );
};

export default NotificationForm;
