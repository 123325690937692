import React, { useState, useEffect } from "react";
import axios from "axios";
import config from '../../../config';
import { Link } from "react-router-dom";
import Common from "../../common/Common";


const ContactRequests = () => {
  const [contactRequests, setContactRequests] = useState([]);

  useEffect(() => {
    // Fetch contact requests from the API
    axios.get(`${config.apiUrl}/contacts`)
      .then(response => {
        setContactRequests(response.data);
      })
      .catch(error => {
        console.error('Error fetching contact requests:', error);
      });
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">Contact Requests</h3>
            </div>
            <div className="card-body">
              <div className="responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Service</th>
                      <th>Notes</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactRequests.map((request, index) => (
                      <tr key={request._id}>
                        <td>{index + 1}</td>
                        <td>
                          {request.name}
                        </td>
                        <td>
                          <a href="mailto:{request.email}">{request.email}</a>
                        </td>
                        <td>
                          <a href="tel:{request.phone}">
                            {request.phone}
                          </a>
                        </td>
                        <td>{request.service}</td>
                        <td>{Common.truncateStringToWord(request.notes, 30)}</td>
                        <th className="text-center">
                          <Link to={`/contact-requests/${request._id}`}>
                            <i className="fa fa-eye"></i>
                          </Link>
                          {/* <a href="#" className="ml-2">
                            <i className="fa fa-trash"></i>
                          </a> */}
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default ContactRequests;