import React, { useState, useEffect } from "react";
import axios from "axios";
import { redirect, useNavigate, useParams } from "react-router-dom";
import config from "../../../config";
import Swal from "sweetalert2";
import { toast } from "react-toastify"; // Import react-toastify
import { useForm } from "react-hook-form";
// import React from "react";
import { apiEndpoints } from "../../common/api/apiEndpoints";
import { CreateDataApi, GetListApi } from "../../common/api/api";
import SectionManage from "./SectionManage";
import { convertToSlug } from "../../common/utils/convertToSlug";
import { ConvertArrayTOString } from "../../common/utils/arrayToString";
import _ from "lodash";

const useSectionHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const [sectionList, setSectionList] = useState();
  const [sectionItem, setSectionItem] = useState();
  const handleClose = (e) => setShow(false);
  const [show, setShow] = useState(false);
  // const [allowedStates, setallowedStates] = useState(allowedState);

  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;

  const [sectionView, setSectionView] = useState([]);

  // section descriptiton start from here

  const fetchSectionById = async () => {
    try {
      axios
        .get(`${config.apiUrl}/section/${id}`)
        .then((response) => {
          if (response?.data) {
            setSectionView(response?.data);
            let length = response?.data.parent_section.length - 1;
            setValue("parent_section", response?.data.parent_section[length]);
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSectionById();
    }
  }, [id]);
  // section descriptiton end  here
  const HandleCreateCollection = async (savedSection) => {
    if (savedSection) {
      let collection_payload = {
        title: savedSection?.section_name,

        include_section: [
          {
            label: savedSection?.section_name,
            value: savedSection?._id,
            parents:
              savedSection?.parent_section?.length > 0
                ? savedSection?.parent_section
                : [],
          },
        ],
        // author: req?.body?.author,

        slug:
          savedSection?.parent_section?.length > 0
            ? `${_.lowerCase(savedSection?.section_name)
                ?.split(" ")
                .join("-")}-${ConvertArrayTOString(
                savedSection?.parent_section,
                "dash"
              )
                ?.split(" ")
                .join("-")}`
            : `${_.lowerCase(savedSection?.section_name)
                ?.split(" ")
                .join("-")}`,

        // slug: _.lowerCase(savedSection?.section_name)?.split(" ").join("-"),
        slug_preview:
          savedSection?.parent_section?.length > 0
            ? `${_.lowerCase(savedSection?.section_name)
                ?.split(" ")
                .join("/")}/${ConvertArrayTOString(
                savedSection?.parent_section,
                "slash"
              )
                ?.split(" ")
                .join("/")}`
            : `${_.lowerCase(savedSection?.section_name)
                ?.split(" ")
                .join("/")}`,

        status: "Active",
        created_on: savedSection?.createdAt,
        created_by: user?.name,
        rule_type: "automated",
      };
      const response = await CreateDataApi(`collection/create`, {
        ...collection_payload,
        slug: collection_payload?.slug?.split("-").reverse().join("-"),
        slug_preview: collection_payload?.slug_preview
          ?.split("/")
          .reverse()
          .join("/"),
      });
    }
  };
  const generateSlug = (e) => {
    let slug = convertToSlug(e?.target?.value);
    setValue("slug", slug);
  };

  const onSubmit = (data) => {
    data.parent_section = data?.parent_section;
    let slug = convertToSlug(data?.section_name);
    data.slug = slug;
    data.created_by = user?.name;
    axios
      .post(`${config.apiUrl}/section/create`, data)
      .then((response) => {
        setIsOpenCanvas(false);
        if (response.data) {
          fetchOurSection();

          // C R E A T E  C O L L E C T I O N
          HandleCreateCollection(response?.data);

          setShow(false);
          Swal.fire({
            icon: "success",
            title: "Section added successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.error("Error adding section:", error);
      });
  };

  const onUpdate = async (data) => {
    let slug = convertToSlug(data?.section_name);
    data.slug = slug;
    await axios
      .put(`${config.apiUrl}/section/${sectionItem?._id}`, data)
      .then((response) => {
        if (response?.data) {
          fetchOurSection();
          setIsOpenCanvas(false);
          setShow(false);
          Swal.fire({
            icon: "success",
            title: "Section updated successfully.",
            showConfirmButton: false,
            timer: 1500,
          });
          setSectionView(response.data);
          navigate(`/section/${sectionItem?._id}`);
        }
      })
      .catch((error) => {
        console.error("Error adding section:", error);
      });
  };

  useEffect(() => {
    fetchOurSection();
  }, [currentPage]);

  const fetchOurSection = async (
    section_name,
    display_name,
    parent_section,
    page_title,
    meta_title,
    description,
    keywords
  ) => {
    const section = await GetListApi(`${apiEndpoints.section}`, {
      section_name: section_name,
      display_name: display_name,
      parent_section: parent_section,
      page_title: page_title,
      meta_title: meta_title,
      description: description,
      keywords: keywords,
      page: currentPage,
      pageSize: 20,
      pagination: true,
    });
    if (section) {
      setSectionList(section?.sections);
      setTotalPages(section?.pages);
    }
  };

  const handleDelete = (id) => {
    // Show the confirm dialog
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // If the user confirms, proceed with deletion
        axios
          .delete(`${config.apiUrl}/section/${id}`)
          .then((response) => {
            fetchOurSection();
            toast.success("Item deleted successfully");
            navigate("/section");
          })
          .catch((error) => {
            console.error("Error deleting item:", error);
            // Handle error, show error message, etc.
            toast.error("An error occurred while deleting");
          });
      }
    });
  };
  const onSectionListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      let dt = new Date();
      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios
        .put(`${config.apiUrl}/${apiEndpoints?.section}`, {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: data?.originalOrder,
        })
        .then((response) => {
          if (response.data) {
            fetchOurSection();
            toast.success("Section updated successfully.");
          } else {
            toast.error(response.data.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return {
    onSubmit,
    onUpdate,
    navigate,
    register,
    handleSubmit,
    sectionView,
    setSectionView,
    errors,
    handleDelete,
    sectionList,
    setSectionList,
    sectionItem,
    setSectionItem,
    setValue,
    isOpenCanvas,
    setIsOpenCanvas,
    onUpdate,
    fetchOurSection,
    onSectionListUpdate,
    currentPage,
    totalPages,
    setCurrentPage,
    handleClose,
    show,
    setShow,
    getValues,
    generateSlug,
  };
};

export default useSectionHook;
