import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const RoleForm = () => {
  const { register, formState: { errors }, } = useForm();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    role: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,[name]: value,}));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: Add logic to submit the form data (e.g., send it to the server).

    // For demonstration purposes, you can log the form data to the console.
    console.log('Form Data:', formData);
  };

  return (

<>
    <div className="content text-center">
      <div className="content">
        <div className="card col-md-12 m-auto p-30">
          <div className="card-header">
            <h3 className="text-bold">{}</h3>
          </div>
          <div className="card-body">
          <form onSubmit={handleSubmit}>
      
          <div className="row mb-15">
                  <label htmlFor="txtRole" className="col-sm-4 col-form-label text-right">Name</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="txtName" placeholder=" Name" {...register('txtName', { required: true })} />
                    {errors.txtName && <span className="text-danger">Name is required.</span>}
                  </div>
                </div>
          

            <div className="row mb-15">
                  <label htmlFor="txtEmail" className="col-sm-4 col-form-label text-right">Email</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="txtEmail" placeholder=" Email" {...register('txtEmail', { required: true })} />
                    {errors.txtEmail && <span className="text-danger">Email is required.</span>}
                  </div>
                </div>
         
                <div className="row mb-15">
                  <label htmlFor="txtMobile" className="col-sm-4 col-form-label text-right">Mobile</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="txtMobile" placeholder="Mobile" {...register('txtMobile', { required: true })} />
                    {errors.txtMobile && <span className="text-danger">Mobile is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <label htmlFor="txtRole" className="col-sm-4 col-form-label text-right">Role</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="txtRole" placeholder="Role" {...register('txtRole', { required: true })} />
                    {errors.txtRole && <span className="text-danger">Role is required.</span>}
                  </div>
                </div>    
         

                <div className="row mb-15">
                  <label htmlFor="txtPassword" className="col-sm-4 col-form-label text-right">Password</label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="txtPassword" placeholder="Password" {...register('txtPassword', { required: true })} />
                    {errors.txtPassword && <span className="text-danger">Password is required.</span>}
                  </div>
                </div>

                <div className="row mb-15">
                  <div className="col-sm-12 text-center">
                    <Link to="" className="btn btn-primary btn-round" type="reset">Create Role</Link>
                  </div>
                </div>
    </form>
        </div>
        </div>
      </div>
    </div>
  </>

);
};
  
export default RoleForm;
































