import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import config from "../../../config";
import { apiEndpoints } from "../api/apiEndpoints";
import styles from "./dropzone.module.css";
import { DeleteApi } from "../api/api";
const CommonDropBox = (props) => {
  const {
    register,
    setValue,
    imgUrl,
    setImgUrl,
    files,
    setFiles,
    isLoading,
    setIsLoading,
    getValues,
    errors,
  } = props;
  const [heroImageLoad, setHeroImageLoad] = useState(false);
  const HandleUploadImage = async (files) => {
    let data = {};
    const file = files[0];
    try {
      setIsLoading(true);
      setHeroImageLoad(true);
      if (files?.length > 0) {
        const formData = new FormData();
        data.status = "Active";
        const res = await axios.post(
          `${config?.fileUrl}/${apiEndpoints.upload}`,
          { files: files[0] },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res?.data?.length > 0) {
          setIsLoading(false);

          setValue("hero_image.file_name", res?.data[0]?.file_name);
          setValue("hero_image.file_path", res?.data[0]?.file_path);
          setValue("hero_image.file_id", res?.data[0]?._id);

          setImgUrl(`${config?.fileUrl}/${res?.data[0]?.file_path}`);
        }
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setImgUrl("");
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      HandleUploadImage(acceptedFiles);
    },
  });
  const handleRootClick = (e) => {
    e.preventDefault(); // Prevent the default behavior
  };
  const thumbs = imgUrl ? (
    <div className={styles.thumb}>
      <div className={styles.thumbInner}>
        <img src={imgUrl} className={styles.img} />
      </div>
    </div>
  ) : (
    files?.map((file) => (
      <div key={file.name} className={`d-flex justify-content-center`}>
        <img
          src={file?.preview}
          className={styles.img}
          onLoad={() => {
            URL.revokeObjectURL(file?.preview);
          }}
        />
      </div>
    ))
  );
  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, []);
  const HandleImageDelete = async (imgId) => {
    const response = await DeleteApi(`${apiEndpoints.uploadById(imgId)}`);
    if (response.status === 204) {
      setValue("hero_image.file_name", "");
      setValue("hero_image.file_path", "");
      setValue("hero_image.file_id", "");

      setValue("hero_image.caption", "");
      setValue("hero_image.alt", "");
      setValue("hero_image.credit", "");

      setFiles([]);
      setImgUrl("");
    }
  };

  const editorThumbs = (
    <div className={styles.thumb}>
      <div className={styles.thumbInner}>
        <img
          src={`${config?.fileUrl}/${getValues()?.hero_image?.file_path}`}
          className={styles.img}
        />
      </div>
    </div>
  );

  const fetchImage = async (file_url) => {
    try {
      const response = await fetch(file_url);
      const blob = await response.blob();

      const fileName = "image.png";
      const lastModified = new Date();

      // Create a File object
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: lastModified.getTime(),
      });
      //   setSelectedFile(file);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };
  return (
    <>
      <div className={`position-relative`}>
        {!getValues()?.hero_image?.file_path &&
        getValues()?.hero_image?.file_Id !== "" ? (
          <div
            {...getRootProps({
              className: "dropzone border p-3 text-center hero-image",
            })}
          >
            <input {...getInputProps()} />
            <i className="fa fa-cloud-upload"></i>
            <p>Upload Image</p>
          </div>
        ) : (
          <div className="row display-flex justify-content-center">
            {isLoading && !getValues()?.hero_image?.file_path ? (
              <div className="spinner text-editor-loader"></div>
            ) : (
              ""
            )}
            <div className="col-sm-12 col-md-6 px-0 hero-image dropzone border position-relative">
              {editorThumbs}
              <div className={`d-flex position-absolute ${styles.heroImgBtns}`}>
                <button
                  className={`btn btn-primary btn-small text-light`}
                  onClick={(e) => {
                    e?.preventDefault();
                    HandleImageDelete(getValues()?.hero_image?.file_id);
                  }}
                  disabled={isLoading}
                >
                  <i className="text-white fa fa-trash"></i>
                </button>
                <button
                  {...getRootProps({ onClick: handleRootClick })}
                  className={`btn btn-primary btn-small text-light`}
                  disabled={isLoading}
                >
                  <i className="text-white fa fa-cloud-upload"></i>
                </button>
                <button
                  className={`btn btn-primary btn-small text-light`}
                  onClick={(e) => {
                    e?.preventDefault();
                    setIsLoading(false);
                    fetchImage(`${config?.fileUrl}/${getValues()?.images}`);
                  }}
                  disabled={isLoading}
                >
                  <i className="text-white fa fa-edit"></i>
                </button>
              </div>
            </div>
            <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-around">
              <label htmlFor="title" className="col-form-label text-bold mb-0">
                Caption
              </label>
              <input
                type="text"
                name="caption"
                className="form-control mb-3 width-100"
                placeholder="Enter Caption"
                {...register(`hero_image.caption`, {
                  required: true,
                })}
              />
              {errors?.hero_image?.caption && (
                <span className="text-danger">{`Caption is required.`}</span>
              )}
              <label htmlFor="title" className="col-form-label text-bold mb-0">
                Alt
              </label>
              <input
                type="text"
                name="alt"
                className="form-control mb-3 width-100"
                placeholder="Enter Attribution"
                {...register(`hero_image.alt`, {
                  required: true,
                })}
              />
              {errors?.hero_image?.alt && (
                <span className="text-danger">{`Alt is required`}</span>
              )}
              <label htmlFor="title" className="col-form-label text-bold mb-0">
                Credit
              </label>
              <input
                type="text"
                name="credit"
                className="form-control mb-3 width-100"
                placeholder="Enter Credit"
                {...register(`hero_image.credit`, {
                  required: "Credit is required",
                })}
              />
              {errors?.hero_image?.credit && (
                <span className="text-danger">
                  {`Credit is required.`}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CommonDropBox;
