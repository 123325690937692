import React, { useEffect, useState } from "react";
import UserCreate from "./UserCreate";
import { Pencil, Trash } from "react-bootstrap-icons";
import useUserHook from "./userHook";
import useRolesHook from "../roles/rolesHook";
import { Badge } from "react-bootstrap";
import Pagination from "../../../common/pagination";
import { CheckAccess } from "../../../common/utils/roleAccess";

function User() {
  const {
    onSubmit,
    userList,
    pageTitle,
    register,
    handleSubmit,
    errors,
    setValue,
    handleDelete,
    isOpenCanvas,
    setIsOpenCanvas,
    editUser,
    setEditUser,
    onUserUpdate,
    fetchUserList,
    currentPage,
    setCurrentPage,
    totalPages,
  } = useUserHook();
  const { rolesList } = useRolesHook();
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="content">
        <div className="content">
          <div className="card col-md-12 m-auto p-30">
            <div className="card-header">
              <h3 className="text-bold">{pageTitle}</h3>
              {CheckAccess("user", "create") && <div className="timeline-footer text-right">
                <button
                  className="btn btn-primary btn-round btn-sm"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                  onClick={() => {
                    setIsOpenCanvas(true);
                    setEditUser("");
                    setValue("status", "Active");
                  }}
                >
                  Create User
                </button>
              </div>}
            </div>
            <div className="card-body">
              <table className="table-responsive ">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Full Name</th>
                    {/* <th scope="col">Last Name</th> */}
                    <th scope="col">Phone Number</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                <tr style={{ height:"50px"}}>       
                   <th scope="col"></th>                   
                       <th scope="col">
                        <input type="name"  className="form-control" 
                        onChange={(e)=>{fetchUserList(e.target.value,"","","","")}}
                         data-table="your-table" placeholder="Item to search.." />
                         </th>
                       {/* <th scope="col">
                        <input type="lname" className="form-control" 
                        onChange={(e)=>{fetchUserList("",e.target.value,"","","","")}}
                        data-table="your-table" placeholder="Item to search.." />
                       </th> */}
                       <th scope="col"> 
                        <input type="mobile" className="form-control"
                          onChange={(e)=>{fetchUserList("",e.target.value,"","","" )}}
                        data-table="your-table" placeholder="Item to search.." />
                        </th>
                       <th scope="col">
                        <input type="email" className="form-control" 
                         onChange={(e)=>{fetchUserList("","",e.target.value,"","")}}
                        data-table="your-table" placeholder="Item to search.." />
                       </th>
                       <th scope="col">
                        <input type="user_role" className="form-control" 
                         onChange={(e)=>{fetchUserList("","","",e.target.value,"")}}
                        data-table="your-table" placeholder="Item to search.." />
                       </th>
                       <th scope="col">
                       <select name="status"  onChange={(e)=>{fetchUserList("","","","",e.target.value)}} className="form-control">
                      <option value={""}>All</option>
                      <option value={"Active"}>Active</option>
                      <option value={"Inactive"}>Inactive</option>
                    </select>
                       </th>
                       {/* <th scope="col">
                        <input type="text" className="form-control" 
                        //  onChange={(e)=>{fetchUserList("","","","",e.target.value)}}
                        data-table="your-table" placeholder="Item to search.." />
                       </th> */}
                </tr>
                  {/* return ( */}
                  {!userList?.length > 0 && loading ? (
                    <>
                      <tr>
                        <div id="loader">
                          <div className="d-flex flex-column align-items-center">
                            <div className="spinner-grow text-gold"></div>
                            <span className="text-gold mt-1">Loading...</span>
                          </div>
                        </div>
                      </tr>
                    </>
                  ) : (
                    userList?.map((user, key) => {
                      return (
                        <tr key={key}>
                          <td scope="row" className="p-4">
                            {key + 1}
                          </td>
                          <td className="p-4">
                            {user?.name || `-`}
                          </td>
                          {/* <td className="p-4">
                            {user?.name?.split(" ")[1] || `--`}
                          </td> */}
                          <td className="p-4">{user?.mobile || `-`}</td>
                          <td className="p-4">{user?.email || `-`}</td>
                          <td className="p-4">{user?.user_role?.name || `-`}</td>
                          <td className="p-4">
                            <Badge
                              bg={
                                user.status === "Active" ? "success" : "danger"
                              }
                              text="white"
                            >
                              {user.status}
                            </Badge>
                          </td>
                          <td>
                            {CheckAccess("user", "update") && <button
                              type="button"
                              onClick={() => {
                                setEditUser(user);
                                setIsOpenCanvas(true);
                              }}
                              className="btn btn"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvasRight"
                              aria-controls="offcanvasRight"
                            >
                              <Pencil
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="Edit"
                              />
                            </button>}
                            {CheckAccess("user", "delete") && <button
                              type="button"
                              className="btn btn"
                              onClick={() => handleDelete(user._id)}
                            >
                              <Trash
                                color="royalblue"
                                size={16}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </button>}
                          </td>
                        </tr>
                      );
                    })
                  )}
                  {/* ); */}
                </tbody>
              </table>
              {totalPages ? (
                <div className={`container`}>
                  <div className={`row`}>
                    <div
                      className={`col-sm-12 d-flex justify-content-center px-0`}
                    >
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* offcanvas start  */}
              <div
                className={`offcanvas offcanvasbodyupdate offcanvas-end ${
                  isOpenCanvas ? "show" : ""
                }`}
                tabIndex="-1"
                id="offcanvasRight"
                aria-labelledby="offcanvasRightLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <UserCreate
                    onSubmit={onSubmit}
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    setValue={setValue}
                    isOpenCanvas={isOpenCanvas}
                    setIsOpenCanvas={setIsOpenCanvas}
                    rolesList={rolesList}
                    editUser={editUser}
                    setEditUser={setEditUser}
                    onUserUpdate={onUserUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default User;
