import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { apiEndpoints } from "../../../common/api/apiEndpoints";
import {
  CreateDataApi,
  GetListApi,
  UpdateDataApiByPatch,
} from "../../../common/api/api";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../../config";
import AppContext from "../../../appContext";
const useStoreHook = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageCollection, setCurrentPageCollection] = useState(1);
  const [collectionList, setCollectionList] = useState();
  const [totalPages, setTotalPages] = useState(null);
  const [storiesList, setStoriesList] = useState([]);
  const [rulesType, setRulesType] = useState("automated");
  const [loading, setLoading] = useState(false);
  const [collectionOfCollectionList, setCollectionOfCollectionList] = useState(
    []
  );
  const { searchItem, setSearchItem } = useContext(AppContext);
  const convertDataToParams = (data) => {
    const params = {};

    // Convert sections
    params.sections = searchItem?.sections?.map((section) => {
      // Splitting the label string and taking the last part
      const parts = section.label.split(">");
      const label = parts[parts.length - 1].trim();
      return label;
    });

    // Convert tags
    params.tags = searchItem?.tags?.map((tag) => {
      return tag.label;
    });

    return params;
  };
  const userData = sessionStorage.getItem("user");
  const user = userData ? JSON.parse(userData) : null;
  const [stepperForm, setStepperForm] = useState("contentForm");
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await CreateDataApi(
        apiEndpoints.collectionOfCollection,
        data
      );
      if (response) {
        setLoading(false);
        toast.success("Add Successfully");
        FetchCollectionOfCollectionList();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdate = async (data) => {
    try {
      setLoading(true);
      const response = await UpdateDataApiByPatch(
        apiEndpoints.collectionOfCollectionById(data?.id),
        data
      );
      if (response) {
        FetchCollectionOfCollectionList();
        data?.status !== "publish" && toast.success("Update Successfully");
      }
    } catch (error) {
      console.error("Error updating form:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (id) => {
    await axios
      .delete(`${config.apiUrl}${apiEndpoints.collectionOfCollectionById(id)}`)
      .then((response) => {
        FetchCollectionOfCollectionList();
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };

  const onDeleteTemp = (id) => {
    axios
      .delete(`${config.apiUrl}${apiEndpoints.collectionOfCollectionById(id)}`)
      .then((response) => {
        FetchCollectionOfCollectionList();
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
  };

  const FetchCollectionOfCollectionList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${config.apiUrl}/${apiEndpoints.collectionOfCollection}`,
        { params: { id } }
      );
      setCollectionOfCollectionList(response?.data?.collectionOfCollection);
    } catch (error) {
      console.error("Error fetching collection list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue("rule_type", e.target.value);
    setRulesType(e.target.value);
  };

  // useEffect(() => {
  //   FetchCollectionOfCollectionList();
  // }, []);

  const fetchOurStoryList = async (status, query = "", page) => {
    let filter = convertDataToParams();
    try {
      setLoading(true);
      const response = await GetListApi(`${apiEndpoints.storiesFilter}`, {
        page: page,
        pageSize: 20,
        pagination: true,
        status: status,
        sections:
          filter?.sections?.length > 0 ? filter?.sections?.toString() : "",
        tags: filter?.tags?.length > 0 ? filter?.tags?.toString() : "",
        q: query,
      });
      if (response?.stories) {
        if (filter?.sections?.length > 0 || filter?.tags?.length > 0) {
          setStoriesList(response.stories);
        } else {
          setStoriesList((prevStories) => [
            ...prevStories,
            ...response?.stories,
          ]);
        }
        setTotalPages(response.pages);
      }
    } catch (error) {
      console.error("Error fetching stories:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchItem?.sections?.length > 0 || searchItem?.tags?.length > 0) {
      fetchOurStoryList();
    }
  }, [searchItem]);
  const fetchOurCollectionList = async (status, query, page) => {
    const param = {
      page: page,
      pageSize: 20,
      q: query,
      title: query,
    };
    const response = await GetListApi(`${apiEndpoints.collection}`, param);
    if (response) {
      if (query != "") {
        setCollectionList(response);
      } else {
        setCollectionList((prevStories) => [...prevStories, ...response]);
      }
      // setCurrentPageCollection(response);
    }
  };

  const handleTempCollectionUpdate = async () => {
    try {
      const tempItems = collectionOfCollectionList?.filter(
        (item) => item?.status === "temp"
      );

      if (tempItems?.length > 0) {
        const updatePromises = tempItems.map((tempItem) =>
          onUpdate({
            id: tempItem?._id,
            status: "published",
          })
        );

        await Promise.all(updatePromises);

        await FetchCollectionOfCollectionList();
        toast.success("All items published successfully");
        navigate("/collection");
      } else {
        console.log("No temp items to update", tempItems);
      }
    } catch (error) {
      console.error("Error updating temp items:", error);
    }
  };
  const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };

  const [dragItem, setDragItem] = useState(collectionOfCollectionList);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: collectionOfCollectionList,
    });
    // event.dataTransfer.setData("text/html", '');
  };
  const onDragOver = (event) => {
    event.preventDefault();
    let newList = dragAndDrop.originalOrder;
    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(event.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );
    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };
  const onDrop = (event) => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
    setCollectionOfCollectionList(dragAndDrop.updatedOrder);
    SubCollectionListUpdate(dragAndDrop);
  };
  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };
  const SubCollectionListUpdate = async (data) => {
    try {
      const userData = sessionStorage.getItem("user");
      const user = userData ? JSON.parse(userData) : null;
      const dt = new Date();

      data.updated_by = user?.name;
      data.updated_on = dt;

      const response = await axios.put(
        `${config.apiUrl}/${apiEndpoints?.collectionOfCollection}`,
        {
          draggedFrom: data?.draggedFrom,
          draggedTo: data?.draggedTo,
          originalOrder: data?.originalOrder,
        }
      );

      if (response.data) {
        // FetchCollectionList();
      } else {
        toast.error(response.data.error);
      }
    } catch (error) {
      console.error("Error updating list:", error?.message);
    }
  };
  return {
    navigate,
    register,
    handleSubmit,
    handleChange,
    selectedDate,
    setSelectedDate,
    rulesType,
    setRulesType,
    user,
    onSubmit,
    collectionOfCollectionList,
    setCollectionOfCollectionList,
    FetchCollectionOfCollectionList,
    fetchOurCollectionList,
    id,
    stepperForm,
    setStepperForm,
    onDelete,
    loading,
    onUpdate,
    currentPage,
    setCurrentPage,
    onDeleteTemp,
    storiesList,
    setStoriesList,
    totalPages,
    setTotalPages,
    fetchOurStoryList,
    currentPageCollection,
    collectionList,
    setCollectionList,
    setCurrentPageCollection,

    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    handleTempCollectionUpdate,
  };
};

export default useStoreHook;
